import { capitalize, flow } from 'lodash';
import { endOfDay, parse, startOfDay } from 'date-fns';
import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import {
  DateRangeFilter,
  PresetSelectedColumnFilter,
  ThrottledFilter,
} from '../components/organisms/MultiSelectTable/filters';
import { IOrderListItem, OrderStatus } from 'Order/types/orderRecord';
import {
  formatAccessProvider,
  formatAccessType,
  formatProductType,
  formatQuoteLocation,
  formatQuoteState,
} from 'Quotes/utils/quoteTableHelpers';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';
import { FilterableProviderNames, ProviderName } from 'Quotes/types/store';
import { productTypes } from 'Quotes/types/productTypes';
import { IPageFilter } from 'shared/hooks/useTablePaging';
import { formatOrderStatus } from 'Order/OrderBuilder/shared/utils/ordersTableHelper';
import { calculateAnnualPrice } from 'Quotes/QuoteBuilder/utils/price';
import { QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import TableNames from 'Tables/types/tableNames';
import { connectionTypeLabels } from 'Quotes/types/connectionType';
import { formatDateForPaginatedQuery } from './dateHelper';
import { maskSSEUserEmail } from './maskEmail';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

const overflowVisibleStyle = { overflow: 'visible' };

type PageFilterTransform = (filters: IPageFilter[]) => IPageFilter[];

export const transformAccessTypeFilter: PageFilterTransform = (filters) => {
  return filters.map((filter) => {
    if (filter.id === 'access_type' && FilterableProviderNames.includes(filter.value as ProviderName)) {
      return {
        id: 'cloud_connect_provider',
        value: filter.value,
      };
    }
    return filter;
  });
};

export const transformNeosAccessTypeFilter: PageFilterTransform = (filters) => {
  return filters.map((filter) => {
    if (filter.id === 'access_type' && filter.value === Supplier.NONE) {
      return {
        id: filter.id,
        value: 'Neos Networks',
      };
    }

    return filter;
  });
};

export const transformDateAtFilter: PageFilterTransform = (filters) => {
  return filters.reduce<IPageFilter[]>((acc, filter) => {
    if (['created_at', 'updated_at'].includes(filter.id)) {
      const parts = filter.value.split(' - ');
      const start = parse(parts[0], 'yyyy-MM-dd', new Date());
      const end = parse(parts[1], 'yyyy-MM-dd', new Date());

      acc.push({
        id: `${filter.id}_gte`,
        value: formatDateForPaginatedQuery(startOfDay(start)),
      });
      acc.push({
        id: `${filter.id}_lte`,
        value: formatDateForPaginatedQuery(endOfDay(end)),
      });
    } else {
      acc.push(filter);
    }

    return acc;
  }, []);
};

export const transformIdFilter: PageFilterTransform = (filters) => {
  return filters.reduce<IPageFilter[]>((acc, filter) => {
    if (filter.id !== 'id' && filter.id !== 'quote_id') {
      acc.push(filter);
      return acc;
    }

    if (/^(?:o|q|bq)-$/i.test(filter.value)) {
      return acc;
    }

    if (/^(?:o|q|bq)-.+/i.test(filter.value)) {
      acc.push({
        id: filter.id,
        value: filter.value.slice(filter.value.indexOf('-') + 1),
      });
      return acc;
    }

    acc.push(filter);
    return acc;
  }, []);
};

export const transformUpdatedByFilter: PageFilterTransform = (filters) => {
  return filters.reduce<IPageFilter[]>((acc, filter) => {
    if (filter.id !== 'updated_by') {
      acc.push(filter);
      return acc;
    }

    acc.push({
      id: filter.id,
      value: filter.value.trim().replace(/ /g, '.'),
    });
    return acc;
  }, []);
};

export const transformCreatedByFilter: PageFilterTransform = (filters) => {
  return filters.reduce<IPageFilter[]>((acc, filter) => {
    if (filter.id !== 'created_by' && filter.id !== 'quoted_by') {
      acc.push(filter);
      return acc;
    }

    acc.push({
      id: filter.id,
      value: filter.value.trim().replace(/ /g, '.'),
    });
    return acc;
  }, []);
};

export const orderFilterTransform = flow([
  transformAccessTypeFilter,
  transformDateAtFilter,
  transformIdFilter,
  transformUpdatedByFilter,
]);

export function sharedQuoteOrderTableHeaders(quoteAccessor: (row: any) => IQuoteListItem) {
  return {
    accessProvider: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: PresetSelectedColumnFilter,
      Header: 'Access Provider',
      accessor: (row: IOrderListItem) => quoteAccessor(row) && formatAccessProvider(quoteAccessor(row)!),
      filter: 'equalsOrEmptyFilter',
      id: 'access_type',
      disableSortBy: true,
      options: Object.values(Supplier)
        .filter((value) => value !== Supplier.ONAT)
        .filter((value) => value !== Supplier.NNAT)
        .map((value) => ({
          value: `${value}`,
          label: presentSupplierName(value),
        }))
        .concat(
          Object.values(FilterableProviderNames).map((provider) => ({
            value: provider,
            label: provider,
          }))
        )
        .sort(function (a, b) {
          return a.label < b.label ? -1 : 1;
        }),

      width:
        localStorage.getItem(`${tableName}-width-access_type`) !== null
          ? localStorage.getItem(`${tableName}-width-access_type`)
          : 120,
      tableName,
      storeKeyName,
      key: 'Access Provider',
    }),
    accessTypeForQuote: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: PresetSelectedColumnFilter,
      Header: 'Access Type',
      accessor: (row: IQuoteListItem) =>
        quoteAccessor(row) &&
        formatAccessType(
          row.product_type,
          row.selected_price?.product_sub_type === 'optical' ? 'Optical' : row.a_end_access_method || '',
          row.selected_price?.product_sub_type === 'optical' ? 'Optical' : row.b_end_access_method || ''
        ),
      filter: 'equalsOrEmptyFilter',
      id: 'access_method',
      disableSortBy: true,
      options: connectionTypeLabels.sort(function (a, b) {
        return a.label < b.label ? -1 : 1;
      }),

      width:
        localStorage.getItem(`${tableName}-width-access_method`) !== null
          ? localStorage.getItem(`${tableName}-width-access_method`)
          : 120,
      tableName,
      storeKeyName,
      key: 'Access Type',
    }),
    accessTypeForOrder: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: PresetSelectedColumnFilter,
      Header: 'Access Type',
      accessor: (row: IOrderListItem) =>
        quoteAccessor(row) &&
        formatAccessType(
          row.quote?.product_type,
          row.quote?.a_end_access_method || '',
          row.quote?.b_end_access_method || ''
        ),
      filter: 'equalsOrEmptyFilter',
      id: 'access_method',
      disableSortBy: true,
      options: connectionTypeLabels.sort(function (a, b) {
        return a.label < b.label ? -1 : 1;
      }),
      width:
        localStorage.getItem(`${tableName}-width-access_method`) !== null
          ? localStorage.getItem(`${tableName}-width-access_method`)
          : 130,
      tableName,
      storeKeyName,
      key: 'Access Type',
    }),
    createdAt: (tableName?: TableNames, storeKeyName?: string) => ({
      style: overflowVisibleStyle,
      Filter: DateRangeFilter,
      Header: 'Created',
      accessor: 'created_at',
      id: 'created_at',
      disableSortBy: true,
      width:
        localStorage.getItem(`${tableName}-width-createdAt`) !== null
          ? localStorage.getItem(`${tableName}-width-createdAt`)
          : 130,
      tableName,
      storeKeyName,
      key: 'Created',
    }),
    createdBy: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: ThrottledFilter,
      Header: 'Quoted by',
      accessor: (row: IQuoteListItem) => {
        return maskSSEUserEmail(row.created_by || '');
      },
      filter: 'equalsOrEmptyFilter',
      id: 'created_by',
      disableSortBy: true,
      width:
        localStorage.getItem(`${tableName}-width-created_by`) !== null
          ? localStorage.getItem(`${tableName}-width-created_by`)
          : 140,
      tableName,
      storeKeyName,
      key: 'Quoted by',
    }),
    price: (tableName?: TableNames) => ({
      Header: 'Price',
      accessor: (row: IQuoteListItem) => {
        const { selected_price } = row;
        if (selected_price) {
          return calculateAnnualPrice(
            selected_price.amortised ?? false,
            selected_price.net_amortised_annual_price ?? 0,
            Number(selected_price.net_annual_price) ?? 0
          );
        }

        if (row.is_poa) return 'PoA';

        return undefined;
      },
      id: 'annual_price',
      disableSortBy: true,
      disableFilters: true,
      width:
        localStorage.getItem(`${tableName}-width-annual_price`) !== null
          ? localStorage.getItem(`${tableName}-width-annual_price`)
          : 130,
      key: 'Price',
    }),
    customer: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: ThrottledFilter,
      Header: 'Customer',
      accessor: 'customer_name',
      id: 'customer_name',
      disableSortBy: true,
      tableName,
      storeKeyName,
      key: 'Customer',
      width:
        localStorage.getItem(`${tableName}-width-customer_name`) !== null
          ? localStorage.getItem(`${tableName}-width-customer_name`)
          : 150,
    }),
    locations: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: ThrottledFilter,
      Header: 'Locations',
      accessor: (row: any) => formatQuoteLocation(quoteAccessor(row)!),
      id: 'locations',
      disableSortBy: true,
      width:
        localStorage.getItem(`${tableName}-width-product_locations`) !== null
          ? localStorage.getItem(`${tableName}-width-product_locations`)
          : 250,
      tableName,
      storeKeyName,
      key: 'Locations',
    }),
    orderId: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: ThrottledFilter,
      Header: 'Order ID',
      accessor: (row: any) => capitalize(row.short_id),
      id: 'id',
      width:
        localStorage.getItem(`${tableName}-width-id`) !== null ? localStorage.getItem(`${tableName}-width-id`) : 90,
      disableSortBy: true,
      tableName,
      storeKeyName,
      key: 'Order ID',
    }),
    product: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: PresetSelectedColumnFilter,
      Header: 'Product',
      accessor: (row: any) => formatProductType(quoteAccessor(row).product_type),
      id: 'product_type',
      width:
        localStorage.getItem(`${tableName}-width-product_type`) !== null
          ? localStorage.getItem(`${tableName}-width-product_type`)
          : 110,
      disableSortBy: true,
      options: productTypes.map((product) => {
        const formattedProductType = formatProductType(product.value);
        return { label: formattedProductType, value: product.value };
      }),
      tableName,
      storeKeyName,
      key: 'Product',
    }),
    modifiedBy: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: ThrottledFilter,
      Header: 'Modified by',
      accessor: (row: IOrderListItem) => {
        return maskSSEUserEmail(row.updated_by || '');
      },
      id: 'updated_by',
      disableSortBy: true,
      tableName,
      storeKeyName,
      key: 'Modified by',
      width:
        localStorage.getItem(`${tableName}-width-updated_by`) !== null
          ? localStorage.getItem(`${tableName}-width-updated_by`)
          : 90,
    }),
    quoteId: (id: 'quote_id' | 'id', tableName?: TableNames, storeKeyName?: string) => ({
      Filter: ThrottledFilter,
      Header: 'Quote ID',
      accessor: (row: IOrderListItem) => capitalize(quoteAccessor(row).short_id),
      width:
        localStorage.getItem(`${tableName}-width-quote_id`) !== null
          ? localStorage.getItem(`${tableName}-width-quote_id`)
          : 95,
      disableSortBy: true,
      id,
      tableName: tableName === undefined ? 'bulkQuotesChildTable' : tableName,
      storeKeyName: storeKeyName === undefined ? 'quoteId' : storeKeyName,
      key: 'Quote ID',
    }),
    orderStatus: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: PresetSelectedColumnFilter,
      Header: 'Status',
      accessor: (row: IOrderListItem) =>
        formatOrderStatus(
          featureFlag.isEnabled(Feature.orderStatusStepperUI) ? row.cerillion_stage ?? row.state : row.state
        ),
      id: 'order_state',
      disableSortBy: true,
      width:
        localStorage.getItem(`${tableName}-width-order_state`) !== null
          ? localStorage.getItem(`${tableName}-width-order_state`)
          : 90,
      tableName,
      storeKeyName,
      options: Object.values(OrderStatus).map((status) => ({
        label: formatOrderStatus(status),
        value: status,
      })),
      key: 'Status',
    }),
    updatedAt: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: DateRangeFilter,
      style: overflowVisibleStyle,
      Header: 'Last modified',
      accessor: 'updated_at',
      id: 'updated_at',
      width:
        localStorage.getItem(`${tableName}-width-updated_at`) !== null
          ? localStorage.getItem(`${tableName}-width-updated_at`)
          : 130,
      tableName,
      storeKeyName,
      key: 'Last modified',
    }),
    quoteStatus: (tableName?: TableNames, storeKeyName?: string) => ({
      Filter: PresetSelectedColumnFilter,
      Header: 'Status',
      accessor: formatQuoteState,
      id: 'quote_state',
      disableSortBy: true,
      width:
        localStorage.getItem(`${tableName}-width-quote_state`) !== null
          ? localStorage.getItem(`${tableName}-width-quote_state`)
          : 100,
      options: Object.values(QuoteStatus).map((status) => ({
        label: formatQuoteState(status),
        value: status,
      })),
      tableName,
      storeKeyName,
      key: 'Status',
    }),
  };
}
