import React, { FC, useEffect, useState } from 'react';
import StyledBrowseInput from 'shared/components/atoms/BrowseInput';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import SectionHeader from 'shared/components/atoms/SectionHeader';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import styled from 'styled-components';
import { EditableField } from '../EditableField';
import DatePicker from 'react-datepicker';
import { formatDate } from 'shared/utils/dateHelper';
import { HtmlEditor } from 'shared/components/organisms/HtmlEditor';
import { usePromotions } from 'PromoOffer/promoApi';
import { toUpdatePromoBody, UpdatePromotionBody, UpdatePromotionRequest } from 'PromoOffer/crud/updatePromoOffer';
import { IPromoOffer } from 'PromoOffer/promoTypes';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { PromoBanner } from 'Welcome/PromoBanner';
import Column from 'shared/components/atoms/Column';

interface IPromoOfferProps {
  className?: string;
}
const UnstyledPromoOfferSettings: FC<React.PropsWithChildren<IPromoOfferProps>> = ({ className }) => {
  const { currentPromotion, isLoading, updatePromotion } = usePromotions();

  return (
    <PromoOfferSettingsInternal
      className={className}
      promoOffer={currentPromotion}
      isLoading={isLoading}
      updatePromotion={updatePromotion}
    />
  );
};

function CurrentImage({ url, isDisplayed }: { url: string | undefined; isDisplayed: boolean }) {
  return (
    <div>
      {!isDisplayed && (
        <h4>
          <em>Image currently hidden</em>
        </h4>
      )}
      <img src={url} />
    </div>
  );
}

export const PromoOfferSettingsInternal: FC<
  IPromoOfferProps & {
    promoOffer: IPromoOffer | undefined;
    updatePromotion: (data: { promoId: string; data: UpdatePromotionBody }) => void;
    isLoading: boolean;
  }
> = ({ className, promoOffer, isLoading, updatePromotion }) => {
  useTrackPage(pages.promoOffer);

  const [isEditing, setIsEditing] = useState(false);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreviewURL, setImagePreviewURL] = useState('');
  const [displayImage, setDisplayImage] = useState(false);

  const [validFrom, setValidFrom] = useState<Date | null>();
  const [validUntil, setValidUntil] = useState<Date | null>();

  useEffect(() => {
    if (!image) return;

    const objectUrl = URL.createObjectURL(image);
    setImagePreviewURL(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  useEffect(() => {
    if (!promoOffer) return;

    setFieldsToCurrentPromoOffer(promoOffer);
  }, [promoOffer]);

  const setFieldsToCurrentPromoOffer = (data: IPromoOffer) => {
    if (!data) return;

    setTitle(data.attributes.title || '');
    setBody(data.attributes.body || '');
    setValidFrom(new Date(data.attributes.validFrom!));
    setValidUntil(new Date(data.attributes.validUntil!));
    setDisplayImage(data.attributes.banner.display);
  };

  const resetChanges = () => {
    setIsEditing(false);
    setFieldsToCurrentPromoOffer(promoOffer!);
  };

  const renderEditingButtons = () => {
    return (
      <div className="editing-buttons">
        {isEditing && (
          <div className="d-flex">
            <Button mainStyle={ButtonMainStyle.PrimaryOutline} disabled={false} onClick={resetChanges}>
              Cancel
            </Button>

            <Button mainStyle={ButtonMainStyle.Primary} disabled={false} onClick={onSubmit}>
              Save changes
            </Button>
          </div>
        )}

        {!isEditing && (
          <Button
            mainStyle={ButtonMainStyle.PrimaryOutline}
            disabled={false}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  const onSubmit = async () => {
    setIsEditing(false);

    const data: Partial<UpdatePromotionRequest> = {
      title: title,
      body: body,
      valid_from: validFrom ? validFrom.toISOString() : null,
      valid_until: validUntil ? validUntil.toISOString() : null,
      banner_hidden: !displayImage,
    };

    updatePromotion({
      promoId: '1234567890',
      data: await toUpdatePromoBody(data, image),
    });
  };

  return (
    <HeaderWithChildrenWrapper className="" header="Promotion settings" isLoading={isLoading}>
      <div className={className}>
        <SectionHeader rightSideContent={renderEditingButtons()} text="Dashboard promotion" />

        {!isEditing && (
          <div className="mb-3" data-testid="promo-preview">
            <h2>Welcome Page Preview</h2>
            {promoOffer && (
              <Column lgWidth={8}>
                <PromoBanner {...promoOffer.attributes} forceDisplay={true} />
              </Column>
            )}
          </div>
        )}
        <div className="promo-form" data-testid="promo-form">
          <h2>Current Promotion Configuration</h2>
          <EditableField label="Title" isEditing={isEditing} value={promoOffer?.attributes.title}>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={50} />
          </EditableField>

          <EditableField
            label="Body"
            isEditing={isEditing}
            customDisplayValue={
              <div
                dangerouslySetInnerHTML={{
                  __html: promoOffer?.attributes.body || '',
                }}
              ></div>
            }
          >
            <HtmlEditor value={body} onChange={setBody} />
          </EditableField>

          <EditableField
            label="Image"
            isEditing={isEditing}
            customDisplayValue={
              <CurrentImage
                url={promoOffer?.attributes.banner.url}
                isDisplayed={promoOffer?.attributes.banner.display ?? false}
              />
            }
          >
            <ToggleButton
              defaultChecked={displayImage}
              label="Display image alongside promotion"
              onToggleChange={(event) => setDisplayImage(event.target.checked)}
            />
            <div className="mb-3">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>New Image</h4>
                <StyledBrowseInput
                  onUploadFile={(files) => {
                    setImage(files[0]);
                  }}
                  inputText="Upload image"
                  accept="image/*"
                />
              </div>
              {imagePreviewURL && <img className="mt-3" src={imagePreviewURL} />}
            </div>

            <div className="mb-2">
              <h4>Current Image</h4>
              {promoOffer?.attributes.banner.url && <img src={promoOffer?.attributes.banner.url} />}
            </div>
          </EditableField>

          <EditableField
            label="Valid from"
            isEditing={isEditing}
            value={promoOffer?.attributes.validFrom ? formatDate(promoOffer?.attributes.validFrom) : ''}
          >
            <DatePicker
              popperPlacement="bottom"
              isClearable
              onChange={(date) => {
                setValidFrom(date as Date);
              }}
              selected={validFrom}
              dateFormat="dd/MM/yyyy"
            />
          </EditableField>

          <EditableField
            label="Valid until"
            isEditing={isEditing}
            value={promoOffer?.attributes.validUntil ? formatDate(promoOffer?.attributes.validUntil) : ''}
          >
            <DatePicker
              popperPlacement="bottom"
              isClearable
              onChange={(date) => {
                setValidUntil(date as Date);
              }}
              selected={validUntil}
              dateFormat="dd/MM/yyyy"
            />
          </EditableField>
        </div>
      </div>
    </HeaderWithChildrenWrapper>
  );
};

const PromoOfferSettings = styled(UnstyledPromoOfferSettings)`
  .promo-form {
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 2em;
  }

  .editing-buttons {
    button {
      width: 170px;
      height: 44px;
    }
  }

  img {
    max-width: 100%;
  }

  input {
    width: 100%;
  }
`;

export default PromoOfferSettings;
