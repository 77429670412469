import React, { useEffect } from 'react';
import { BearerType, isOpticalBearer } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ConnectorType, ISiteConfig, MediaType } from 'Order/types/location';
import RadioButton, { RadioButtonDisabledOverride } from 'shared/components/molecules/RadioButton';
import { mediaTypeForDisplay } from 'Order/OrderBuilder/shared/utils/mediaTypeForDisplay';
import { useSelector } from 'react-redux';
import {
  selectProductType,
  selectQuote,
  selectRJ45ConnectorTypeAvailable,
  selectSRMediaTypeAvailable,
} from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import { getDIAOrderOptions } from './DIAOrderOptions';
import { Feature } from 'FeatureFlags/types';
import { StackedOptions } from '../utils/StackedOptions';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

interface Props {
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  endPort: BearerType;
  isSupplierNNAT: boolean;
  onChange(field: keyof ISiteConfig, value: any): void;
}

function getMDIAOptions(isManagedDIA: boolean, endPort: BearerType, isONAT: boolean) {
  const { mediaType } = getDIAOrderOptions(isManagedDIA, endPort, isONAT);

  return {
    showLX: mediaType.isLXAvailable,
    showSX: mediaType.isSXAvailable,
    showLR: mediaType.isLRAvailable,
    showSR: mediaType.isSRAvailable,
    showTX: mediaType.isTXAvailable,
  };
}

function getWavelengthAvailability(siteConfig: ISiteConfig, endPort: BearerType, isNNAT: boolean) {
  if (
    siteConfig.connectorType !== ConnectorType.RJ45 &&
    (endPort === BearerType.MEDIUM || (isNNAT && endPort === BearerType.SMALL))
  ) {
    return { showLX: true, showSX: true };
  }

  return { showLX: false, showSX: false };
}

function getReachAvailability(endPort: BearerType, isSRAvailable: boolean) {
  if (!isOpticalBearer(endPort)) return { showLR: false, showSR: false };

  return { showLR: true, showSR: isSRAvailable };
}

function getMediaOptions(
  endPort: BearerType,
  isNNAT: boolean,
  siteConfig: ISiteConfig,
  isSRAvailable: boolean,
  isRJ45Available: boolean
) {
  return {
    ...getWavelengthAvailability(siteConfig, endPort, isNNAT),
    ...getReachAvailability(endPort, isSRAvailable),
    showTX: isRJ45Available && siteConfig.connectorType === ConnectorType.RJ45,
  };
}

export function useMediaTypes(
  identifierPrefix: string,
  endPort: BearerType,
  isSupplierNNAT: boolean,
  siteConfig: ISiteConfig
) {
  const RJ45Availability = useSelector(selectRJ45ConnectorTypeAvailable);
  const isRJ45Available = identifierPrefix === 'aEndLocation' ? RJ45Availability.A : RJ45Availability.B;
  const SRAvailability = useSelector(selectSRMediaTypeAvailable);
  const isSRAvailable = identifierPrefix === 'aEndLocation' ? SRAvailability.A : SRAvailability.B;

  const isManagedDIA = useSelector(selectQuote).location.aEnd.is_managed_dia;
  const productType = useSelector(selectProductType);

  const { showLX, showSX, showLR, showSR, showTX } =
    featureFlag.isEnabled(Feature.newMDIAUI) && productType === ProductType.DIA
      ? getMDIAOptions(isManagedDIA, endPort, isSupplierNNAT)
      : getMediaOptions(endPort, isSupplierNNAT, siteConfig, isSRAvailable, isRJ45Available);
  return { isManagedDIA, showLX, showSX, showLR, showSR, showTX };
}

const MediaTypeOptions = ({ endPort, identifierPrefix, isSupplierNNAT, onChange, siteConfig }: Props): JSX.Element => {
  const { isManagedDIA, showLX, showSX, showLR, showSR, showTX } = useMediaTypes(
    identifierPrefix,
    endPort,
    isSupplierNNAT,
    siteConfig
  );

  useEffect(() => {
    if (siteConfig.mediaType) return;

    switch (endPort) {
      case BearerType.SMALL: {
        if (
          !isSupplierNNAT ||
          (isSupplierNNAT && siteConfig.connectorType === ConnectorType.RJ45 && siteConfig.mediaType !== MediaType.TX)
        ) {
          onChange('mediaType', MediaType.TX);
        } else if (isSupplierNNAT && siteConfig.connectorType === ConnectorType.LC && !siteConfig.mediaType) {
          onChange('mediaType', MediaType.LX);
        }
        break;
      }
      case BearerType.MEDIUM: {
        if (
          (!isSupplierNNAT &&
            (siteConfig.connectorType === ConnectorType.LC || siteConfig.connectorType === ConnectorType.SC)) ||
          (isSupplierNNAT && siteConfig.connectorType === ConnectorType.LC && !siteConfig.mediaType)
        ) {
          onChange('mediaType', MediaType.LX);
        }
        break;
      }
      case BearerType.LARGE: {
        if (!isSupplierNNAT) {
          onChange('mediaType', MediaType.LR);
        } else if (isSupplierNNAT && siteConfig.connectorType === ConnectorType.LC && !siteConfig.mediaType) {
          onChange('mediaType', MediaType.LR);
        }
        break;
      }
      default:
        break;
    }
  }, [siteConfig]);

  return (
    <StackedOptions>
      {showLX && (
        <RadioButton
          id={`${identifierPrefix}_mediaType_LX`}
          description={mediaTypeForDisplay(MediaType.LX)}
          onClick={() => onChange('mediaType', MediaType.LX)}
          selected={siteConfig.mediaType === MediaType.LX}
        />
      )}

      {showSX && (
        <RadioButton
          id={`${identifierPrefix}_mediaType_SX`}
          description={mediaTypeForDisplay(MediaType.SX)}
          onClick={() => onChange('mediaType', MediaType.SX)}
          selected={siteConfig.mediaType === MediaType.SX}
        />
      )}

      {showLR && (
        <RadioButton
          id={`${identifierPrefix}_mediaType_LR`}
          description={mediaTypeForDisplay(MediaType.LR)}
          onClick={() => onChange('mediaType', MediaType.LR)}
          selected={siteConfig.mediaType === MediaType.LR}
        />
      )}
      {showSR && (
        <RadioButton
          id={`${identifierPrefix}_mediaType_SR`}
          description={mediaTypeForDisplay(MediaType.SR)}
          onClick={() => onChange('mediaType', MediaType.SR)}
          selected={siteConfig.mediaType === MediaType.SR}
        />
      )}

      {showTX && (
        <RadioButtonDisabledOverride>
          <RadioButton
            id={`${identifierPrefix}_mediaType_TX`}
            description={mediaTypeForDisplay(MediaType.TX)}
            onClick={() => onChange('mediaType', MediaType.TX)}
            selected={siteConfig.mediaType === MediaType.TX}
            disabled={isManagedDIA}
          />
        </RadioButtonDisabledOverride>
      )}
    </StackedOptions>
  );
};

export default MediaTypeOptions;
