import { getJwt } from 'Auth/cognito';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { VerboseErrorMessage } from './components/VerboseErrorMessage';
import React from 'react';
import { GenericErrorMessage } from 'Request/components/GenericErrorMessage';
import delay from 'delay';
import dayjs from 'dayjs';

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum StatusCode {
  Accepted = 202,
  Forbidden = 403,
}

export interface ErrorFromBE {
  status: number;
  title: string;
}

export interface ErrorResponse {
  data?: {
    errors?: ErrorFromBE[];
  };
}

export interface ErrorResponsePayload {
  response: ErrorResponse;
}

interface IRequestOptions {
  method?: Methods;
  path?: string;
  body?: any;
  url?: string;
  withAuth?: boolean;
  params?: Record<string, string>;
  onError?: (error: AxiosError) => void;
}

export interface IPagingResponse {
  data: any[];
  links: {
    next: string;
  };
  meta?: {
    total_count: number;
  };
}

export enum ResourceTypes {
  nni = 'nni',
  bulk_quote = 'bulk_quote',
  price = 'price',
  order = 'order',
  supplier_nni = 'supplier_nni',
  quote = 'quote',
  pop = 'pop',
  onat_address = 'onat_address',
  paf_address = 'paf_address',
  data_centre = 'data_centre',
  cerillion_basket = 'cerillion_basket',
  message = 'message',
  order_template = 'order_template',
}

export type Resource = {
  attributes: Record<string, any>;
  id: string;
  type: ResourceTypes;
  relationships?: Record<string, Record<string, any>>;
  links?: Record<string, string>;
};

export enum ResourceQueryParam {
  nni = 'nni',
  shadow_nni = 'shadow_nni',
  a_end_supplier_nni = 'a_end_supplier_nni',
  b_end_supplier_nni = 'b_end_supplier_nni',
  nni_pop = 'nni_pop',
  shadow_nni_pop = 'shadow_nni_pop',
  prices = 'prices',
  orders = 'orders',
  bulk_quote = 'bulk_quote',
  downstream_responses = 'downstream_responses',
  onat_addresses = 'onat_addresses',
  paf_addresses = 'paf_addresses',
  messages = 'messages',
  secondary_pops = 'secondary_pops',
}

export type Response = {
  data: Resource;
  included: Resource[];
};

export type APIResponse<D, L, M> = {
  data: D[];
  links?: L[];
  meta?: M[];
};

export const doAPIRequest = async <D, L, M>(requestOptions: IRequestOptions): Promise<APIResponse<D, L, M>> => {
  return doRequest(requestOptions);
};

const defaultErrorHandling = (error: AxiosError) => {
  if (featureFlag.isEnabled(Feature.requestErrorToasts)) {
    const verbose = featureFlag.getAttribute<boolean>(Feature.requestErrorToasts, 'verbose');
    if (verbose) {
      toast.error(React.createElement(VerboseErrorMessage, { error }));
    } else {
      toast.error(GenericErrorMessage);
    }
  }
};

export const doRequest = async <T = any>(requestParams: IRequestOptions): Promise<T> => {
  const request = await doRequest1<T>(requestParams);
  return request.data;
};

export const doRequest1 = async <T = any>({
  method = Methods.GET,
  path,
  body,
  url,
  withAuth = true,
  params,
  onError = defaultErrorHandling,
}: IRequestOptions): Promise<AxiosResponse<T>> => {
  const token = await getJwt();

  const requestData = {
    method: method,
    url: url ? url : `${process.env.REACT_APP_SSE_API_URL}${path}`,
    data: body,
  };

  const headers = {
    Pragma: 'no-cache',
    ...(withAuth ? { Authorization: `Bearer ${token}` } : {}),
  };

  return await axios({
    ...requestData,
    headers,
    params,
  }).catch((error: AxiosError) => {
    onError(error);
    return Promise.reject(error) as any;
  });
};

export const doRequestWithPolling = async <T = any>(requestParams: IRequestOptions): Promise<T> => {
  let attempts = 0;
  const timeBetweenPolls = dayjs.duration({ seconds: 10 }).asMilliseconds();
  const maxTimeout = dayjs.duration({ minutes: 5 }).asMilliseconds();
  const maxAttempts = maxTimeout / timeBetweenPolls;

  while (maxAttempts > attempts) {
    const response = await doRequest1<T>(requestParams);
    if (response.status !== StatusCode.Accepted) return response.data;
    attempts++;
    await delay(timeBetweenPolls);
  }
  return Promise.reject('Loading prices took too long');
};
