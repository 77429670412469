import { MediaType } from '../../../types/location';

const mediaTypeForDisplay = (mediaType: MediaType | null) => {
  if (!mediaType) {
    return '';
  }

  if (mediaType === MediaType.LR) {
    return 'Single Mode (LR)';
  }

  if (mediaType === MediaType.SR) {
    return 'Multi Mode (SR)';
  }

  return mediaType.replace(/(.*)(\([A-Z]{2}\))/, '$1 $2');
};

export { mediaTypeForDisplay };
