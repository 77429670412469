import produce from 'immer';
import { parse } from 'postcode';
import {
  IFullAddressTypePayload,
  ISetPricesOnCircuit,
  ISetSelectedPriceOnCircuit,
  QuoteAction,
  QuoteActionTypes,
} from './types/actions';
import {
  AddressType,
  ILocation,
  ILocationGroup,
  INNI,
  IPriceData,
  IPriceDataWithBandwidth,
  IQuotesState,
  ProviderName,
  QuoteOrigin,
  UploadState,
} from './types/store';
import { QuoteStatus } from './types/quoteRecordAttributesBase';
import { Supplier, SupplierEndType } from './types/supplier';
import { getAddressAttrsBEnd } from './utils/getAddressAttrsBEnd';
import { getAddressAttrsAEnd } from './utils/getAddressAttrsAEnd';
import { IPType } from './QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { parseJsonApiResponse } from 'Request/utils/parseErrorResponse';
import { deserialiseJsonApiResponse } from 'Request/utils/deserialiseJsonApiResponse';
import { ProductType } from './types/productTypes';
import { getPortSpeedFromPortString, maxPortSpeedFilter, reducePortEnds } from 'shared/utils/connectionCapacity';
import { convertPriceDataFromQuotePrice } from './QuoteBuilder/utils/price';
import { IQuoteData, IQuotePricedOrOrdered } from './types/quoteRecord';
import { getOpenreachAddressAttrsAEnd } from './utils/getOpenreachAddressAttrsAEnd';
import { getOpenreachAddressAttrsBEnd } from './utils/getOpenreachAddressAttrsBEnd';
import { getEndTypesForProduct } from 'shared/utils/getEndTypesForProduct';
import { productTypeHasFTTX } from './utils/productTypeHasFTTX';
import { PointType } from 'shared/types/pointType';
import { isFTTXCompatibleOpenreachLocation } from 'shared/utils/addresses/helperFunctions';
import { getOverallAvailability } from './utils/transformAccessAvailability';
import { isFTTXQuote } from './utils/isFTTXQuote';
import { INNIRecord, PopResource } from 'Location/NNI/types';
import { cloneDeep, intersection, last } from 'lodash';
import { BearerType, bearerTypes } from './QuoteBuilder/components/Configure/Bearer/BearerType';
import { ResourceQueryParam } from 'Request';
import { isNeosNetworksSupplier } from './utils/supplierHelpers';
import { isValidONATAddressResponseItem } from 'shared/types/onatAddress';
import { AvailabilityCheckState, Source } from './types/availabilityCheck';
import { IPAddressAllocation } from './QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { transformMessagesForReducer } from 'shared/utils/messagesHelpers';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { Action } from 'redux';
import setSelectedPrice, {
  addPricesToSelectedCircuit,
  setSecondaryCircuits,
  setSecondaryCircuitsEnabled,
  setSelectedPriceOnCircuit,
} from 'Quotes/setSelectedPrice';
import { IOpticalDataCentre } from 'Location/OpticalDataCentres/types';

export const MAX_SELECTABLE_BANDWIDTHS = 5;
export const MAX_SELECTABLE_TERM_LENGTHS = 3;

const setSupplierFilter = (filterList: Supplier[], supplier: Supplier): Supplier[] => {
  if (isNeosNetworksSupplier(supplier)) {
    if (
      !filterList.includes(Supplier.ONAT) &&
      !filterList.includes(Supplier.NNAT) &&
      !filterList.includes(Supplier.NONE)
    ) {
      return [...filterList, Supplier.ONAT, Supplier.NNAT, Supplier.NONE];
    }

    return filterList.filter((s) => {
      return !isNeosNetworksSupplier(s);
    });
  }

  if (!filterList.includes(supplier)) {
    return [...filterList, supplier];
  }

  return filterList.filter((s) => s !== supplier);
};

const resetFilteredPrices = (draft: IQuotesState) => {
  const prices = draft.pricing.allPrices;
  const aEndFilterList = draft.pricing.aEndFilterList;
  const bEndFilterList = draft.pricing.bEndFilterList;
  const subProductTypeFilterList = draft.pricing.subProductTypeFilterList;
  const bandwidthFilterList = draft.pricing.bandwidthFilterList;
  const contractTermFilterList = draft.pricing.contractTermFilterList;

  if (aEndFilterList.length && bEndFilterList.length === 0) {
    draft.pricing.filteredPrices = prices.filter((price: IPriceData) =>
      aEndFilterList.includes(price.a_end_access_type as Supplier)
    );
  }

  if (bEndFilterList.length && aEndFilterList.length === 0) {
    draft.pricing.filteredPrices = prices.filter((price: IPriceData) =>
      bEndFilterList.includes(price.b_end_access_type as Supplier)
    );
  }

  if (aEndFilterList.length && bEndFilterList.length) {
    draft.pricing.filteredPrices = prices.filter(
      (price) =>
        aEndFilterList.includes(price.a_end_access_type as Supplier) &&
        bEndFilterList.includes(price.b_end_access_type as Supplier)
    );
  }

  draft.pricing.filteredPrices = draft.pricing.filteredPrices
    .filter((price) => {
      if (!price.product_sub_type) {
        return true;
      }
      return subProductTypeFilterList.includes(price.product_sub_type);
    })
    .filter((price) => {
      if (!price.bandwidth) {
        return true;
      }
      return bandwidthFilterList.includes(price.bandwidth);
    })
    .filter((price) => {
      if (!price.term_length_in_months) {
        return true;
      }
      return contractTermFilterList.includes(price.term_length_in_months);
    });
};

const resetLocationData = (draft: IQuotesState, end: 'A' | 'B') => {
  if (end === 'A') {
    draft.quoteEndpointMeta.a_end_address_not_listed = false;
    draft.quote.location.aEnd.addressesFound = emptyAddressesFound;
    draft.quote.location.aEnd.fullAddress = null;
    draft.quote.location.aEnd.onNetType = null;
    draft.quote.location.aEnd.openreachAddress = null;
  } else {
    draft.quoteEndpointMeta.b_end_address_not_listed = false;
    draft.quote.location.bEnd.addressesFound = emptyAddressesFound;
    draft.quote.location.bEnd.fullAddress = null;
    draft.quote.location.bEnd.onNetType = null;
    draft.quote.location.bEnd.openreachAddress = null;
  }
};

const setEndTypeFilter = (prices: IPriceData[], endType: SupplierEndType): Supplier[] => {
  if (endType === SupplierEndType.AEnd) {
    return [...new Set(prices.map((price) => price.a_end_access_type))] as Supplier[];
  }

  return [...new Set(prices.map((price) => price.b_end_access_type))] as Supplier[];
};

const getOrderShortId = (included: IQuotePricedOrOrdered[]): string | undefined => {
  const order = included?.find((item) => item.type === 'order');
  return order?.attributes?.short_id;
};

export const getOrigin = (data: IQuoteData) => {
  if (data.attributes.origin) {
    return data.attributes.origin;
  }

  if (data.attributes.is_bulk) {
    return QuoteOrigin.BULK;
  }

  if (/^[0-9]{7,8}$/.test(data.id)) {
    return QuoteOrigin.API;
  }

  return QuoteOrigin.APP;
};

const setLQError = (data: IQuoteData): IQuotesState['pricing']['lqPricingError'] => {
  const { attributes } = data;

  if (!attributes.lq_pricing_error) {
    return null;
  }

  if (attributes.state !== QuoteStatus.DRAFT) {
    return null;
  }

  return attributes.lq_pricing_error;
};

export const emptyAddressesFound = {
  onNet: [],
  openreach: [],
  paf: [],
};

export const initialState: IQuotesState = {
  cloning: {},
  creating: {},
  downloading: {},
  pricing: {
    selectedDummyPrice: undefined,
    selectedPrice: {
      id: '',
      is_poa: false,
      a_end_access_type: null,
      a_end_annual_cost: null,
      a_end_p_o_p: null,
      a_end_p_o_p_id: null,
      a_end_p_o_p_postcode: null,
      a_end_p_o_p_address: null,
      a_end_setup_cost: null,
      a_end_cabling_charge: null,
      annual_price: null,
      annual_setup_price: null,
      b_end_cabling_charge: null,
      b_end_access_type: null,
      b_end_annual_cost: null,
      b_end_p_o_p: null,
      b_end_p_o_p_postcode: null,
      b_end_setup_cost: null,
      a_end_exchange_type: null,
      b_end_exchange_type: null,
      a_end_product_name: null,
      b_end_product_name: null,
      setup_price: null,
      shadow_vlan_price: null,
      annual_discount: null,
      install_discount: null,
      margin_amount: null,
      margin_percentage: null,
      port_a_cost: null,
      port_b_cost: null,
      port_costs_annual: null,
      port_costs_setup: null,
      annual_ip_charge: null,
      amortised: false,
      a_end_gea_cablelink_annual_cost: null,
      b_end_gea_cablelink_annual_cost: null,
      a_end_gea_cablelink_install_cost: null,
      b_end_gea_cablelink_install_cost: null,
      cloud_connect: {
        provider: ProviderName.NOT_DEFINED,
        annual: null,
      },
      supplier_annual_cost: null,
      supplier_setup_cost: null,
      amortised_annual_price: null,
      net_amortised_annual_price: null,
      net_annual_price: null,
      net_install_price: null,
      net_amortised_install_price: null,
      total_contract_value: null,
      a_end_cross_connect_cost: null,
      b_end_cross_connect_cost: null,
      a_end_cabling_type: null,
      b_end_cabling_type: null,
      mdia_annual_cost: null,
      mdia_annual_price: null,
      mdia_install_cost: null,
      mdia_install_price: null,
      mdia_engineer_cost: null,
      mdia_engineer_price: null,
      mdia_rack_mount_kit_cost: null,
      mdia_rack_mount_kit_price: null,
      mdia_router_cost: null,
      mdia_router_price: null,
      product_sub_type: null,
      fttp_aggregation_charge: null,
      annual_price_with_fttp_aggregation: null,
      net_annual_price_with_fttp_aggregation: null,
      margin_percentage_with_fttp_aggregation: null,
      total_contract_value_with_fttp_aggregation: null,
      total_cost: null,
      total_price: null,
      net_amortised_annual_price_with_fttp_aggregation: null,
      subnetPrices: undefined,
    },
    pricingProgress: {},
    allPrices: [],
    filteredPrices: [],
    aEndFilterList: [],
    bEndFilterList: [],
    lqPricingError: null,
    subProductTypeFilterList: ['ethernet', 'optical', 'unprotected'],
    bandwidthFilterList: [],
    contractTermFilterList: [],
  },
  quoteEndpointMeta: {
    preferred_p2p_price_id: '',
    a_end_address_not_listed: false,
    b_end_address_not_listed: false,
    a_end_nni_shadow_reference: '',
    a_end_nni_shadow_data_centre_reference: '',
    a_end_on_net_name: '',
    b_end_on_net_name: '',
    nni_label: '',
    a_end_location_lookup: 'postcode',
    b_end_location_lookup: 'postcode',
    cabling_type_selection: [],
    journey_origin: undefined,
  },
  associatedOrderId: undefined,
  associatedOrderShortId: undefined,
  quote: {
    diverseOptionFor: null,
    diverseOption: null,
    requiresAvailabilityCheck: false,
    availability: undefined,
    shortId: '',
    origin: QuoteOrigin.APP,
    bandwidth: '',
    bearer: undefined,
    aEndAccessMethod: null,
    bEndAccessMethod: null,
    aEndBandwidth: null,
    bEndBandwidth: null,
    aEndPort: undefined,
    bEndPort: undefined,
    aEndExcludeSSEOnNet: true,
    bEndExcludeSSEOnNet: true,
    aEndL2SID: null,
    bEndL2SID: null,
    aEndMDFID: null,
    bEndMDFID: null,
    bulkQuoteId: undefined,
    createdAt: '',
    createdBy: '',
    connectionType: 'Ethernet',
    cerillion_opportunity_stage_history: null,
    cerillion_quote_status_message: null,
    isPoa: false,
    contractTerm: '36',
    customerId: '',
    customerName: '',
    location: {
      aEnd: {
        addressType: null,
        addressesFound: emptyAddressesFound,
        addressesRetrieving: {
          onNetAndPaf: false,
          openreach: false,
        },
        dataCentreReference: '',
        draftPostcode: '',
        fullAddress: {
          id: undefined,
          attributes: {
            udprn: '',
            organisation_name: '',
            building_name: '',
            building_number: '',
            county: '',
            post_town: '',
            postcode: '',
            thoroughfare: '',
            sub_building: '',
          },
        },
        onatAddress: null,
        openreachAddress: null,
        ip: {
          selectedId: null,
        },
        nni: {
          capacity: {
            bandwidth: null,
            ports: [],
          },
          reference: '',
          popName: '',
          popAddress: '',
          selectedId: '',
          selectedDataCentreId: '',
          shadowVLAN: {
            enabled: false,
            selectedId: '',
            selectedDataCentreId: '',
            name: '',
            location: '',
          },
        },
        onNetType: null,
        optical: {
          list: undefined,
          dataCentreNotListed: false,
          selectedId: '',
        },
        cloudConnect: {
          providersList: [],
          name: ProviderName.NOT_DEFINED,
          supportedBandwidths: [],
          usage: '',
          diversified: false,
        },
        postcode: '',
        is_managed_dia: false,
        is_engineer_installation_required: false,
        is_rack_mount_required: false,
        secondIPChoice: undefined,
        routerChoice: undefined,
        dia_ip_allocation: null,
      },
      bEnd: {
        addressType: null,
        addressesFound: emptyAddressesFound,
        addressesRetrieving: {
          onNetAndPaf: false,
          openreach: false,
        },
        dataCentreReference: '',
        draftPostcode: '',
        fullAddress: {
          id: undefined,
          attributes: {
            udprn: '',
            organisation_name: '',
            building_name: '',
            building_number: '',
            county: '',
            post_town: '',
            postcode: '',
            thoroughfare: '',
            sub_building: '',
          },
        },
        onatAddress: null,
        openreachAddress: null,
        onNetType: null,
        optical: { list: undefined, dataCentreNotListed: false, selectedId: '' },
        postcode: '',
      },
    },
    productType: null,
    updatedAt: '',
    is_internal: false,
    a_end_onat_address_id: null,
    b_end_onat_address_id: null,
    sent_to_cerillion_at: null,
    chosen_bandwidths: [],
    chosen_term_lengths_in_months: ['36'],
    is_multiquote: false,
    fttpAggregation: null,
    messages: [],
  },
  retrieveAPIQuoteState: {
    inProgress: true,
  },
  retrieving: {
    errorResponse: null,
    inProgress: false,
  },
  checkingAvailability: {
    errorResponse: null,
    inProgress: false,
  },
  publishing: {
    error: false,
    inProgress: false,
  },
  state: QuoteStatus.DRAFT,
  updating: {},
  bulkQuoteUploadState: UploadState.EMPTY,
  editMode: false,
  savingONATAddress: {},
  availabilityCheck: {
    active: ['point_left', 'point_right'],
    sources: {
      point_left: {},
      point_right: {},
      nni: {},
      cloud: {},
    },
    failed: false,
    loading: false,
    showConfig: false,
  },
  bulkOrderCreateState: {
    successfullyCreatedOrders: [],
    successfullyCreatedPOAQuotes: [],
    failedQuotes: [],
    creating: {},
  },
};

export const quoteRequiresAvailabilityCheck = (location: ILocationGroup, productType: ProductType | null) => {
  if (!productType || !productTypeHasFTTX(productType)) {
    return false;
  }

  const aEndHasOpenReachAddress = isFTTXCompatibleOpenreachLocation(location.aEnd.openreachAddress);
  const bEndHasOpenReachAddress = isFTTXCompatibleOpenreachLocation(location.bEnd.openreachAddress);

  const ends = getEndTypesForProduct(productType);
  if (ends.aEnd === PointType.POINT && ends.bEnd === PointType.POINT) {
    return aEndHasOpenReachAddress && bEndHasOpenReachAddress;
  } else if (ends.aEnd === PointType.POINT) {
    return aEndHasOpenReachAddress;
  } else if (ends.bEnd === PointType.POINT) {
    return bEndHasOpenReachAddress;
  }
  return false;
};

export const getNniCapacity = (
  nni: Partial<INNIRecord['attributes']>,
  shadowNni: Partial<INNIRecord['attributes']>,
  nniPop: Partial<PopResource['attributes']>,
  shadowNniPop: Partial<PopResource['attributes']>
) => {
  let capacity: INNI['capacity'] = {
    bandwidth: null,
    ports: [],
  };

  // Existing NNI
  if (nni.port_type && nni.pop_max_bandwidth) {
    const portSpeed = getPortSpeedFromPortString(nni.port_type);
    const portSpeedAsInt = portSpeed ? parseInt(portSpeed, 10) : 0;

    capacity = {
      bandwidth: parseInt(nni.pop_max_bandwidth, 10),
      ports: bearerTypes.filter(maxPortSpeedFilter(portSpeedAsInt)),
    };
    // New NNI
  } else if (Array.isArray(nniPop.ports) && nniPop.max_bandwidth) {
    const maxPortSpeed = getPortSpeedFromPortString(last(nniPop.ports)!);
    const portSpeedAsInt = maxPortSpeed ? parseInt(maxPortSpeed, 10) : 0;

    capacity = {
      bandwidth: parseInt(nniPop.max_bandwidth, 10),
      ports: bearerTypes.filter(maxPortSpeedFilter(portSpeedAsInt)),
    };
  }

  // Existing shadow NNI
  if (shadowNni.port_type && shadowNni.pop_max_bandwidth) {
    const portSpeed = getPortSpeedFromPortString(shadowNni.port_type);
    const portSpeedAsInt = portSpeed ? parseInt(portSpeed, 10) : 0;

    capacity.bandwidth = Math.min(capacity.bandwidth || 0, parseInt(shadowNni.pop_max_bandwidth, 10));

    capacity.ports = intersection(capacity.ports, bearerTypes.filter(maxPortSpeedFilter(portSpeedAsInt)));
    // New shadow NNI
  } else if (Array.isArray(shadowNniPop.ports) && shadowNniPop.max_bandwidth) {
    const maxPortSpeed = getPortSpeedFromPortString(last(shadowNniPop.ports)!);
    const portSpeedAsInt = maxPortSpeed ? parseInt(maxPortSpeed, 10) : 0;

    capacity.bandwidth = Math.min(capacity.bandwidth || 0, parseInt(shadowNniPop.max_bandwidth, 10));

    capacity.ports = intersection(capacity.ports, bearerTypes.filter(maxPortSpeedFilter(portSpeedAsInt)));
  }

  return capacity;
};

const getInitialAvailabilityCheck = (productType: ProductType): AvailabilityCheckState => {
  switch (productType) {
    case ProductType.DIA:
      return {
        ...initialState.availabilityCheck,
        active: ['point_left', 'point_right'],
        sources: {
          ...initialState.availabilityCheck.sources,
          point_left: {
            is_dia: true,
          },
        },
        showConfig: true,
      };
    case ProductType.P2P:
      return {
        ...initialState.availabilityCheck,
        active: ['point_left', 'point_right'],
        showConfig: true,
      };
    case ProductType.P2NNI:
      return {
        ...initialState.availabilityCheck,
        active: ['point_left', 'nni'],
        showConfig: true,
      };
    case ProductType.P2CCT:
      return {
        ...initialState.availabilityCheck,
        active: ['point_left', 'cloud'],
        showConfig: true,
      };
    default:
      return initialState.availabilityCheck;
  }
};

const setFullAddressOn = (end: ILocation, payload: IFullAddressTypePayload) => {
  const fullAddress = payload.fullAddress;
  end.fullAddress = fullAddress;
  end.openreachAddress = null;
  end.addressesFound.openreach = [];

  if (!fullAddress) {
    end.addressType = null;
  } else if ('type' in fullAddress && fullAddress.type === 'on_net_building') {
    end.addressType = AddressType.ON_NET;
    end.onNetType = payload.onNetType ?? null;
  } else {
    end.addressType = AddressType.PAF;
    end.onNetType = null;
  }
};

export function convertQuoteRecord(
  action: Action & { payload?: any } & {
    key?: keyof IQuotesState;
    notListed?: boolean;
  },
  inProgress: boolean,
  opticalDataCentres: { aEnd: IOpticalDataCentre[] | undefined; bEnd: IOpticalDataCentre[] | undefined } = {
    aEnd: undefined,
    bEnd: undefined,
  }
): IQuotesState {
  const { data, included } = action.payload.quote;
  const { attributes } = data;

  const deserialised = deserialiseJsonApiResponse(action.payload.quote);
  const aEndSupplierNNI = deserialised.selectSupplierNNI(ResourceQueryParam.a_end_supplier_nni);
  const bEndSupplierNNI = deserialised.selectSupplierNNI(ResourceQueryParam.b_end_supplier_nni);
  const nni = deserialised.selectNNI();
  const shadowNni = deserialised.selectShadowNNI();
  const nniPop = deserialised.selectNniPop();
  const shadowNniPop = deserialised.selectNniShadowPop();
  const onatAddresses = deserialised.selectONATAddresses().filter(isValidONATAddressResponseItem);
  const bulkQuote = deserialised.selectBulkQuote();
  const messages = deserialised.selectMessages();

  const nniPortAndBandwidthCapacity = getNniCapacity(nni, shadowNni, nniPop, shadowNniPop);

  const location: ILocationGroup = {
    aEnd: {
      ...initialState.quote.location.aEnd,
      addressType: (attributes.a_end_address_type as AddressType) || null,
      dataCentreReference: attributes.a_end_n_n_i_reference ? '' : attributes.a_end_data_centre_reference || '',
      draftPostcode: attributes.a_end_postcode || '',
      ip: {
        selectedId:
          attributes.ip_count ||
          (attributes.product_type === ProductType.DIA ? initialState.quote.location.aEnd.ip.selectedId : undefined),
      },
      nni: {
        capacity: {
          bandwidth: nniPortAndBandwidthCapacity.bandwidth,
          ports: nniPortAndBandwidthCapacity.ports,
        },
        reference: nni.reference || attributes.a_end_n_n_i_reference || '',
        popName:
          attributes.product_type === ProductType.P2NNI || attributes.product_type === ProductType.NNI2CCT
            ? attributes.a_end_data_centre_reference
            : '',
        popAddress: nni.pop_address || '',
        selectedId: attributes.a_end_n_n_i_id && attributes.a_end_n_n_i_id !== '0' ? attributes.a_end_n_n_i_id : '',
        selectedDataCentreId: attributes.a_end_data_centre_id || '',
        shadowVLAN: {
          enabled: !!attributes.a_end_n_n_i_shadow_vlan_id || !!attributes.a_end_n_n_i_shadow_vlan_data_centre_id,
          selectedId: attributes.a_end_n_n_i_shadow_vlan_id || '',
          selectedDataCentreId: attributes.a_end_n_n_i_shadow_vlan_data_centre_id || '',
          name: attributes.shadow_vlan_pop_name || '',
          location: attributes.shadow_vlan_pop_address || '',
        },
      },
      optical: {
        list: opticalDataCentres.aEnd,
        selectedId: attributes.a_end_data_centre_id || '',
        dataCentreNotListed: false,
      },
      postcode: attributes.a_end_postcode || '',
      fullAddress: {
        id: attributes.a_end_paf_address_id,
        attributes: { ...getAddressAttrsAEnd(attributes) },
      },
      openreachAddress: attributes.a_end_openreach_address ? { ...getOpenreachAddressAttrsAEnd(attributes) } : null,
      cloudConnect: {
        ...initialState.quote.location.aEnd.cloudConnect,
        name: attributes.cloud_connect_option?.provider || ProviderName.NOT_DEFINED,
        diversified:
          attributes.cloud_connect_option?.diversified === undefined
            ? false
            : attributes.cloud_connect_option?.diversified,
      },
      supplierNNI: aEndSupplierNNI,
      is_managed_dia: attributes.is_managed_dia || false,
      dia_ip_allocation: attributes.dia_ip_allocation || null,
      is_engineer_installation_required: attributes.is_engineer_installation_required || false,
      is_rack_mount_required: attributes.is_rack_mount_required || false,
      routerChoice: attributes.secondary_circuit_options?.mdia_cpe_selection || undefined,
      secondIPChoice: attributes.secondary_circuit_options?.ip_count || undefined,
      secondRouterOptions: {
        engineerInstallationRequired: attributes.secondary_circuit_options?.is_engineer_installation_required,
        rackMountKitRequired: attributes.secondary_circuit_options?.is_rack_mount_required,
      },
      onatAddress: onatAddresses.find((address) => address.id === attributes.a_end_onat_address_id) || null,
    },
    bEnd: {
      ...initialState.quote.location.bEnd,
      addressType: (attributes.b_end_address_type as AddressType) || null,
      dataCentreReference: attributes.b_end_data_centre_reference || '',
      optical: {
        list: opticalDataCentres.bEnd,
        selectedId: attributes.b_end_data_centre_id || '',
        dataCentreNotListed: !!attributes.a_end_data_centre_id && !!attributes.b_end_postcode,
      },
      draftPostcode: attributes.b_end_postcode || '',
      postcode: attributes.b_end_postcode || '',
      fullAddress: {
        id: attributes.b_end_paf_address_id,
        attributes: { ...getAddressAttrsBEnd(attributes) },
      },
      openreachAddress: attributes.b_end_openreach_address ? { ...getOpenreachAddressAttrsBEnd(attributes) } : null,
      supplierNNI: bEndSupplierNNI,
      onatAddress: onatAddresses.find((address) => address.id === attributes.b_end_onat_address_id) || null,
    },
  };

  return {
    ...initialState,
    retrieving: {
      ...initialState.retrieving,
      inProgress: inProgress,
    },
    associatedOrderId: data?.relationships?.orders?.data?.[0]?.id || undefined,
    associatedOrderShortId: getOrderShortId(included) || undefined,
    currentQuoteId: data.id,
    lqId: data.attributes.lq_id,
    quoteEndpointMeta: attributes.meta || {},
    pricing: {
      ...initialState.pricing,
      lqPricingError: setLQError(data),
    },
    quote: {
      ...initialState.quote,
      diverseOptionFor: attributes.diverse_option_for || null,
      diverseOption: attributes.diverse_option || null,
      requiresAvailabilityCheck: quoteRequiresAvailabilityCheck(location, attributes.product_type),
      shortId: data.attributes.short_id,
      origin: getOrigin(data),
      bandwidth:
        Array.isArray(attributes.chosen_bandwidths) && !!attributes.chosen_bandwidths[0]
          ? attributes.chosen_bandwidths[0]
          : attributes.path_speed || '',
      bearer: reducePortEnds(attributes.product_type, attributes.a_end_port, attributes.b_end_port),
      aEndAccessMethod: attributes.a_end_access_method || null,
      bEndAccessMethod: attributes.b_end_access_method || null,
      aEndBandwidth: attributes.a_end_bandwidth || null,
      bEndBandwidth: attributes.b_end_bandwidth || null,
      aEndPort: attributes.a_end_port || undefined,
      bEndPort: attributes.b_end_port || undefined,
      aEndExcludeSSEOnNet: attributes.a_end_exclude_sse_onnet ?? true,
      bEndExcludeSSEOnNet: attributes.b_end_exclude_sse_onnet ?? true,
      aEndL2SID: attributes.a_end_l2sid || null,
      bEndL2SID: attributes.b_end_l2sid || null,
      aEndMDFID: attributes.a_end_mdfid || null,
      bEndMDFID: attributes.b_end_mdfid || null,
      /** @deprecated multiple terms are now supported by `chosen_term_lengths_in_months` */
      contractTerm: attributes.term_length_in_months || '',
      connectionType: getOverallAvailability(
        attributes.a_end_access_method,
        attributes.b_end_access_method,
        attributes.product_type
      ),
      createdAt: data.attributes.created_at,
      createdBy: data.attributes.created_by,
      customerId: attributes.customer_id || '',
      customerName: attributes.customer_name || '',
      bulkQuoteId: attributes.bulk_request_id || undefined,
      isPoa: !!attributes.is_poa,
      location,
      productType: attributes.product_type || null,
      updatedAt: attributes.updated_at,
      is_internal: attributes.is_internal || false,
      a_end_onat_address_id: attributes.a_end_onat_address_id || null,
      b_end_onat_address_id: attributes.b_end_onat_address_id || null,
      sent_to_cerillion_at: attributes.sent_to_cerillion_at || null,
      chosen_bandwidths: Array.isArray(attributes.chosen_bandwidths)
        ? attributes.chosen_bandwidths
        : attributes.path_speed
        ? [attributes.path_speed]
        : [],
      chosen_term_lengths_in_months: Array.isArray(attributes.chosen_term_lengths_in_months)
        ? attributes.chosen_term_lengths_in_months
        : [],
      fttpAggregation: attributes.fttp_aggregation ?? null,
      is_multiquote: typeof bulkQuote?.short_id === 'string' && bulkQuote.short_id.startsWith('mq-'),
      cerillion_stage: attributes.cerillion_stage || null,
      cerillion_opportunity_stage_history: attributes.cerillion_opportunity_stage_history || null,
      cerillion_quote_status_message: attributes.cerillion_quote_status_message || null,
      messages: transformMessagesForReducer(messages),
    },
    state: attributes.is_poa ? QuoteStatus.PRICED : attributes.state,
    availabilityCheck: getInitialAvailabilityCheck(attributes.product_type),
  };
}

const reducer = produce((draft: IQuotesState, action: QuoteAction): void | IQuotesState => {
  switch (action.type) {
    case QuoteActionTypes.DOWNLOAD_QUOTES_STARTED:
      draft.downloading.inProgress = true;
      draft.downloading.error = false;
      break;

    case QuoteActionTypes.DOWNLOAD_QUOTES_ENDED:
      draft.downloading = {};
      break;

    case QuoteActionTypes.DOWNLOAD_QUOTES_ERROR:
      draft.downloading.error = true;
      draft.downloading.inProgress = false;
      break;

    case QuoteActionTypes.SET_FULL_ADDRESS_A_END: {
      setFullAddressOn(draft.quote.location.aEnd, action.payload);
      break;
    }

    case QuoteActionTypes.SET_FULL_ADDRESS_B_END: {
      setFullAddressOn(draft.quote.location.bEnd, action.payload);
      break;
    }

    case QuoteActionTypes.SET_OPENREACH_ADDRESS: {
      const { address, end } = action.payload;

      if (end === 'A') {
        draft.quote.location.aEnd.openreachAddress = address;
        draft.quote.location.aEnd.addressType = AddressType.OPENREACH;
      } else {
        draft.quote.location.bEnd.openreachAddress = address;
        draft.quote.location.bEnd.addressType = AddressType.OPENREACH;
      }
      break;
    }

    case QuoteActionTypes.SET_ADDRESS_NOT_LISTED_A_END:
      draft.quoteEndpointMeta.a_end_address_not_listed = action.payload.notListed;

      if (action.payload.notListed) {
        draft.quote.location.aEnd.addressType = null;
        draft.quote.location.aEnd.fullAddress = null;
        draft.quote.location.aEnd.onNetType = null;
      }

      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_ADDRESS_NOT_LISTED_B_END:
      draft.quoteEndpointMeta.b_end_address_not_listed = action.payload.notListed;

      if (action.payload.notListed) {
        draft.quote.location.bEnd.addressType = null;
        draft.quote.location.bEnd.fullAddress = null;
        draft.quote.location.bEnd.onNetType = null;
      }

      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_ADDRESSES_FOUND_A_END:
      draft.quote.location.aEnd.addressesFound = action.payload.addresses;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_ADDRESSES_FOUND_B_END:
      draft.quote.location.bEnd.addressesFound = action.payload.addresses;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_POSTCODE_A_END:
      resetLocationData(draft, 'A');
      draft.quote.location.aEnd.postcode = parse(action.payload.postcode).postcode ?? '';
      break;

    case QuoteActionTypes.SET_POSTCODE_B_END:
      resetLocationData(draft, 'B');
      draft.quote.location.bEnd.postcode = parse(action.payload.postcode).postcode ?? '';
      break;

    case QuoteActionTypes.SET_DRAFT_POSTCODE_A_END:
      draft.quote.location.aEnd.draftPostcode = parse(action.payload.postcode).postcode ?? '';
      draft.quote.location.aEnd.fullAddress = null;
      draft.quote.location.aEnd.onNetType = null;
      draft.quote.location.aEnd.openreachAddress = null;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_DRAFT_POSTCODE_B_END:
      draft.quote.location.bEnd.draftPostcode = parse(action.payload.postcode).postcode ?? '';
      draft.quote.location.bEnd.fullAddress = null;
      draft.quote.location.bEnd.onNetType = null;
      draft.quote.location.bEnd.openreachAddress = null;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_PRODUCT_TYPE:
      draft.quote.productType = action.payload.productType;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_BEARER:
      draft.quote.bandwidth = '';
      draft.quote.chosen_bandwidths = [];
      draft.quote.bearer = action.payload.bearer;
      draft.quote.connectionType = 'Ethernet';
      draft.editMode = true;

      draft.quote.location.aEnd.is_rack_mount_required = action.payload.bearer === BearerType.LARGE;
      break;

    case QuoteActionTypes.SET_DIVERSITY:
      draft.quote.location.aEnd.cloudConnect.diversified = action.payload.diversity;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_BANDWIDTH:
      draft.quote.bandwidth = action.payload.bandwidth;
      draft.editMode = true;
      break;

    case QuoteActionTypes.TOGGLE_CHOSEN_BANDWIDTH: {
      const bandwidth = action.payload.bandwidth;

      if (draft.quote.chosen_bandwidths.includes(bandwidth)) {
        draft.quote.chosen_bandwidths = draft.quote.chosen_bandwidths.filter((bw) => bw !== bandwidth);
      } else {
        draft.quote.chosen_bandwidths.push(bandwidth);
      }

      draft.quote.chosen_bandwidths.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

      break;
    }

    case QuoteActionTypes.TOGGLE_CHOSEN_CONTRACT_TERM: {
      const termLength = action.payload.term;

      if (draft.quote.chosen_term_lengths_in_months.includes(termLength)) {
        draft.quote.chosen_term_lengths_in_months = draft.quote.chosen_term_lengths_in_months.filter(
          (tl) => tl !== termLength
        );
      } else {
        draft.quote.chosen_term_lengths_in_months.push(termLength);
      }

      draft.quote.chosen_term_lengths_in_months.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

      draft.quote.contractTerm = draft.quote.chosen_term_lengths_in_months[0];

      break;
    }

    case QuoteActionTypes.SET_SUPPORTED_BANDWIDTHS:
      draft.quote.location.aEnd.cloudConnect.supportedBandwidths = action.payload.bandwidths;
      break;

    case QuoteActionTypes.SET_CONTRACT_TERM_LENGTH:
      draft.quote.contractTerm = action.payload.contractTerm;
      draft.quote.chosen_term_lengths_in_months = [action.payload.contractTerm];
      draft.editMode = true;
      break;

    case QuoteActionTypes.CREATE_QUOTE_STARTED:
      draft.creating.inProgress = true;
      draft.creating.error = false;
      break;

    case QuoteActionTypes.CREATE_QUOTE_ENDED:
      draft.creating.inProgress = false;
      break;

    case QuoteActionTypes.SET_CURRENT_QUOTE_DATA:
      draft.currentQuoteId = action.payload.currentQuote.id;
      draft.quoteEndpointMeta = action.payload.currentQuote.attributes.meta || {};
      break;
    case QuoteActionTypes.SET_AMORTISATION:
      draft.pricing.selectedPrice.amortised = action.payload.amortised;
      draft.editMode = true;
      break;
    case QuoteActionTypes.SET_FTTP_AGGREGATION:
      draft.quote.fttpAggregation = action.payload.fttpAggregation;
      draft.editMode = true;
      break;

    case QuoteActionTypes.SET_ROUTER_CHOICE:
      draft.quote.location.aEnd.routerChoice = action.payload.routerChoice;
      break;

    case QuoteActionTypes.CLEAR_ROUTER_CHOICE:
      draft.quote.location.aEnd.routerChoice = action.payload.routerChoice;
      break;

    case QuoteActionTypes.SET_SECONDARY_CIRCUIT_OPTIONS:
      if (!draft.quote.location.aEnd.secondRouterOptions) draft.quote.location.aEnd.secondRouterOptions = {};

      if (typeof action.payload.engineerInstallationRequired !== 'undefined') {
        draft.quote.location.aEnd.secondRouterOptions.engineerInstallationRequired =
          action.payload.engineerInstallationRequired;
      }

      if (typeof action.payload.rackMountKitRequired !== 'undefined') {
        draft.quote.location.aEnd.secondRouterOptions.rackMountKitRequired = action.payload.rackMountKitRequired;
      }
      break;

    case QuoteActionTypes.CREATE_QUOTE_ERROR:
      draft.creating.error = true;
      break;

    case QuoteActionTypes.CLONE_QUOTE_STARTED:
      draft.cloning.inProgress = true;
      draft.cloning.error = false;
      break;

    case QuoteActionTypes.CLONE_QUOTE_ENDED:
      draft.cloning.inProgress = false;
      break;

    case QuoteActionTypes.CLONE_QUOTE_ERROR:
      draft.cloning.error = true;
      break;

    case QuoteActionTypes.SET_PRICE_DATA: {
      const prices = action.payload.prices;
      draft.pricing.allPrices = prices;
      draft.pricing.filteredPrices = prices;
      draft.pricing.lqPricingError = null;
      draft.pricing.bandwidthFilterList = [];
      draft.pricing.contractTermFilterList = [];

      if (prices.some((price: IPriceDataWithBandwidth) => !!price.bandwidth)) {
        const pricesWithBandwidth: IPriceDataWithBandwidth[] = prices.filter(
          (price: IPriceDataWithBandwidth) => !!price.bandwidth
        );
        draft.pricing.bandwidthFilterList = [...new Set(pricesWithBandwidth.map((price) => price.bandwidth))];
      }

      if (prices.some((price: IPriceData) => !!price.term_length_in_months)) {
        const pricesWithTerms: IPriceData[] = prices.filter((price: IPriceData) => !!price.term_length_in_months);

        draft.pricing.contractTermFilterList = [
          ...new Set(pricesWithTerms.map((price) => price.term_length_in_months!)),
        ];
      }

      draft.pricing.aEndFilterList = setEndTypeFilter(prices, SupplierEndType.AEnd);

      draft.pricing.bEndFilterList = setEndTypeFilter(prices, SupplierEndType.BEnd);

      if (draft.state !== QuoteStatus.LAPSED && draft.state !== QuoteStatus.ORDERED) {
        draft.state = QuoteStatus.PRICED;
      }

      break;
    }

    case QuoteActionTypes.REFRESH_FILTERED_PRICES: {
      resetFilteredPrices(draft);
      break;
    }

    case QuoteActionTypes.SET_FILTERED_PRICE_DATA: {
      resetFilteredPrices(draft);

      let aEndFilterList = draft.pricing.aEndFilterList;
      let bEndFilterList = draft.pricing.bEndFilterList;

      if (draft.quote.productType === ProductType.P2NNI) {
        aEndFilterList = [];
      } else if (draft.quote.productType === ProductType.DIA || draft.quote.productType === ProductType.P2CCT) {
        bEndFilterList = [];
      }

      if (draft.pricing.filteredPrices.length === 0 || (aEndFilterList.length === 0 && bEndFilterList.length === 0)) {
        draft.pricing.filteredPrices = [];
        draft.pricing.selectedPrice = initialState.pricing.selectedPrice;
      } else {
        draft.pricing.selectedPrice = draft.pricing.filteredPrices[0];
      }

      break;
    }

    case QuoteActionTypes.SET_SELECTED_PRICE: {
      const prices: IPriceData[] = draft.pricing.allPrices;
      const {
        preferredPriceId,
        amortised,
        missingPrices,
      }: {
        preferredPriceId: string;
        amortised: boolean | undefined;
        missingPrices: IDummyPrice[] | undefined;
      } = action.payload;

      setSelectedPrice(draft, preferredPriceId, amortised, prices, missingPrices);
      break;
    }

    case QuoteActionTypes.SET_SECONDARY_CIRCUITS_ENABLED: {
      setSecondaryCircuitsEnabled(draft, action.payload.priceId, action.payload.enabled);
      break;
    }

    case QuoteActionTypes.SET_SECONDARY_CIRCUITS_ON_SELECTED_PRICE: {
      setSecondaryCircuits(draft, action.payload.priceId, action.payload.secondaryCircuits);
      break;
    }

    case QuoteActionTypes.ADD_PRICES_TO_CIRCUIT: {
      const { primaryPriceId, circuitId, prices } = action.payload as ISetPricesOnCircuit['payload'];
      addPricesToSelectedCircuit(draft, primaryPriceId, circuitId, prices);
      break;
    }

    case QuoteActionTypes.SELECT_PRICE_ON_CIRCUIT: {
      const { primaryPriceId, circuitId, priceId } = action.payload as ISetSelectedPriceOnCircuit['payload'];
      setSelectedPriceOnCircuit(draft, primaryPriceId, circuitId, priceId);
      break;
    }

    case QuoteActionTypes.SET_SUPPLIER_END_FILTER: {
      const { supplier, endType } = action.payload;

      switch (endType) {
        case SupplierEndType.AEnd: {
          draft.pricing.aEndFilterList = setSupplierFilter(draft.pricing.aEndFilterList, supplier);

          break;
        }

        case SupplierEndType.BEnd: {
          draft.pricing.bEndFilterList = setSupplierFilter(draft.pricing.bEndFilterList, supplier);

          break;
        }
        default:
          break;
      }
      break;
    }

    case QuoteActionTypes.UPDATE_QUOTE_STARTED:
      draft.updating.error = false;
      draft.updating.inProgress = true;
      break;

    case QuoteActionTypes.UPDATE_QUOTE_ENDED:
      draft.updating.inProgress = false;
      break;

    case QuoteActionTypes.UPDATE_QUOTE_ERROR:
      draft.updating.error = true;
      break;

    case QuoteActionTypes.UPDATE_QUOTE_AND_GET_PRICE: {
      draft.pricing.pricingProgress.error = undefined;
      draft.pricing.pricingProgress.errorMessage = undefined;
      break;
    }

    case QuoteActionTypes.RESET_PRICING_PROGRESS_STATE: {
      draft.pricing.pricingProgress = {};
      break;
    }

    case QuoteActionTypes.PRICE_QUOTE_ENDED:
      draft.pricing.pricingProgress.fetchingPrice = false;
      draft.pricing.pricingProgress.loadingUpdate = false;
      if (!draft.pricing.pricingProgress.error) {
        draft.pricing.pricingProgress.updatingPrice = false;
        draft.pricing.pricingProgress.updatingSuccess = true;
      }
      break;

    case QuoteActionTypes.PRICE_QUOTE_ERROR:
      draft.pricing.pricingProgress.error = true;
      draft.pricing.pricingProgress.loadingUpdate = false;
      draft.pricing.pricingProgress.updatingSuccess = false;
      draft.pricing.pricingProgress.errorMessage = parseJsonApiResponse(action.payload.error);

      draft.pricing.lqPricingError = null;

      draft.pricing.pricingProgress.cablingSuccess = false;
      draft.pricing.pricingProgress.updatingCabling = false;
      break;

    case QuoteActionTypes.UPDATE_QUOTE_PRICE: {
      draft.pricing.pricingProgress.loadingUpdate = true;

      if (action.payload.updatingCabling) {
        if (!Array.isArray(draft.quoteEndpointMeta.cabling_type_selection)) {
          draft.quoteEndpointMeta.cabling_type_selection = [];
        }

        const existingSelection = draft.quoteEndpointMeta.cabling_type_selection.find(
          (item) => item.priceId === action.payload.priceId
        );

        if (existingSelection) {
          draft.quoteEndpointMeta.cabling_type_selection = draft.quoteEndpointMeta.cabling_type_selection.map(
            (item) => {
              if (item.priceId !== action.payload.priceId) {
                return item;
              }

              return {
                priceId: item.priceId,
                a_end_cabling_type_selected:
                  action.payload.updatingCablingOrigin === 'A' ? true : item.a_end_cabling_type_selected,
                b_end_cabling_type_selected:
                  action.payload.updatingCablingOrigin === 'B' ? true : item.b_end_cabling_type_selected,
              };
            }
          );
        } else {
          draft.quoteEndpointMeta.cabling_type_selection.push({
            priceId: action.payload.priceId,
            a_end_cabling_type_selected: action.payload.updatingCablingOrigin === 'A' ? true : undefined,
            b_end_cabling_type_selected: action.payload.updatingCablingOrigin === 'B' ? true : undefined,
          });
        }

        draft.pricing.pricingProgress.cablingSuccess = false;
      }

      break;
    }

    case QuoteActionTypes.PRICE_QUOTE_STARTED:
      draft.pricing.pricingProgress.error = false;
      draft.pricing.pricingProgress.loadingUpdate = true;
      draft.pricing.pricingProgress.requiresAsyncPrices = action.payload.requiresAsyncPrices;
      draft.pricing.pricingProgress.fetchingPrice = !action.payload.updatingCabling;
      draft.pricing.pricingProgress.updatingPrice = action.payload.updatingPrice;
      draft.pricing.pricingProgress.updatingCabling = action.payload.updatingCabling;
      break;

    case QuoteActionTypes.PRICE_COMPLEX_QUOTE_UPDATE_PROGRESS:
      draft.pricing.pricingProgress.complexQuoteProgress = action.payload.progress;
      break;

    case QuoteActionTypes.SET_CABLING_SUCCESS_STATE:
      draft.pricing.pricingProgress.cablingSuccess = action.payload.state;
      break;

    case QuoteActionTypes.GET_EXISTING_QUOTE_ENDED:
      draft.retrieving.inProgress = false;
      break;

    case QuoteActionTypes.GET_EXISTING_QUOTE_ERROR:
      draft.retrieving.error = true;
      draft.retrieving.errorResponse = action.payload.response;
      break;

    case QuoteActionTypes.GET_EXISTING_QUOTE_STARTED:
      draft.retrieving.error = false;
      draft.retrieving.inProgress = true;
      break;

    case QuoteActionTypes.SET_QUOTE_CONNECTION_TYPE:
      draft.quote.connectionType = action.payload.connectionType;
      if (isFTTXQuote(draft.quote) && draft.quote.availability?.bandwidths.length === 1) {
        draft.quote.bandwidth = draft.quote.availability.bandwidths[0].value;
      }
      draft.quote.bearer = undefined;
      draft.quote.location.aEnd.is_managed_dia = false;
      draft.quote.chosen_bandwidths = [];
      break;

    case QuoteActionTypes.GET_API_QUOTE_ENDED:
      draft.retrieveAPIQuoteState.inProgress = false;
      break;

    case QuoteActionTypes.GET_API_QUOTE_ERROR:
      draft.retrieveAPIQuoteState.error = true;
      break;

    case QuoteActionTypes.GET_API_QUOTE_STARTED:
      draft.retrieveAPIQuoteState.error = false;
      draft.retrieveAPIQuoteState.inProgress = true;
      break;

    case QuoteActionTypes.REPLACE_QUOTE_STATE: {
      const inProgress = draft.retrieving.inProgress;
      const opticalDataCentres = {
        aEnd: draft.quote.location.aEnd.optical.list,
        bEnd: draft.quote.location.bEnd.optical.list,
      };
      return convertQuoteRecord(action, inProgress, opticalDataCentres);
    }

    case QuoteActionTypes.SET_CERILLION_SENT_AT: {
      const sent_to_cerillion_at = action.payload.cerillionSentAt;

      draft.quote.sent_to_cerillion_at = sent_to_cerillion_at || null;
      break;
    }

    case QuoteActionTypes.SET_QUOTE_POA: {
      draft.quote.isPoa = true;
      draft.state = QuoteStatus.PRICED;
      break;
    }

    case QuoteActionTypes.PRICING_QUOTE_WILL_RETRY: {
      draft.pricing.pricingProgress.pricingQuoteWillRetry = true;
      break;
    }

    case QuoteActionTypes.SET_OPTICAL_DATA_CENTRE_LIST: {
      const { opticalDataCentreList } = action.payload;

      draft.quote.location.aEnd.optical.list = opticalDataCentreList.filter(
        (address: any) => !address.attributes.is_infinera
      );
      draft.quote.location.bEnd.optical.list = opticalDataCentreList;
      break;
    }

    case QuoteActionTypes.SET_CLOUD_PROVIDERS_LIST: {
      const { cloudProvidersList } = action.payload;

      draft.quote.location.aEnd.cloudConnect.providersList = cloudProvidersList;
      draft.editMode = true;
      break;
    }

    case QuoteActionTypes.SET_CLOUD_PROVIDER: {
      const { cloudProvider } = action.payload;

      draft.quote.location.aEnd.cloudConnect.name = cloudProvider.attributes.name;
      draft.quote.location.aEnd.cloudConnect.usage = cloudProvider.attributes.usage;
      draft.quote.location.aEnd.cloudConnect.supportedBandwidths = cloudProvider.attributes.supported_bandwidths;
      break;
    }

    case QuoteActionTypes.SET_DATA_CENTRE_A_END: {
      draft.quote.location.aEnd.optical.selectedId = action.payload.id;
      draft.quote.location.aEnd.dataCentreReference = action.payload.reference;
      draft.editMode = true;
      break;
    }

    case QuoteActionTypes.SET_DATA_CENTRE_B_END: {
      draft.quote.location.bEnd.optical.selectedId = action.payload.id;
      draft.quote.location.bEnd.dataCentreReference = action.payload.reference;

      if (action.payload.id) {
        draft.quote.location.bEnd.draftPostcode = '';
        draft.quote.location.bEnd.postcode = '';
        draft.quote.location.bEnd.addressType = null;
        draft.quote.location.bEnd.fullAddress = null;
        draft.quote.location.bEnd.optical.dataCentreNotListed = false;
      }
      draft.editMode = true;

      break;
    }

    case QuoteActionTypes.SET_DATA_CENTRE_B_END_NOT_LISTED: {
      if (action.notListed) {
        draft.quote.location.bEnd.draftPostcode = '';
        draft.quote.location.bEnd.fullAddress = null;
      }
      draft.quote.location.bEnd.optical.dataCentreNotListed = action.notListed!;
      draft.editMode = true;
      break;
    }

    case QuoteActionTypes.SET_NNI_ID_AND_REFERENCE: {
      const { id, nniReference, type, postcode, dataCentreReference, label } = action.payload;

      if (!action.payload.shadowVLAN) {
        draft.quote.location.aEnd.nni.selectedId = type === 'nni' ? id : '';
        draft.quote.location.aEnd.nni.selectedDataCentreId = type === 'nni' ? '' : id;
        draft.quote.location.aEnd.nni.reference = nniReference;
        draft.quote.location.aEnd.nni.popName = type === 'nni' ? dataCentreReference : '';
        draft.quote.location.aEnd.dataCentreReference = type === 'nni' ? '' : dataCentreReference;
        draft.quote.location.aEnd.postcode = postcode;
        draft.quoteEndpointMeta.nni_label = label;
        return;
      }

      draft.quote.location.aEnd.nni.shadowVLAN.selectedId = type === 'nni' ? id : '';
      draft.quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId = type === 'nni' ? '' : id;
      draft.quoteEndpointMeta.a_end_nni_shadow_reference = type === 'nni' ? `${label}` : '';
      draft.quoteEndpointMeta.a_end_nni_shadow_data_centre_reference = type === 'nni' ? '' : dataCentreReference;

      draft.editMode = true;
      break;
    }

    case QuoteActionTypes.TOGGLE_NNI_SHADOW_VLAN: {
      const isEnabled = draft.quote.location.aEnd.nni.shadowVLAN.enabled;

      draft.quote.location.aEnd.nni.shadowVLAN.enabled = !isEnabled;

      if (isEnabled) {
        draft.quote.location.aEnd.nni.shadowVLAN.selectedId = '';
        draft.quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId = '';
        draft.quoteEndpointMeta.a_end_nni_shadow_reference = '';
        draft.quoteEndpointMeta.a_end_nni_shadow_data_centre_reference = '';
      }

      break;
    }

    case QuoteActionTypes.RESET_QUOTE_STATE:
      return initialState;

    case QuoteActionTypes.BULK_QUOTE_SUBMIT_STARTED:
      draft.bulkQuoteUploadState = UploadState.IN_PROGRESS;
      break;

    case QuoteActionTypes.BULK_QUOTE_SUBMIT_ENDED:
      draft.bulkQuoteUploadState = UploadState.SUCCESSFUL;
      break;

    case QuoteActionTypes.BULK_QUOTE_SUBMIT_ERROR: {
      draft.bulkQuoteUploadState = [UploadState.ERRORED, action.payload.errors || []];
      break;
    }

    case QuoteActionTypes.RESET_BULK_QUOTE_SUBMIT_STATE:
      draft.bulkQuoteUploadState = UploadState.EMPTY;
      break;

    case QuoteActionTypes.SET_IP_OPTION:
      draft.quote.location.aEnd.ip.selectedId = action.payload.option;
      break;

    case QuoteActionTypes.SET_SECOND_IP_OPTION:
      draft.quote.location.aEnd.secondIPChoice = action.payload.option;
      break;

    case QuoteActionTypes.SET_DIA_IP_ALLOCATION:
      draft.quote.location.aEnd.dia_ip_allocation = action.payload.dia_ip_allocation;

      delete draft.quote.location.aEnd.ip.selectedId;

      // When user selects WAN only 31 set the IP option to /31
      if (action.payload.dia_ip_allocation === IPAddressAllocation.WAN_ONLY_31)
        draft.quote.location.aEnd.ip.selectedId = IPType.SUBNET_31;

      break;

    case QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_STARTED: {
      draft.checkingAvailability.error = false;
      draft.checkingAvailability.errorResponse = null;
      draft.checkingAvailability.inProgress = true;
      break;
    }
    case QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ENDED: {
      draft.checkingAvailability.inProgress = false;
      draft.quote.availability = action.payload.availability;
      break;
    }
    case QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ERROR: {
      draft.checkingAvailability.error = true;
      draft.checkingAvailability.errorResponse = action.payload.response;
      draft.checkingAvailability.inProgress = false;
      break;
    }
    case QuoteActionTypes.UPDATE_SINGLE_PRICE: {
      const updatedPriceId = action.payload.price.id;
      draft.pricing.allPrices = draft.pricing.allPrices.map((price: IPriceData) =>
        price.id === updatedPriceId ? convertPriceDataFromQuotePrice(action.payload.price) : price
      );
      break;
    }

    case QuoteActionTypes.SET_SHORT_BULK_QUOTE_ID: {
      draft.quote.shortBulkQuoteId = action.payload.shortBulkQuoteId;
      break;
    }

    case QuoteActionTypes.SET_BULK_QUOTE_ID: {
      draft.quote.bulkQuoteId = action.payload.bulkQuoteId;
      break;
    }

    case QuoteActionTypes.ADDRESS_RETRIEVAL: {
      const { aEnd, onNetAndPaf, retrieve } = action.payload;

      if (aEnd && onNetAndPaf) {
        draft.quote.location.aEnd.addressesRetrieving.onNetAndPaf = retrieve;
      } else if (aEnd && !onNetAndPaf) {
        draft.quote.location.aEnd.addressesRetrieving.openreach = retrieve;
      } else if (!aEnd && onNetAndPaf) {
        draft.quote.location.bEnd.addressesRetrieving.onNetAndPaf = retrieve;
      } else {
        draft.quote.location.bEnd.addressesRetrieving.openreach = retrieve;
      }

      break;
    }

    case QuoteActionTypes.PRICE_QUOTE_SUCCESS: {
      draft.state = QuoteStatus.PRICED;
      break;
    }

    case QuoteActionTypes.PUBLISH_QUOTE: {
      draft.publishing.inProgress = true;
      draft.publishing.error = false;
      break;
    }

    case QuoteActionTypes.PUBLISH_QUOTE_FAILURE: {
      draft.publishing.inProgress = false;
      draft.publishing.error = true;
      break;
    }

    case QuoteActionTypes.PUBLISH_QUOTE_SUCCESS: {
      draft.publishing.inProgress = false;
      draft.quote.is_internal = false;
      break;
    }

    case QuoteActionTypes.SWITCH_LOCATION_LOOKUP: {
      if (action.payload.end === 'A') {
        resetLocationData(draft, 'A');
        draft.quoteEndpointMeta.a_end_location_lookup = action.payload.lookupType;
        draft.quote.location.aEnd.postcode = '';
        draft.quote.location.aEnd.draftPostcode = '';
      } else {
        resetLocationData(draft, 'B');
        draft.quoteEndpointMeta.b_end_location_lookup = action.payload.lookupType;
        draft.quote.location.bEnd.postcode = '';
        draft.quote.location.bEnd.draftPostcode = '';
      }
      break;
    }

    case QuoteActionTypes.SET_IS_MANAGED_DIA: {
      draft.quote.location.aEnd.is_managed_dia = action.payload.is_managed_dia;

      if (action.payload.is_managed_dia && draft.quote.location.aEnd.ip.selectedId === IPType.WAN) {
        draft.quote.location.aEnd.ip.selectedId = IPType.STATIC_4;
      } else if (action.payload.is_managed_dia && draft.quote.location.aEnd.ip.selectedId === IPType.SUBNET_31) {
        draft.quote.location.aEnd.ip.selectedId = undefined;
      } else if (!action.payload.is_managed_dia && draft.quote.location.aEnd.ip.selectedId === undefined) {
        draft.quote.location.aEnd.ip.selectedId = IPType.SUBNET_31;
      }
      break;
    }

    case QuoteActionTypes.SET_IS_ENGINEER_INSTALLATION_REQUIRED: {
      draft.quote.location.aEnd.is_engineer_installation_required = action.payload.is_engineer_installation_required;
      break;
    }

    case QuoteActionTypes.SET_IS_RACK_MOUNT_REQUIRED: {
      draft.quote.location.aEnd.is_rack_mount_required = action.payload.is_rack_mount_required;
      break;
    }

    case QuoteActionTypes.SAVE_ONAT_ADDRESS_ID: {
      draft.savingONATAddress.end = action.payload.end;
      draft.savingONATAddress.inProgress = true;
      draft.savingONATAddress[action.payload.end === 'A' ? 'errorA' : 'errorB'] = false;
      break;
    }

    case QuoteActionTypes.SAVE_ONAT_ADDRESS_ID_FAILURE: {
      draft.savingONATAddress[draft.savingONATAddress.end === 'A' ? 'errorA' : 'errorB'] = true;
      draft.savingONATAddress.inProgress = false;
      break;
    }

    case QuoteActionTypes.SAVE_ONAT_ADDRESS_ID_SUCCESS: {
      draft.savingONATAddress.inProgress = false;
      draft.quote.location[draft.savingONATAddress.end === 'A' ? 'aEnd' : 'bEnd'].onatAddress = action.payload.address;
      break;
    }

    case QuoteActionTypes.CLEAR_ONAT_ADDRESS_ID_FAILURE: {
      draft.savingONATAddress[action.payload.end === 'A' ? 'errorA' : 'errorB'] = false;
      break;
    }

    case QuoteActionTypes.AVAILABILITY_CHECK_CHANGE: {
      const activeTabs = draft.availabilityCheck.active;
      draft.availabilityCheck.sources[action.payload.source as Source] = action.payload.changes;
      draft.quote.availability = undefined;

      if (draft.availabilityCheck.sources.point_left.is_dia) {
        draft.quote.productType = ProductType.DIA;

        if (draft.quote.location.aEnd.postcode) {
          draft.availabilityCheck.showConfig = true;
        }
      } else if (activeTabs[1] === 'point_right') {
        draft.quote.productType = ProductType.P2P;

        if (!!draft.quote.location.aEnd.postcode && !!draft.quote.location.bEnd.postcode) {
          draft.availabilityCheck.showConfig = true;
        }
      } else if (activeTabs[1] === 'cloud') {
        draft.quote.productType = ProductType.P2CCT;

        if (
          !!draft.quote.location.aEnd.postcode &&
          draft.quote.location.aEnd.cloudConnect.name !== ProviderName.NOT_DEFINED
        ) {
          draft.availabilityCheck.showConfig = true;
        }
      } else if (activeTabs[1] === 'nni') {
        draft.quote.productType = ProductType.P2NNI;

        if (
          !!draft.quote.location.bEnd.postcode &&
          (draft.quote.location.aEnd.nni.selectedId || draft.quote.location.aEnd.nni.selectedDataCentreId)
        ) {
          draft.availabilityCheck.showConfig = true;
        }
      }

      break;
    }

    case QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_START: {
      draft.availabilityCheck.loading = true;
      draft.availabilityCheck.failed = false;
      break;
    }

    case QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_FAILURE: {
      draft.availabilityCheck.loading = false;
      draft.availabilityCheck.failed = true;
      break;
    }

    case QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_SUCCESS: {
      draft.availabilityCheck.loading = false;
      draft.availabilityCheck.lastestAvailabilityResponse = action.payload.response;

      // Simple solution for now: reset existing selections if the FTTX availability doesn't match.
      if (!!draft.quote.availability?.overall && draft.quote.availability?.overall !== draft.quote.connectionType) {
        draft.quote.connectionType = 'Ethernet';
        draft.quote.bearer = undefined;
        draft.quote.bandwidth = '';
        draft.quote.chosen_bandwidths = [];
      }
      break;
    }

    case QuoteActionTypes.SET_IS_MULTIQUOTE: {
      const is_multiquote = action.payload.isMultiQuote;
      const bulk_request_id = action.payload.bulkRequestId;

      draft.quote.is_multiquote = is_multiquote || false;
      draft.quote.bulkQuoteId = bulk_request_id || null;
      break;
    }

    case QuoteActionTypes.AVAILABILITY_CHECK_TAB_CHANGE: {
      let restoredLocationData: Partial<ILocation> = {};
      const { a_end_address_not_listed, b_end_address_not_listed } = draft.quoteEndpointMeta;

      // Point to NNI A/B End switch
      // If we switch from Point to NNI, transfer over the location data.
      if (action.payload.source === 'nni' && draft.availabilityCheck.active[1] === 'point_right') {
        restoredLocationData = {
          postcode: draft.quote.location.aEnd.postcode,
          fullAddress: cloneDeep(draft.quote.location.aEnd.fullAddress),
          addressType: draft.quote.location.aEnd.addressType,
          addressesFound: cloneDeep(draft.quote.location.aEnd.addressesFound),
          openreachAddress: cloneDeep(draft.quote.location.aEnd.openreachAddress),
        };
        draft.quoteEndpointMeta.b_end_address_not_listed = a_end_address_not_listed;
        draft.quoteEndpointMeta.a_end_address_not_listed = b_end_address_not_listed;
      }

      // Point to NNI A/B End switch
      // If we switch from NNI to Point, transfer over the location data.
      if (action.payload.source === 'point_right' && draft.availabilityCheck.active[1] === 'nni') {
        draft.quote.location.aEnd = {
          ...draft.quote.location.aEnd,
          postcode: draft.quote.location.bEnd.postcode,
          fullAddress: cloneDeep(draft.quote.location.bEnd.fullAddress),
          addressType: draft.quote.location.bEnd.addressType,
          addressesFound: cloneDeep(draft.quote.location.bEnd.addressesFound),
          openreachAddress: cloneDeep(draft.quote.location.bEnd.openreachAddress),
        };
        draft.quoteEndpointMeta.b_end_address_not_listed = a_end_address_not_listed;
        draft.quoteEndpointMeta.a_end_address_not_listed = b_end_address_not_listed;
      }

      // Point to NNI A/B End switch
      // As a result of the switch, we clear previously entered location data to ensure
      // data integrity. It's not great UX. But it'll do for now.
      draft.quote.location.bEnd = {
        ...initialState.quote.location.bEnd,
        ...restoredLocationData,
      };

      draft.availabilityCheck.active = ['point_left', action.payload.source];

      break;
    }

    case QuoteActionTypes.TOGGLE_PRODUCT_SUB_TYPE_FILTER: {
      const subType = action.payload.productSubType;

      if (draft.pricing.subProductTypeFilterList.includes(subType)) {
        draft.pricing.subProductTypeFilterList = draft.pricing.subProductTypeFilterList.filter(
          (item) => item !== subType
        );
      } else {
        draft.pricing.subProductTypeFilterList.push(subType);
      }

      break;
    }

    case QuoteActionTypes.TOGGLE_PRICE_BANDWIDTH_FILTER: {
      const bandwidth = action.payload.bandwidth;

      if (draft.pricing.bandwidthFilterList.includes(bandwidth)) {
        draft.pricing.bandwidthFilterList = draft.pricing.bandwidthFilterList.filter((item) => item !== bandwidth);
      } else {
        draft.pricing.bandwidthFilterList.push(bandwidth);
      }

      break;
    }

    case QuoteActionTypes.NEW_QUOTE_MESSAGE: {
      draft.quote.messages.push(action.payload.message);
      break;
    }

    case QuoteActionTypes.TOGGLE_CONTRACT_TERM_FILTER: {
      const contractTerm = action.payload.contractTerm;

      if (draft.pricing.contractTermFilterList.includes(contractTerm)) {
        draft.pricing.contractTermFilterList = draft.pricing.contractTermFilterList.filter(
          (item) => item !== contractTerm
        );
      } else {
        draft.pricing.contractTermFilterList.push(contractTerm);
      }

      break;
    }

    case QuoteActionTypes.CREATE_MULTIPLE_ORDERS_STARTED: {
      draft.bulkOrderCreateState.creating.inProgress = true;

      break;
    }

    case QuoteActionTypes.CREATE_MULTIPLE_ORDERS_SUCCESS: {
      draft.bulkOrderCreateState.successfullyCreatedOrders = Array.from(
        new Set([...draft.bulkOrderCreateState.successfullyCreatedOrders, action.payload.relatedQuote])
      );

      break;
    }

    case QuoteActionTypes.CREATE_MULTIPLE_ORDERS_POA_SUCCESS: {
      draft.bulkOrderCreateState.successfullyCreatedPOAQuotes = Array.from(
        new Set([...draft.bulkOrderCreateState.successfullyCreatedPOAQuotes, action.payload.relatedQuote])
      );

      break;
    }

    case QuoteActionTypes.CREATE_MULTIPLE_ORDERS_ERROR: {
      draft.bulkOrderCreateState.failedQuotes = Array.from(
        new Set([...draft.bulkOrderCreateState.failedQuotes, action.payload.relatedQuote])
      );

      break;
    }

    case QuoteActionTypes.CREATE_MULTIPLE_ORDERS_ENDED: {
      draft.bulkOrderCreateState.creating.inProgress = false;

      break;
    }

    case QuoteActionTypes.CREATE_MULTIPLE_ORDERS_CLEANUP: {
      draft.bulkOrderCreateState = initialState.bulkOrderCreateState;

      break;
    }

    default:
      return draft;
  }
}, initialState);

export default reducer;
