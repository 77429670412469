import { Supplier } from '../types/supplier';

export const getSupplier = (supplier: Supplier | null, isPoA: boolean | null): Supplier => {
  // TODO: Fix type system so we actually support `null`
  if (supplier) return supplier;
  if (isPoA) return Supplier.NNAT;
  return Supplier.NONE;
};

export function presentSupplierName(supplier: Supplier | string): string {
  switch (supplier) {
    case Supplier.OPENREACH:
      return 'Openreach';
    case Supplier.VIRGIN:
      return 'Virgin';
    case Supplier.BT_WHOLESALE:
      return 'BT Wholesale';
    case Supplier.CITYFIBRE:
      return 'City Fibre';
    case Supplier.VODAFONE:
      return 'Vodafone National';
    case Supplier.NONE:
    case Supplier.ONAT:
    case Supplier.NNAT:
      return 'Neos Networks';
    default:
      return supplier;
  }
}

export function presentSupplierNameForPriceTile(supplier: Supplier | string | null | undefined): string {
  if (!supplier) {
    return ''; // or any default value you want to return
  }

  return presentSupplierName(supplier).replace('Openreach', 'via Openreach').replace('City Fibre', 'via City Fibre');
}

export const isNeosNetworksSupplier = (supplier: Supplier) => {
  return [Supplier.NONE, Supplier.ONAT, Supplier.NNAT].includes(supplier);
};

export const supplierListIncludesNeosNeworksSupplier = (list: Supplier[]) => {
  return list.includes(Supplier.NONE) || list.includes(Supplier.ONAT) || list.includes(Supplier.NNAT);
};
