import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createOrderAction } from 'Order/actions';
import { IAppState } from 'reducers';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { usePreviousState } from 'shared/utils/customHooks';
import Amortisation from './components/Amortisation';
import {
  priceQuote as priceQuoteAction,
  publishQuote as publishQuoteAction,
  setIsMultiQuote as setIsMultiQuoteAction,
  updatePricedQuote,
  updatePricedQuoteAndCreateOrder,
} from 'Quotes/actions';
import IQuoteRecordAttributesBase, { IMeta, QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import { orderByIdAfterCreation } from 'Order/routes';
import { IPriceData, IQuote, QuoteOrigin } from 'Quotes/types/store';
import PresentForRoles from 'Auth/PresentForRoles';
import CostsBreakdown from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/TillReceipt';
import CostAdjustment from './components/CostAdjustment';
import { IPType } from '../Configure/DIAConfig/types/ip';
import { canAmortise } from '../../utils/price';
import { StyledGetInTouch as GetInTouch } from './components/GetInTouch';
import { pages, pagesWithType, trackPage } from 'shared/utils/trackPage';
import { ProductType } from 'Quotes/types/productTypes';
import { userPermissions } from 'shared/utils/permissions';
import Cabling from './components/Cabling';
import { CompanyData } from 'User/types/user';
import { UpdatePricedQuotePayloadPrice, UpdatePricedQuotePayloadQuote } from 'Quotes/types/actions';
import { isFttpAggregationPossible, isFTTXQuote } from 'Quotes/utils/isFTTXQuote';
import { isObject } from 'lodash';
import {
  selectCurrentMeta,
  selectIsInternalQuote,
  selectIsMultiQuote,
  selectIsNNATPrice,
  selectMultiQuoteModalDisplayButton,
  selectPublishing,
  selectSavingONATAddress,
  selectSelectedPriceAnnualCost,
  selectShowShadowVLANPrice,
} from 'Quotes/selectors';
import Modal from '../../../../shared/components/molecules/Modal';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import Checkbox from '../../../../shared/components/atoms/Checkbox';
import Select from '../../../../shared/components/atoms/Select';
import TextInput from '../../../../shared/components/atoms/TextInput';
import { postMultiQuote } from 'Quotes/crud/postMultiQuote';
import { selectSelectedCompanyId } from 'User/selectors';
import updateQuote from '../../../crud/updateQuote';
import { SortDirection, useTablePaging } from 'shared/hooks/useTablePaging';
import { CreatingOrderSpinner, PricingSpinner, SavingSpinner } from './Spinners';
import {
  AdjustQuoteErrorAlert,
  CreateOrderErrorAlert,
  ErrorFetchingPricingAlert,
  ErrorUpdatingQuoteAlert,
  PublishingErrorAlert,
} from './Errors';
import {
  BulkQuoteAlert,
  LapsedQuoteAlert,
  OpticalNoticeAlert,
  QuoteAlreadyOrdered,
  SubmittedQuoteAlert,
  SuccessfulMultiQuoteAlert,
} from './Alerts';
import { PriceBlocks } from 'Quotes/QuoteBuilder/components/Price/PriceBlocks/PriceBlocks';
import { AddToMultiQuoteButton, InternalQuotePublishButton, ReQuoteButton, SaveAndExitButton } from './Buttons';
import { CreateAQuoteOrProceedToOrder } from './CreateQuoteButton';
import FTTPAggregation from './components/FTTPAggregation';
import { requiresAsyncPrices } from 'Quotes/QuoteBuilder/utils/requiresAsyncPrices';
import { supportsFTTPAggregation, supportsFTTPAggregationSecondaryCircuits } from 'Quotes/types/supplier';
import { IComplexQuotePricingProgress } from 'Quotes/types/quoteRecord';
import { AsyncPricesLoadingOverlay } from 'Quotes/QuoteBuilder/components/Configure/ComplexQuoteLoadingOverlay/AsyncPricesLoadingOverlay';
import SubmitPoAMessage, { SubmitProceedToApplicationMessage } from './SubmitPoAMessage';
import PoAModal from 'Quotes/QuoteBuilder/components/Price/PoAModal';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';
import { DIAConfig } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/Totals';

interface IPrice {
  associatedOrderId?: string;
  associatedOrderShortId?: string;
  companyData: CompanyData;
  selectedPrice: IPriceData;
  selectedDummyPrice?: IDummyPrice | undefined;
  className?: string;
  creatingOrder?: boolean;
  creatingOrderError?: boolean;
  fetchingPrice?: boolean;
  updatingPrice?: boolean;
  updatingCabling?: boolean;
  fetchingPriceError?: boolean;
  pricingWillRetry?: boolean;
  fetchingPriceErrorMessage?: string[];
  asyncPrices?: boolean;
  complexQuoteProgress?: IComplexQuotePricingProgress;
  orderId?: string;
  quote: IQuote;
  isPoa?: boolean;
  quoteEndpointMeta?: IMeta;
  quoteId?: string;
  lqId?: string;
  quoteState: IQuoteRecordAttributesBase['state'];
  updatingQuote?: boolean;
  updatingQuoteError?: boolean;
  ipType?: IPType;
  allPrices: IPriceData[];
  bulkQuoteId: IQuote['bulkQuoteId'];
  createNewOrder(
    quoteId: string,
    quote: IQuote,
    customerName: string,
    selectedPrice: IPriceData,
    meta?: IMeta,
    lqId?: string
  ): void;
  onSave(price: UpdatePricedQuotePayloadPrice, quote: UpdatePricedQuotePayloadQuote): void;
  onSaveAndCreate(
    updatePrice: { price: UpdatePricedQuotePayloadPrice; quote: UpdatePricedQuotePayloadQuote },
    createOrder: {
      quoteId: string;
      quote: IQuote;
      customerName: string;
      selectedPrice: IPriceData;
      meta?: IMeta;
      lqId?: string;
    }
  ): void;
  onEdit?(): void;
  priceQuote(quoteId: string, asyncPrices: boolean): void;
  publishQuote(): void;
  setIsMultiQuote(isMultiQuote: boolean, bulkRequestId: string): void;
}

function PoAInfoDisplay(props: { hasPrice: boolean; shortId: string; isPoAQuote: boolean; showGetInTouch: boolean }) {
  if (!props.showGetInTouch) return null;

  if (props.isPoAQuote) {
    return (
      <ContactMessageContainer>
        <ContactMessage>{props.hasPrice ? <SubmitProceedToApplicationMessage /> : <SubmitPoAMessage />}</ContactMessage>
      </ContactMessageContainer>
    );
  }

  return (
    <ContactMessageContainer>
      <ContactMessage>
        <GetInTouch
          message="This is a Price On Application quote. Please get in touch with your account manager using the Quote ID below, to continue further with this quote."
          quoteShortId={props.shortId}
        />
      </ContactMessage>
    </ContactMessageContainer>
  );
}

const ContactMessage = styled.div`
  max-width: 60%;
  min-width: 50%;
  width: fit-content;
`;

const ContactMessageContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2em;
`;

const AlignContentRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Price: FunctionComponent<React.PropsWithChildren<IPrice>> = (props) => {
  const navigate = useNavigate();
  return <PriceInternal {...props} navigate={navigate} />;
};
export const PriceInternal: FunctionComponent<React.PropsWithChildren<IPrice & Navigation>> = ({
  companyData,
  createNewOrder,
  onSave,
  onSaveAndCreate,
  selectedPrice,
  selectedDummyPrice,
  className,
  creatingOrder,
  creatingOrderError,
  fetchingPrice,
  pricingWillRetry,
  updatingPrice,
  updatingCabling,
  fetchingPriceError,
  fetchingPriceErrorMessage,
  orderId,
  lqId,
  quote,
  quoteEndpointMeta,
  quoteId,
  quoteState,
  updatingQuote,
  updatingQuoteError,
  ipType,
  isPoa,
  onEdit,
  associatedOrderId,
  associatedOrderShortId,
  priceQuote,
  publishQuote,
  allPrices,
  bulkQuoteId,
  setIsMultiQuote,
  asyncPrices,
  complexQuoteProgress,
  navigate,
}) => {
  const companyId = useSelector(selectSelectedCompanyId);
  // TODO: support multiple terms here
  const { contractTerm, productType, origin } = quote;
  const showShadowVLANPrice = useSelector(selectShowShadowVLANPrice);
  const isInternalQuote = useSelector(selectIsInternalQuote);
  const publishing = useSelector(selectPublishing);
  const selectedPriceAnnualCost = useSelector(selectSelectedPriceAnnualCost);
  const isNNATPrice = useSelector(selectIsNNATPrice);
  const savingONATAddress = useSelector(selectSavingONATAddress);
  const isMultiQuote = useSelector(selectIsMultiQuote);
  const currentMeta = useSelector(selectCurrentMeta);
  const enableMultiQuoteModalButton = useSelector(selectMultiQuoteModalDisplayButton);

  const diaConfig: DIAConfig = {
    ipType: ipType,
    isManagedDIA: quote.location.aEnd.is_managed_dia,
    diaIPAllocation: quote.location.aEnd.dia_ip_allocation,
    engineerInstallationRequired: quote.location.aEnd.is_engineer_installation_required,
    routerChoice: quote.location.aEnd.routerChoice,
  };

  const saveSelectedPrice = () => {
    if (quoteId) {
      onSave(
        {
          id: selectedPrice.id,
          amortised: selectedPrice.amortised ?? true,
        },
        {
          id: quoteId,
          selectedPriceId: selectedPrice.id,
          fttpAggregation: quote.fttpAggregation,
          routerChoice: quote.location.aEnd.routerChoice,
          secondRouterOptions: quote.location.aEnd.secondRouterOptions,
          secondIPChoice: quote.location.aEnd.secondIPChoice,
        }
      );
    }
  };

  const saveSelectedPriceAndCreateOrder = () => {
    if (quoteId) {
      onSaveAndCreate(
        {
          price: {
            id: selectedPrice.id,
            amortised: selectedPrice.amortised ?? true,
          },
          quote: {
            id: quoteId,
            selectedPriceId: selectedPrice.id,
            fttpAggregation: quote.fttpAggregation,
            routerChoice: quote.location.aEnd.routerChoice,
            secondRouterOptions: quote.location.aEnd.secondRouterOptions,
            secondIPChoice: quote.location.aEnd.secondIPChoice,
          },
        },
        {
          quoteId: quoteId,
          quote: quote,
          selectedPrice: selectedPrice,
          customerName: getCustomerName(),
          lqId: lqId,
          meta: quoteEndpointMeta,
        }
      );
    }
  };
  const prevCreatingOrder = usePreviousState(creatingOrder);
  const prevUpdating = usePreviousState(updatingQuote);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [shouldCreateOrder, setShouldCreateOrder] = useState(false);
  const [shouldReQuote, setShouldReQuote] = useState(false);

  const [showPoAModal, setShowPoAModal] = useState(false);

  const [showMultiQuoteModal, setShowMultiQuoteModal] = useState(false);
  const [submittingMultiQuote, setSubmittingMultiQuote] = useState(false);
  const [successfulMultiQuoteRequest, setSuccessfulMultiQuoteRequest] = useState({ id: '', label: '' });
  const [postSubmitMultiQuoteError, setSubmitMultiQuoteError] = useState(false);
  const [multiQuoteValidationError, setMultiQuoteValidationError] = useState('');
  const [selectMultiQuoteValue, setSelectMultiQuoteValue] = useState({
    value: '',
    label: '',
  });
  const [checkboxNewMultiQuoteValue, setCheckboxNewMultiQuoteValue] = useState(false);
  const [newMultiQuoteValue, setNewMultiQuoteValue] = useState('');

  const isQuoteLapsed = quoteState === QuoteStatus.LAPSED;
  const isPriceSelected = selectedPrice.id !== '';
  const fttpAggregationSupported =
    supportsFTTPAggregation(selectedPrice.a_end_access_type) &&
    supportsFTTPAggregation(selectedPrice.b_end_access_type) &&
    supportsFTTPAggregationSecondaryCircuits(productType, selectedPrice.secondary_circuits);
  const pagedMultiQuote = useTablePaging({
    baseUrl: `/customers/${companyId}/bulk-quote-requests`,
    extraParams: {
      [`filter${encodeURIComponent('[multiquote]')}`]: 'true',
    },
    defaultSort: {
      key: 'created_at',
      direction: SortDirection.DESCENDING,
    },
  });
  const hasExistingMultiQuotes = isObject(pagedMultiQuote) && pagedMultiQuote?.results.length > 0;

  const isPoAQuote =
    featureFlag.isEnabled(Feature.opticalP2PCerillion) &&
    productType === ProductType.OpticalP2P &&
    !selectedPrice.is_orderable;

  useEffect(() => {
    if (pagedMultiQuote.fetchAttempts >= 1 && !pagedMultiQuote.isLoading) {
      pagedMultiQuote.refresh();
    }
  }, [companyId]);

  const toggleMultiQuoteModal = useCallback(() => {
    setSubmitMultiQuoteError(false);
    setSubmittingMultiQuote(false);
    setCheckboxNewMultiQuoteValue(false);
    setShowMultiQuoteModal(!showMultiQuoteModal);
  }, [showMultiQuoteModal]);

  const toggleCheckboxNewMultiQuote = useCallback(() => {
    setCheckboxNewMultiQuoteValue(!checkboxNewMultiQuoteValue);
  }, [checkboxNewMultiQuoteValue]);

  const multiQuoteSubmit = async () => {
    // @Todo Handle if either if check box selected but no text entered or quote ID not present
    // @Todo handle if checkbox not selected but also no current Multi-Quote is selected

    const createNewMultiQuote = quoteId && checkboxNewMultiQuoteValue && newMultiQuoteValue.length > 0;
    const addToExistingMultiQuote = quoteId && !checkboxNewMultiQuoteValue && selectMultiQuoteValue.value.length > 0;

    if (!createNewMultiQuote && !addToExistingMultiQuote) {
      setMultiQuoteValidationError(
        'Please either check the "Create new" and enter a label for it or uncheck the "Create new" box and select an existing Multi-Quote'
      );
      return;
    }

    setSubmittingMultiQuote(true);
    saveSelectedPrice();

    let multiQuoteId;

    try {
      if (createNewMultiQuote) {
        // New Multi-Quote
        const newMultiQuoteResponse = await postMultiQuote(companyId, newMultiQuoteValue);
        multiQuoteId = newMultiQuoteResponse.data.id;
      } else {
        // Adding this quote to an existing Multi-Quote
        multiQuoteId = selectMultiQuoteValue.value;
      }

      await updateQuote(quoteId!, { bulk_request_id: multiQuoteId }, currentMeta);

      setSuccessfulMultiQuoteRequest({
        id: multiQuoteId,
        label: newMultiQuoteValue,
      });

      setIsMultiQuote(true, multiQuoteId);
      toggleMultiQuoteModal();
    } catch (e) {
      setSubmitMultiQuoteError(true);
    } finally {
      setSubmittingMultiQuote(false);
    }
  };

  const getCustomerName = useCallback(() => {
    const selectedCompany = companyData.companies.filter((company) => company.id === companyData.selectedCompanyId);
    return selectedCompany[0]?.name;
  }, [companyData]);

  const adjustQuoteError = () => {
    if (fetchingPriceError && updatingPrice && Array.isArray(fetchingPriceErrorMessage)) {
      return fetchingPriceErrorMessage;
    }
  };

  const quoteUpdated = useCallback(() => quoteId && prevUpdating && !updatingQuote && !updatingQuoteError, [
    quoteId,
    prevUpdating,
    updatingQuote,
    updatingQuoteError,
  ]);

  useEffect(() => {
    if (quoteUpdated() && shouldCreateOrder) {
      setShouldCreateOrder(false);
      createNewOrder(quoteId!, quote, getCustomerName(), selectedPrice, quoteEndpointMeta, lqId);
    }
  }, [
    shouldCreateOrder,
    prevUpdating,
    updatingQuote,
    updatingQuoteError,
    quoteId,
    lqId,
    quote,
    quoteEndpointMeta,
    createNewOrder,
    getCustomerName,
    quoteUpdated,
    isNNATPrice,
  ]);

  useEffect(() => {
    if (quoteUpdated() && shouldReQuote) {
      priceQuote(quoteId!, requiresAsyncPrices(quote));
      setShouldReQuote(false);
    }
  }, [shouldReQuote, prevUpdating, quoteUpdated, quoteId]);

  useEffect(() => {
    let pageToTrack;
    if (productType) {
      switch (origin) {
        case QuoteOrigin.API:
          pageToTrack = pages.apiQuotePrice;
          break;
        case QuoteOrigin.BULK:
          pageToTrack = pages.bulkQuotePrice;
          break;
        default:
          pageToTrack = pagesWithType.getQuotePrice(productType);
      }
      if (pageToTrack) {
        trackPage(pageToTrack);
      }
    }
  }, [productType, origin]);

  useEffect(() => {
    if (prevCreatingOrder && !creatingOrder && !creatingOrderError && orderId) {
      navigate(orderByIdAfterCreation(orderId));
    }
  }, [prevCreatingOrder, creatingOrder, creatingOrderError, orderId, navigate]);

  useEffect(() => {
    if (saveAndExit && quoteUpdated()) {
      navigate('/quotes');
    }
  }, [prevUpdating, saveAndExit, updatingQuote, prevCreatingOrder, quoteUpdated, updatingQuoteError, navigate]);

  const showCabling =
    !isFTTXQuote(quote) &&
    !isPoa &&
    (selectedPrice.a_end_cross_connect_cost?.is_on_net || selectedPrice.b_end_cross_connect_cost?.is_on_net);

  const withDiv = (component: ReactNode) => {
    return <div className={className}>{component}</div>;
  };

  function handleSubmitQuoteButton() {
    if (isPoAQuote) {
      setShowPoAModal(true);
    } else if (origin !== QuoteOrigin.API) {
      saveSelectedPriceAndCreateOrder();
    } else {
      if (quoteId) {
        createNewOrder(quoteId, quote, getCustomerName(), selectedPrice, quoteEndpointMeta, lqId);
      }
    }
  }

  const LoadingOverlay = ({ isAsync }: { isAsync: boolean }) => {
    if (isAsync) return <AsyncPricesLoadingOverlay progressPercentage={Number(complexQuoteProgress?.progress) || 0} />;

    return withDiv(<PricingSpinner pricingWillRetry={pricingWillRetry} />);
  };

  if (updatingQuote) {
    return withDiv(<SavingSpinner />);
  } else if (creatingOrder) {
    return withDiv(<CreatingOrderSpinner />);
  } else if (fetchingPrice) {
    return <LoadingOverlay isAsync={!!asyncPrices} />;
  } else if (fetchingPriceError && !updatingPrice && !updatingCabling) {
    return withDiv(<ErrorFetchingPricingAlert fetchingPriceErrorMessage={fetchingPriceErrorMessage} />);
  } else if (updatingQuoteError) {
    return <ErrorUpdatingQuoteAlert />;
  } else {
    const isSuccessfulMultiQuoteRequest =
      successfulMultiQuoteRequest.id.length > 0 && successfulMultiQuoteRequest.label.length > 0;
    const showAmortisationBox = canAmortise(
      selectedPrice.term_length_in_months || contractTerm,
      selectedPrice.setup_price ?? 0
    );
    return withDiv(
      <div>
        {productType === ProductType.OpticalP2P && quote.sent_to_cerillion_at !== null && <SubmittedQuoteAlert />}
        {productType !== ProductType.OpticalP2P && allPrices.find((price) => price.product_sub_type === 'optical') && (
          <OpticalNoticeAlert />
        )}
        <PriceBlocks quoteState={quoteState} sentToCerillion={!!quote.sent_to_cerillion_at} />

        {!selectedDummyPrice && isPriceSelected && !isPoa && (
          <>
            <hr className="price_below_suppliers_line" />
            <div className="price_summary">
              <div className="price_summary__section">
                {origin !== QuoteOrigin.API && (
                  <div className="price_summary__amortisation">
                    <Amortisation
                      readonly={quoteState === QuoteStatus.ORDERED}
                      hidden={!showAmortisationBox}
                      hasShadowVLAN={showShadowVLANPrice}
                    />
                    <PresentForRoles roles={userPermissions.costs}>
                      <CostAdjustment
                        annualDiscount={selectedPrice.annual_discount ?? 0}
                        installDiscount={selectedPrice.install_discount ?? 0}
                        selectedPriceId={selectedPrice.id}
                        amortised={selectedPrice.amortised ?? true}
                      />
                      {adjustQuoteError() && <AdjustQuoteErrorAlert errors={adjustQuoteError()} />}
                    </PresentForRoles>
                    {isFttpAggregationPossible(quote, selectedPrice) && (
                      <FTTPAggregation
                        readonly={quoteState === QuoteStatus.ORDERED}
                        fttpAggregationSupported={fttpAggregationSupported}
                        fttpAggregation={quote.fttpAggregation}
                        secondaryCircuitsEnabled={selectedPrice.secondary_circuits?.enabled}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="price_summary__section">
                <CostsBreakdown
                  showShadowVLANPrice={showShadowVLANPrice}
                  selectedPrice={selectedPrice}
                  selectedPriceAnnualCost={selectedPriceAnnualCost}
                  includeFTTPAggregationCharge={!!quote.fttpAggregation}
                  productType={productType}
                  isFTTXQuote={isFTTXQuote(quote)}
                  diaConfig={diaConfig}
                />
              </div>
            </div>
          </>
        )}

        {(isPriceSelected || isPoa) && <div className="price_action_btns">{showCabling && <Cabling />}</div>}

        {isQuoteLapsed && <LapsedQuoteAlert />}

        {quoteState === QuoteStatus.ORDERED && (
          <QuoteAlreadyOrdered associatedOrderId={associatedOrderId} string={associatedOrderShortId} onClick={onEdit} />
        )}

        {isMultiQuote && bulkQuoteId && (
          <AlignContentRight>
            <BulkQuoteAlert id={bulkQuoteId} />
          </AlignContentRight>
        )}

        {isSuccessfulMultiQuoteRequest && (
          <SuccessfulMultiQuoteAlert successfulMultiQuoteRequest={successfulMultiQuoteRequest} onClick={onEdit} />
        )}

        {(isPriceSelected || isPoa) && (
          <div className="price_action_btns">
            <PoAInfoDisplay
              shortId={quote.shortId}
              isPoAQuote={isPoAQuote}
              hasPrice={!selectedDummyPrice && !!selectedPrice}
              showGetInTouch={(quote.isPoa || !selectedPrice.is_orderable) && quoteState !== QuoteStatus.ORDERED}
            />

            <div className="price_action_btns_wrapper">
              {isInternalQuote && (
                <InternalQuotePublishButton
                  publishing={publishing}
                  updatingQuote={updatingQuote}
                  onClick={publishQuote}
                />
              )}

              {!isQuoteLapsed && origin !== QuoteOrigin.API && (
                <SaveAndExitButton
                  sentToCerillionAt={quote.sent_to_cerillion_at}
                  quoteState={quoteState}
                  savingONATAddress={savingONATAddress}
                  onClick={() => {
                    setSaveAndExit(true);
                    saveSelectedPrice();
                  }}
                />
              )}

              <AddToMultiQuoteButton
                disabled={enableMultiQuoteModalButton}
                onClick={() => {
                  toggleMultiQuoteModal();
                }}
              />

              <CreateAQuoteOrProceedToOrder
                isPoAQuote={isPoAQuote}
                onClick={() => {
                  handleSubmitQuoteButton();
                }}
              />

              {!isPoa && isQuoteLapsed && (
                <ReQuoteButton
                  disabled={updatingQuote}
                  onClick={() => {
                    priceQuote(quoteId!, requiresAsyncPrices(quote));
                    setShouldCreateOrder(false);
                  }}
                />
              )}
            </div>

            {publishing.error && <PublishingErrorAlert />}
          </div>
        )}

        {creatingOrderError && <CreateOrderErrorAlert />}

        {showMultiQuoteModal && (
          <Modal
            closeBtnLabel="Cancel"
            confirmBtnLabel="Add"
            showCloseIcon={true}
            disableActions={submittingMultiQuote}
            showCloseBtn={true}
            showConfirmBtn={true}
            onClose={toggleMultiQuoteModal}
            onConfirm={multiQuoteSubmit}
            title="Add quote to Multi-Quote"
          >
            {postSubmitMultiQuoteError && (
              <Alert>
                <p>Your Multi-Quote was not submitted due to a technical error. Please try again later</p>
                <p>If the problem persists, contact your account manager.</p>
              </Alert>
            )}
            {multiQuoteValidationError.length > 0 && (
              <Alert>
                <p>{multiQuoteValidationError}</p>
              </Alert>
            )}
            <p>Add this quote to a new or existing Multi-Quote below for a discount</p>

            <Checkbox value={checkboxNewMultiQuoteValue} label="Create new" onChange={toggleCheckboxNewMultiQuote} />

            {checkboxNewMultiQuoteValue && (
              <>
                <TextInput
                  inputIdentifierPrefix={'newMultiQuoteInput'}
                  onChange={(e) => {
                    setNewMultiQuoteValue(e.target.value);
                  }}
                  type="text"
                  value={newMultiQuoteValue}
                  labelText="Multi-Quote reference name"
                  maxLength={255}
                />
              </>
            )}

            <Select
              inputIdentifierPrefix="existingMultiQuoteInput"
              value={selectMultiQuoteValue.value.length > 0 ? selectMultiQuoteValue.value : 'none'}
              labelText="Available Multi-Quotes"
              readOnly={!hasExistingMultiQuotes || checkboxNewMultiQuoteValue}
              onChange={(e) => {
                const index = e.target.selectedIndex;

                setSelectMultiQuoteValue({
                  value: e.target.value,
                  label: e.target[index].innerText,
                });
              }}
            >
              <option value={0}>{hasExistingMultiQuotes ? 'Select a Multi-Quote' : 'No available Multi-Quotes'}</option>
              {hasExistingMultiQuotes &&
                pagedMultiQuote.results.map((result) => {
                  const disabled = result.attributes.total_entries >= 10;
                  const quotesSuffixText = result.attributes.total_entries > 1 ? 'quotes' : 'quote';

                  return (
                    <option key={result.id} value={result.id} disabled={disabled}>
                      {result.attributes.multiquote_label} ({result.attributes.total_entries} {quotesSuffixText})
                    </option>
                  );
                })}
            </Select>
            {/* @Todo Handle if pagedMultiQuote request failed, errored or is just empty */}

            {submittingMultiQuote && <SpinnerWithText text="Submitting Multi-Quote" size="medium" />}
          </Modal>
        )}

        {showPoAModal && <PoAModal saveSelectedPrice={saveSelectedPrice} closeModal={() => setShowPoAModal(false)} />}
      </div>
    );
  }
};

const styledPrice = styled(Price)`
  text-align: left;

  .price__block {
    width: 48%;
  }

  .price_below_suppliers_line {
    margin: 1em 0;
  }

  .price_action_btns_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .price_action_btns {
    margin-top: 1.25em;
  }

  .price_action_btns_wrapper + .alert {
    margin-top: 20px;
  }

  .price__proceed_to_order_btn {
    margin-bottom: 1em;
    position: relative;

    button {
      min-width: 200px;
      width: fit-content;
    }
  }

  .price_summary {
    display: flex;
    justify-content: space-between;

    .price_summary__section {
      width: 48%;
    }
  }
`;

const mapStateToProps = ({
  user: { companyData },
  orderBuilder: { id: orderId, creating: creatingOrder },
  quoteBuilder: {
    associatedOrderId,
    associatedOrderShortId,
    currentQuoteId,
    updating,
    quote: {
      isPoa,
      location: { aEnd },
      bulkQuoteId,
    },
    quote,
    lqId,
    pricing: { selectedPrice, selectedDummyPrice, pricingProgress, allPrices },
    state,
    quoteEndpointMeta,
  },
}: IAppState) => ({
  companyData,
  associatedOrderId,
  associatedOrderShortId,
  orderId,
  isPoa,
  lqId,
  quote,
  quoteEndpointMeta,
  selectedPrice: selectedPrice,
  selectedDummyPrice: selectedDummyPrice,
  creatingOrder: creatingOrder.inProgress,
  creatingOrderError: creatingOrder.error,
  fetchingPrice: pricingProgress.fetchingPrice,
  updatingPrice: pricingProgress.updatingPrice,
  updatingCabling: pricingProgress.updatingCabling,
  fetchingPriceError: pricingProgress.error,
  fetchingPriceErrorMessage: pricingProgress.errorMessage,
  pricingWillRetry: pricingProgress.pricingQuoteWillRetry,
  asyncPrices: pricingProgress.requiresAsyncPrices,
  complexQuoteProgress: pricingProgress.complexQuoteProgress,
  quoteId: currentQuoteId,
  quoteState: state,
  updatingQuote: updating.inProgress,
  updatingQuoteError: updating.error,
  ipType: aEnd.ip.selectedId,
  allPrices,
  bulkQuoteId,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  createNewOrder: (
    quoteId: string,
    quote: IQuote,
    customerName: string,
    selectedPrice: IPriceData,
    meta?: IMeta,
    lqId?: string
  ) => dispatch(createOrderAction(quoteId, quote, customerName, selectedPrice, meta, lqId)),
  onSave: (price: UpdatePricedQuotePayloadPrice, quote: UpdatePricedQuotePayloadQuote) =>
    dispatch(updatePricedQuote(price, quote)),
  onSaveAndCreate: (
    updatePrice: { price: UpdatePricedQuotePayloadPrice; quote: UpdatePricedQuotePayloadQuote },
    createOrder: {
      quoteId: string;
      quote: IQuote;
      customerName: string;
      selectedPrice: IPriceData;
      meta?: IMeta;
      lqId?: string;
    }
  ) =>
    dispatch(
      updatePricedQuoteAndCreateOrder(
        updatePricedQuote(updatePrice.price, updatePrice.quote),
        createOrderAction(
          createOrder.quoteId,
          createOrder.quote,
          createOrder.customerName,
          createOrder.selectedPrice,
          createOrder.meta,
          createOrder.lqId
        )
      )
    ),
  priceQuote: (quoteId: string, asyncPrices: boolean) => dispatch(priceQuoteAction(quoteId, asyncPrices)),
  publishQuote: () => dispatch(publishQuoteAction()),
  setIsMultiQuote: (isMultiQuote: boolean, bulkRequestId: string) =>
    dispatch(setIsMultiQuoteAction(isMultiQuote, bulkRequestId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(styledPrice);
