import { ProductType } from './productTypes';
import IQuoteRecordAttributesBase, { IMeta } from './quoteRecordAttributesBase';
import { IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ISubmitError } from './bulkQuoteSubmitResponse';
import { Supplier } from './supplier';
import { IOnNetSite } from 'shared/types/onNetSite';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { APIDynamicPricing, CrossConnectCost, PortCost } from './pricedQuote';
import { IOpticalDataCentre } from 'Location/OpticalDataCentres/types';
import { ICloudProvider } from 'Location/CloudConnect/CloudProviders/types';
import { IRequestState } from 'shared/types/requestState';
import { IRetrieving } from 'shared/types/retrieving';
import { ConnectionType, OverallConnectionType } from './connectionType';
import { IAccessAvailability } from './accessAvailability';
import { SupplierNNIResource } from 'Location/NNI/types';
import { FlatONATAddressResponseItem } from 'shared/types/onatAddress';
import { AvailabilityCheckState } from './availabilityCheck';
import { CerillionQuoteStage, IComplexQuotePricingProgress, IQuoteListItem } from './quoteRecord';
import {
  DualCircuitRouterConfigurations,
  IPAddressAllocation,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { MessageResource } from 'shared/types/Messages';
import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { DynamicPrices } from 'Quotes/QuoteBuilder/utils/price';

export enum AddressType {
  ON_NET = 'ON_NET',
  PAF = 'PAF',
  OPENREACH = 'OPENREACH',
  ONAT = 'ONAT',
  MANUAL = 'MANUAL',
}

export enum ProviderName {
  AWS = 'AWS',
  Azure = 'Azure',
  NOT_DEFINED = 'NOT_DEFINED',
}

export const FilterableProviderNames = Object.values(ProviderName).filter((v) => v !== ProviderName.NOT_DEFINED);

export interface IPAddress {
  selectedId?: IPType | null;
}

export enum CablingType {
  Copper = 'copper',
  Fibre = 'fibre',
}

export type AddressesFound = {
  onNet: IOnNetSite[];
  openreach: IOpenReachAddressSite[];
  paf: IPAFAddressSite[];
  hasNNATAddresses?: boolean;
};

export type AddressesRetrieving = {
  onNetAndPaf: boolean;
  openreach: boolean;
};

export interface ILocation {
  optical: IOptical;
  dataCentreReference?: string;
  addressesFound: AddressesFound;
  addressesRetrieving: AddressesRetrieving;
  draftPostcode: string;
  postcode: string;
  fullAddress: IPAFAddressSite | IOnNetSite | null;
  openreachAddress: IOpenReachAddressSite | null;
  onatAddress: FlatONATAddressResponseItem | null;
  addressType: AddressType | null;
  onNetType: 'sse' | 'colt' | null;
  supplierNNI?: Partial<SupplierNNIResource['attributes']>;
}

export interface INNI {
  capacity: {
    bandwidth: number | null;
    ports: string[];
  };
  selectedId?: string;
  reference?: string;
  popName?: string;
  popPostcode?: string;
  popAddress?: string;
  portType?: string;
  selectedDataCentreId?: string;
  shadowVLAN: {
    enabled: boolean;
    selectedId?: string;
    selectedDataCentreId?: string;
    name?: string;
    location?: string;
  };
}

export interface IAEndLocation extends ILocation, MDIAFields {
  cloudConnect: ICloudConnect;
  ip: IPAddress;
  nni: INNI;
  dia_ip_allocation: IPAddressAllocation | null;
  secondIPChoice: IPType | undefined;
}

export interface MDIAFields {
  is_managed_dia: boolean;
  is_engineer_installation_required: boolean;
  is_rack_mount_required: boolean;
  routerChoice?: DualCircuitRouterConfigurations | undefined;
  secondRouterOptions?: {
    engineerInstallationRequired?: boolean;
    rackMountKitRequired?: boolean;
  };
}

export interface IOptical {
  selectedId?: string;
  dataCentreNotListed: boolean;
  list?: IOpticalDataCentre[];
}

export interface ICloudConnect {
  name: ProviderName;
  supportedBandwidths: number[];
  usage: string;
  providersList: ICloudProvider[];
  diversified: boolean | null;
}

export interface ILocationGroup {
  aEnd: IAEndLocation;
  bEnd: ILocation;
}

export const ProductSubTypes = ['ethernet', 'optical', 'unprotected'] as const;
export type ProductSubType = typeof ProductSubTypes[number];

export interface IPriceData {
  is_poa: boolean | null;
  a_end_access_type: string | null;
  a_end_annual_cost: number | null;
  a_end_cabling_charge: boolean | null;
  a_end_cabling_type: CablingType | null;
  a_end_cross_connect_cost: CrossConnectCost | null;
  a_end_exchange_type: string | null;
  a_end_gea_cablelink_annual_cost: number | null;
  a_end_gea_cablelink_install_cost: number | null;
  a_end_p_o_p: string | null;
  a_end_p_o_p_id: string | null;
  a_end_p_o_p_address: string | null;
  a_end_p_o_p_postcode: string | null;
  a_end_product_name: string | null;
  a_end_setup_cost: number | null;
  amortised?: boolean | null;
  amortised_annual_price: number | null;
  annual_discount: number | null;
  annual_ip_charge: number | null;
  annual_price: number | null;
  annual_price_with_fttp_aggregation: number | null;
  annual_setup_price: number | null;
  b_end_access_type: string | null;
  b_end_annual_cost: number | null;
  b_end_cabling_charge: boolean | null;
  b_end_cabling_type: CablingType | null;
  b_end_cross_connect_cost: CrossConnectCost | null;
  b_end_exchange_type: string | null;
  b_end_gea_cablelink_annual_cost: number | null;
  b_end_gea_cablelink_install_cost: number | null;
  b_end_p_o_p: string | null;
  b_end_p_o_p_postcode: string | null;
  b_end_product_name: string | null;
  b_end_setup_cost: number | null;
  bandwidth?: string;
  bandwidth_cost?: {
    annual: number | null;
    install: number | null;
  };
  opticalCosts?: {
    aEnd: {
      install: number;
      annual: number;
    };
    bEnd: {
      install: number;
      annual: number;
    };
  };
  cloud_connect?: {
    provider: ProviderName;
    annual: number | null;
  };
  fttp_aggregation_charge?: number | null;
  id: string;
  install_discount: number | null;
  is_orderable?: boolean;
  margin_amount: number | null;
  margin_percentage: number | null;
  margin_percentage_with_fttp_aggregation: number | null;
  mdia_annual_cost: number | null;
  mdia_annual_price: number | null;
  mdia_engineer_cost: number | null;
  mdia_engineer_price: number | null;
  mdia_install_cost: number | null;
  mdia_install_price: number | null;
  mdia_rack_mount_kit_cost: number | null;
  mdia_rack_mount_kit_price: number | null;
  mdia_router_cost: number | null;
  mdia_router_price: number | null;
  net_amortised_annual_price: number | null;
  net_amortised_annual_price_with_fttp_aggregation: number | null;
  net_amortised_install_price: number | null;
  net_annual_price: number | null;
  net_annual_price_with_fttp_aggregation: number | null;
  net_install_price: number | null;
  port_a_cost: PortCost | null;
  port_b_cost: PortCost | null;
  port_costs_annual: number | null;
  port_costs_setup: number | null;
  product_sub_type: ProductSubType | null;
  setup_price: number | null;
  shadow_vlan_price: number | null;
  supplier_annual_cost: number | null;
  supplier_setup_cost: number | null;
  total_contract_value: number | null;
  total_contract_value_with_fttp_aggregation: number | null;
  total_cost: number | null;
  total_price: number | null;
  term_length_in_months?: ContractTerm | null;
  subnetPrices: APIDynamicPricing | undefined;
  secondary_circuits?: SecondaryCircuits | undefined;
}

export type ISecondaryPrice = Omit<IPriceData, 'secondary_circuits'>;

export interface OpticalCosts {
  aEnd: {
    install: number;
    annual: number;
  };
  bEnd: {
    install: number;
    annual: number;
  };
}

export interface SecondaryCircuits {
  enabled: boolean;
  status: ExchangeRequestStatus;
  selectedCircuitId: string | undefined;
  circuits: Circuit[];
  aEndExchanges: Exchange[];
  bEndExchanges: Exchange[];
  selectedAEndId: string | undefined;
  selectedBEndId: string | undefined;
  dynamicPrices?: DynamicPrices;
}

export enum ExchangeRequestStatus {
  NOT_REQUESTED = 'not_requested',
  EXCHANGES_LOADED = 'distances_retrieved',
}

export enum ExchangePriceRequestStatus {
  NOT_REQUESTED = 'unpriced',
  PRICES_LOADED = 'prices_retrieved',
}

interface PoPData {
  popId: string;
  name: string;
}
interface DistanceData {
  distance: number;
}
interface SecondaryPrices {
  prices?: IPriceData[];
  selectedPriceId: string | undefined;
}
export interface Exchange extends PoPData, DistanceData {}

export interface Circuit extends SecondaryPrices {
  id: string;
  nniId: string;
  exchangeId: string | undefined;
}

export interface IPriceDataWithBandwidth extends IPriceData {
  bandwidth: string;
}

export interface IPricingProgress {
  requiresAsyncPrices?: boolean;
  complexQuoteProgress?: IComplexQuotePricingProgress;
  error?: boolean;
  fetchingPrice?: boolean;
  updatingPrice?: boolean;
  updatingCabling?: boolean;
  updatingSuccess?: boolean;
  loadingUpdate?: boolean;
  pricingQuoteWillRetry?: boolean;
  errorMessage?: string[];
  cablingSuccess?: boolean;
}

export interface IPricing {
  selectedPrice: IPriceData;
  selectedDummyPrice?: IDummyPrice;
  pricingProgress: IPricingProgress;
  allPrices: IPriceData[];
  filteredPrices: IPriceData[];
  aEndFilterList: Supplier[];
  bEndFilterList: Supplier[];
  lqPricingError?: string | null;
  subProductTypeFilterList: ProductSubType[];
  bandwidthFilterList: IQuote['bandwidth'][];
  contractTermFilterList: ContractTerm[];
}

export enum QuoteOrigin {
  APP = 'APP',
  BULK = 'BULK',
  API = 'API',
}

export interface IQuote {
  diverseOptionFor: string | null;
  diverseOption: string | null;
  requiresAvailabilityCheck: boolean;
  availability: IAccessAvailability | undefined;
  origin: QuoteOrigin;
  connectionType: OverallConnectionType;
  productType: ProductType | null | undefined;
  location: ILocationGroup;
  bearer: BearerType | undefined;
  bandwidth: string;
  /** @deprecated multiple terms are now supported by `chosen_term_lengths_in_months` */
  contractTerm: ContractTerm;
  bulkQuoteId?: string;
  shortBulkQuoteId?: string;
  isPoa: boolean;
  customerId: string;
  customerName: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  shortId: string;
  aEndAccessMethod: ConnectionType | null;
  bEndAccessMethod: ConnectionType | null;
  aEndBandwidth: string | null;
  bEndBandwidth: string | null;
  aEndPort: BearerType | undefined;
  bEndPort: BearerType | undefined;
  aEndExcludeSSEOnNet: boolean;
  bEndExcludeSSEOnNet: boolean;
  aEndL2SID: string | null;
  bEndL2SID: string | null;
  aEndMDFID: string | null;
  bEndMDFID: string | null;
  is_internal: boolean;
  a_end_onat_address_id: string | null;
  b_end_onat_address_id: string | null;
  cerillion_stage?: CerillionQuoteStage | null;
  cerillion_opportunity_stage_history: CerillionQuoteStageHistory | null;
  cerillion_quote_status_message: string | null;
  sent_to_cerillion_at: string | null;
  chosen_bandwidths: string[];
  chosen_term_lengths_in_months: ContractTerm[];
  is_multiquote: boolean;
  fttpAggregation: boolean | null;
  messages: MessageResource[];
  order_id?: string | null;
}

export enum UploadState {
  EMPTY = 'EMPTY',
  IN_PROGRESS = 'IN_PROGRESS',
  ERRORED = 'ERRORED',
  SUCCESSFUL = 'SUCCESSFUL',
}

export type IBulkQuoteUploadState =
  | UploadState.EMPTY
  | UploadState.IN_PROGRESS
  | [UploadState.ERRORED, ISubmitError[]]
  | UploadState.SUCCESSFUL;

export interface SavingONATAddress extends IRequestState {
  end?: 'A' | 'B';
  errorA?: boolean;
  errorB?: boolean;
}

export interface IQuotesState {
  associatedOrderId?: string;
  associatedOrderShortId?: string;
  quoteEndpointMeta: IMeta;
  quote: IQuote;
  bulkQuoteId?: string;
  pricing: IPricing;
  retrieving: IRetrieving;
  retrieveAPIQuoteState: IRetrieving;
  updating: IRequestState;
  checkingAvailability: IRetrieving;
  creating: IRequestState;
  downloading: IRequestState;
  cloning: IRequestState;
  currentQuoteId?: string;
  lqId?: string;
  state: IQuoteRecordAttributesBase['state'];
  bulkQuoteUploadState: IBulkQuoteUploadState;
  editMode?: boolean;
  publishing: IRequestState;
  savingONATAddress: SavingONATAddress;
  availabilityCheck: AvailabilityCheckState;
  bulkOrderCreateState: IBulkOrderCreateState;
}

export interface IBulkOrderCreateState {
  successfullyCreatedOrders: IQuoteListItemWithOrderId[];
  successfullyCreatedPOAQuotes: IQuoteListItem[];
  failedQuotes: IQuoteListItem[];
  creating: IRequestState;
}

export interface IQuoteListItemWithOrderId extends IQuoteListItem {
  orderId: string;
  orderShortId: string;
}

export type CerillionQuoteStageHistory = {
  [key in CerillionQuoteStage]: {
    raw_step: string;
    stage: string;
    status: string;
    start_date: string | null;
    end_date: string | null;
  };
};
