// _____   __                      _____   __    _____                       ______
// ___  | / /__________________    ___  | / /______  /___      _________________  /_________
// __   |/ /_  _ \  __ \_  ___/    __   |/ /_  _ \  __/_ | /| / /  __ \_  ___/_  //_/_  ___/
// _  /|  / /  __/ /_/ /(__  )     _  /|  / /  __/ /_ __ |/ |/ // /_/ /  /   _  ,<  _(__  )
// /_/ |_/  \___/\____//____/      /_/ |_/  \___/\__/ ____/|__/ \____//_/    /_/|_| /____/

import { DefaultTheme } from 'styled-components';
import React from 'react';
import { VimeoEmbed } from 'FAQ';
import Column from 'shared/components/atoms/Column';
import Row from 'shared/components/atoms/Column/row';
import {
  CLOUD_SERVER_TERMS,
  ETHERNET_SERVICE_TERMS,
  GENERAL_TERMS,
  OPTICAL_INTERNET_TERMS,
} from 'Order/OrderBuilder/Terms/components/TermsText/GeneralTerms';

const theme: DefaultTheme = {
  navbarHeight: '70px',

  button: {
    width: '170px',
  },

  header: {
    style: 'image',
    bgImage: '/images/neos-networks-wave.png',
  },

  breakpoints: {
    S: '576px',
    M: '768px',
    L: '992px',
    XL: '1200px',
    XXL: '1400px',
  },

  defaultTransition: '0.3s ease',

  alerts: {
    danger: {
      backgroundColor: '#b04653',
      borderColor: '#b04653',
      color: '#fff',
      headingColor: '#fff',
    },
    success: {
      backgroundColor: '#8ad0c9',
      borderColor: '#3ab1a5',
      color: 'inherit',
      headingColor: 'inherit',
    },
    warning: {
      backgroundColor: '#FFF9E8',
      borderColor: '#EFEBDF',
      color: 'inherit',
      headingColor: '#623614',
    },
    info: {
      backgroundColor: '#d4dde0',
      borderColor: '#d4dde0',
      color: '#265766',
      headingColor: '#265766',
    },
  },

  /**
   * Tints:
   * - primaryA1: 0% tint
   * - primaryA2: 20% tint
   * - primaryA3: 40% tint
   * - primaryA4: 60% tint
   * - primaryA5: 80% tint
   */
  colours: {
    // Primary (Navy) / rgb(38,54,106)
    primaryA1: '#26366a',
    primaryA2: '#525e88',
    primaryA3: '#7d86a6',
    primaryA4: '#a7b0c2',
    primaryA5: '#d4d7e1',

    // Primary (Teal) / rgb(58,177,165)
    primaryB1: '#3ab1a5',
    primaryB2: '#62c1b7',
    primaryB3: '#8ad0c9',
    primaryB4: '#b0e0db',
    primaryB5: '#d8efed',

    // Primary (Copper) / rgb(207,121,56)
    primaryC1: '#cf7938',
    primaryC2: '#d89460',
    primaryC3: '#e2af88',
    primaryC4: '#ebc9b0',
    primaryC5: '#f4e4d7',

    // Secondary (Red) / rgb(176,70,83)
    secondaryA1: '#b04653',
    secondaryA2: '#c16b75',
    secondaryA3: '#d09098',
    secondaryA4: '#dfb5ba',
    secondaryA5: '#efdadd',

    // Secondary (Purple) / rgb(104,73,131)
    secondaryB1: '#684983',
    secondaryB2: '#866d9c',
    secondaryB3: '#a493b5',
    secondaryB4: '#c3b7cc',
    secondaryB5: '#e1dae6',

    // Secondary (Turquoise) / rgb(49,165,180)
    secondaryC1: '#31a5b4',
    secondaryC2: '#5ab7c3',
    secondaryC3: '#83c9d3',
    secondaryC4: '#aedbe1',
    secondaryC5: '#f5f9fc',

    // Secondary (Petrol) / rgb(38,87,102)
    secondaryD1: '#265766',
    secondaryD2: '#517a85',
    secondaryD3: '#7d99a3',
    secondaryD4: '#a8bcc2',
    secondaryD5: '#d4dde0',

    // Secondary (Dark Blue) / rgb(19,45,72)
    secondaryE1: '#132d48',
    secondaryE2: '#43576d',
    secondaryE3: '#718191',
    secondaryE4: '#a1abb6',
    secondaryE5: '#d0d5da',

    // Secondary (Dark Green) / rgb(99,127,108)
    secondaryF1: '#637f6c',
    secondaryF2: '#819989',
    secondaryF3: '#a0b3a7',
    secondaryF4: '#c1ccc4',
    secondaryF5: '#e0e6e1',

    // Other
    grey10: '#ebeaeb',
    grey20: '#dadada',
    grey30: '#c6c6c6',
    grey40: '#b2b2b2',
    grey50: '#9d9d9c',
    grey60: '#878787',
    grey70: '#706f6f',
    grey80: '#575756',
    grey90: '#3c3c3b',
    mainBackgroundColor: `#f5f8fc`,
    secondary: '#004687',
    listItemHover: '#f1f5f7',
    navbarSelect: '#f1f5f7',
    navbarColour: '#666',
    inputBorder: '#cccccc',
    inputText: '#808080',
  },

  shadows: {
    boxShadow: `6px 7px 2px 0 rgba(19,45,72, 0.1)`,
  },

  typography: {
    fontFamilyBold: '"bariol_bold", "Calibri", "Helvetica", sans-serif',
    fontFamilyRegular: '"bariol_regular", "Calibri", "Helvetica", sans-serif',
    fontSizeH1: '67.3px',
    fontSizeH2: '50.5px',
    fontSizeH3: '37.9px',
    fontSizeH4: '1.2em',
    fontSizeMd: '14px',
    fontSizeSm: '12px',
  },
  favicons: {
    favicon16: `/favicon-16x16.png`,
    favicon32: `/favicon-32x32.png`,
    appleTouchIcon: `/apple-touch-icon.png`,
    shortcutIcon: `/favicon.ico`,
  },
  logos: {
    main: '/images/neos-networks-logo.png',
    splash: '/images/neos-networks-logo-splash.png',
  },
  backgroundImages: {
    splashBackground: '/images/splash-background.jpg',
    homepageNewsBackground: '',
  },
  htmlEditorTheme: 'snow',
  content: {
    companyName: 'Neos Networks',
    productName: 'LiveQuote',
    title: 'Neos Networks - LIVEQUOTE App',
    defaultMapsPosition: { lat: 51.454167, lng: -0.973056 },
    homeIntro: (
      <p>
        Over 550 customers use our connectivity pricing portal, where you can easily quote and order a range of
        connectivity services including Ethernet, Optical, Dedicated Internet Access, EoFTTx and Cloud Connect.
      </p>
    ),
    homeNews: (
      <>
        <h2 className="h3">Check out our LIVEQUOTE demo video</h2>
        <Row className="justify-content-between align-content-start">
          <Column defaultWidth={7}>
            <p>
              Need some support navigating our LIVEQUOTE portal? Our new demo video walks you through how to use the
              tool, our new features, benefits and more. Take a look for yourself.
            </p>
          </Column>
          <Column defaultWidth={5} className="mr-auto ml-auto pb-3">
            <VimeoEmbed
              title="Check out our LIVEQUOTE demo video"
              url="https://player.vimeo.com/video/845233340?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              style={{ height: '100%' }}
            />
          </Column>
        </Row>
      </>
    ),
    homeMapButtonTitle: 'Interactive Network Map',
    contactInfo: {
      introText: 'In the first instance, please contact your Account Director. Otherwise:',
      support: {
        phone: '+44 (0) 345 305 3332',
        email: 'digital@neosnetworks.com',
      },
      pricing: {
        phone: '+44 (0) 118 953 4758',
        email: 'pricing@neosnetworks.com',
      },
      orders: {
        phone: '+44 (0) 345 070 4301',
        email: 'orders@neosnetworks.com',
      },
      enquiries: {
        email: 'enquiries@neosnetworks.com',
      },
      provisioning: {
        email: 'provisioning@neosnetworks.com',
      },
    },
    usefulLinks: {
      userGuide: {
        title: 'LIVEQUOTE User Guide',
        url: 'https://digital-portal-static.testing.digital-foundations.co.uk/Neos+Networks+LIVEQUOTE+User+Guide+-+07062023.pdf',
      },
      generalTermsAndConditions: {
        title: 'General terms',
        url: GENERAL_TERMS,
      },
      ethernetTermsAndConditions: {
        title: 'Ethernet terms',
        url: ETHERNET_SERVICE_TERMS,
      },
      opticalTermsAndConditions: {
        title: 'Optical Wavelength terms',
        url: OPTICAL_INTERNET_TERMS,
      },
      cloudTermsAndConditions: {
        title: 'Business Cloud and Internet terms',
        url: CLOUD_SERVER_TERMS,
      },
    },
    footer: {
      content: (
        <p>
          Neos Networks is a trading name of Neos Networks Limited, registered in Scotland No. SC213457, which is 50%
          owned by the SSE Group and 50% owned by Infracapital (the infrastructure equity investment arm of M&amp;G
          plc). The registered office of Neos Networks Limited is Inveralmond House, 200 Dunkeld Road, Perth, PH1 3AQ.
          <br />
          The website can be found at{' '}
          <a href="https://neosnetworks.com" rel="noopener noreferrer" target="_blank">
            www.neosnetworks.com
          </a>
          .
        </p>
      ),
      legalLinks: {
        company: {
          name: <>&copy; Neos Networks Ltd</>,
          url: 'https://neosnetworks.com',
        },
        complaintsProcedure: 'https://neosnetworks.com/complaints-procedure/',
        codesOfPractice: 'https://neosnetworks.com/codes-of-practice/',
        privacyPolicy: 'https://neosnetworks.com/privacy-policy/',
        acceptableUsePolicy: 'https://neosnetworks.com/acceptable-use-policy/',
        modernSlaveryStatement:
          'https://neosnetworks.com/wp-content/uploads/2022/09/NN_Modern-Slavery-statement-FT22_FINAL.pdf',
      },
      socialLinks: {
        linkedin: 'https://www.linkedin.com/company/neos-networks',
        twitter: 'https://twitter.com/NeosNetworks',
      },
    },
    promotionsSlot: {
      badgeText: 'LIVEQUOTE Updates',
    },
  },
};

export default theme;
