import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import Column from 'shared/components/atoms/Column';
import LinkButton from 'shared/components/atoms/LinkButton';
import { newQuote, newQuoteByLocation, quoteList } from 'Quotes/Routes';
import { ButtonMainStyle } from 'shared/components/atoms/Button';
import { ordersList } from 'Order/routes';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import Icon from 'shared/components/atoms/Icon';
import { PromoBanner } from './PromoBanner';
import PresentForRoles from 'Auth/PresentForRoles';
import { userPermissions } from 'shared/utils/permissions';
import { Loading } from 'shared/components/molecules/Loading/Loading';
import { usePromotions } from 'PromoOffer/promoApi';

function PromotionDisplay() {
  const { isLoading, currentPromotion } = usePromotions();

  return (
    <Loading isLoading={isLoading}>
      {!currentPromotion && undefined}
      {currentPromotion && <PromoBanner {...currentPromotion.attributes} />}
    </Loading>
  );
}

export const Welcome: FC<{ className?: string }> = ({ className }) => {
  useTrackPage(pages.welcome);
  const theme = useTheme();

  return (
    <HeaderWithChildrenWrapper
      className="welcome-page"
      displayBackground={true}
      header={`Welcome to ${theme.content.productName.toUpperCase()}`}
    >
      <div className={className}>
        <div className="row">
          <Column>{theme.content.homeIntro}</Column>
        </div>
        <div className="get-quote-by-container p-3 mt-4">
          <div className="row">
            <Column>
              <h2>Get your quote by</h2>
            </Column>
          </div>
          <div className="row pt-4">
            <Column lgWidth={6} classNames={['mb-md-4', 'mb-lg-0']}>
              <div className="actions-box by-location">
                <h3 className="pb-3">Location</h3>
                <p className="pb-3">
                  Select the points you want to connect, and we will do the rest to find you the best products and
                  speeds available.
                </p>
                <LinkButton
                  className="large-btn"
                  mainStyle={ButtonMainStyle.PrimaryRectangular}
                  pathname={newQuoteByLocation}
                  buttonText="Start a quote"
                />
              </div>
            </Column>
            <Column lgWidth={6}>
              <div className="actions-box by-location">
                <h3 className="pb-3">Product</h3>
                <p className="pb-3">
                  Select your product type to explore the connectivity between two locations or services at a specific
                  location.
                </p>
                <LinkButton
                  className="large-btn"
                  mainStyle={ButtonMainStyle.PrimaryRectangular}
                  pathname={newQuote}
                  buttonText="Start a quote"
                />
              </div>
            </Column>
          </div>
        </div>
        <div className="row pt-4 pt-lg-5">
          <Column lgWidth={8} classNames={['spaced-list']}>
            <PresentForRoles roles={userPermissions.viewEditPromoOffers}>
              <PromotionDisplay />
            </PresentForRoles>

            <div className="row">
              <Column>
                <div className="actions-box">
                  <h3 className="pb-2">Orders and recent quotes</h3>
                  <div className="body">
                    <p>
                      Quotes may be progressed to an order once you are satisfied with the pricing and configuration.
                    </p>
                    <p>Specify the location, access and billing details to proceed to order.</p>
                  </div>
                  <div className="row quote-btns pt-3">
                    <Column>
                      <LinkButton
                        mainStyle={ButtonMainStyle.PrimaryOutline}
                        pathname={ordersList}
                        buttonText="Recent orders"
                      />
                      <LinkButton
                        mainStyle={ButtonMainStyle.PrimaryOutline}
                        pathname={quoteList}
                        buttonText="Recent quotes"
                      />
                    </Column>
                  </div>
                </div>
              </Column>
            </div>
            <div className="row">
              <Column>
                <div className="news-wrapper">{theme.content.homeNews}</div>
              </Column>
            </div>
            <div className="row">
              <Column defaultWidth={6}>
                <div className="actions-box links">
                  <h4 className="h3">Pricing updates</h4>
                  <p>
                    16/2/24 – We have updated our Ethernet, DIA and Optical pricing to reflect changing market
                    conditions – please{' '}
                    <a
                      href="https://264183.fs1.hubspotusercontent-na1.net/hubfs/264183/LQ%20price%20increase%20communication_160224.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      click here
                    </a>{' '}
                    to read more
                  </p>
                  <h4 className="h3">Useful links</h4>
                  <ul className="list-unstyled useful-links pt-1">
                    {Object.entries(theme.content.usefulLinks).map(([key, link]) => (
                      <li key={key}>
                        <Icon name="chevron_right" />
                        <a rel="noopener noreferrer" target="_blank" href={link.url}>
                          {link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Column>
              <Column defaultWidth={6}>
                <div className="actions-box contact">
                  <h4 className="h3">Contact information</h4>
                  <p>{theme.content.contactInfo.introText}</p>

                  <ul className="list-unstyled">
                    <li>
                      <ul className="list-unstyled pb-3">
                        <li>
                          <strong>LIVEQUOTE Enquiries</strong>
                        </li>
                        {theme.content.contactInfo.support.phone && (
                          <li>T: {theme.content.contactInfo.support.phone}</li>
                        )}

                        {theme.content.contactInfo.support.email && (
                          <li>
                            E:{' '}
                            <a href={`mailto:${theme.content.contactInfo.support.email}`}>
                              {theme.content.contactInfo.support.email}
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li>
                      <ul className="list-unstyled pb-3">
                        <li>
                          <strong>Pricing Team</strong>
                        </li>
                        {theme.content.contactInfo.pricing.phone && (
                          <li>T: {theme.content.contactInfo.pricing.phone}</li>
                        )}

                        {theme.content.contactInfo.pricing.email && (
                          <li>
                            E:{' '}
                            <a href={`mailto:${theme.content.contactInfo.pricing.email}`}>
                              {theme.content.contactInfo.pricing.email}
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li>
                      <ul className="list-unstyled">
                        <li>
                          <strong>Orders Team</strong>
                        </li>
                        {theme.content.contactInfo.orders.phone && <li>T: {theme.content.contactInfo.orders.phone}</li>}

                        {theme.content.contactInfo.orders.email && (
                          <li>
                            E:{' '}
                            <a href={`mailto:${theme.content.contactInfo.orders.email}`}>
                              {theme.content.contactInfo.orders.email}
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
              </Column>
            </div>
          </Column>
          <Column mdWidth={6} lgWidth={4} classNames={['text-right', 'pt-4', 'pt-lg-0']}>
            <div className="map-container">
              <img
                src="/images/network-map.png"
                alt="Map of Network"
                title="Interactive network map"
                className="img-fluid"
              />
              <div className="map-btn-container py-3 text-center">
                <a
                  className="btn map-btn"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://neosnetworks.com/interactive-network-map"
                >
                  {theme.content.homeMapButtonTitle}
                </a>
              </div>
            </div>
          </Column>
        </div>
      </div>
    </HeaderWithChildrenWrapper>
  );
};

const StyledWelcome = styled(Welcome)`
  .actions-box {
    background-color: ${(props) => props.theme.colours.secondaryC5};
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
    border-radius: 0.25em;
    padding: 2em;

    &.by-location {
      background-color: white;
      box-shadow: none;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.M}) {
      &.contact,
      &.links {
        min-height: 595px;
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.L}) {
      &.quote,
      &.order {
        .body {
          min-height: 200px;
        }

        .quote-btns,
        .order-btn-wrapper {
          min-height: 110px;

          button:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
      &.quote,
      &.order {
        .body {
          min-height: 190px;
        }

        .quote-btns,
        .order-btn-wrapper {
          min-height: inherit;

          button:first-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.XXL}) {
      &.quote,
      &.order {
        .body {
          min-height: 150px;
        }
      }

      &.contact,
      &.links {
        min-height: 410px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
    .quote-btns {
      a + a {
        margin-left: 5px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
    .quote-btns {
      a + a {
        margin-left: 25px;
      }
    }
  }

  .news-wrapper {
    background-size: cover;
    background-color: ${(props) => props.theme.colours.secondaryC5};
    background-image: url(${(props) => props.theme.backgroundImages.homepageNewsBackground});
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
    border-radius: 0.25em;
    border: 1px solid ${(props) => props.theme.colours.secondaryC5};
    padding: 1em 0 0 2em;
    background-position-x: 85%;
    background-repeat: no-repeat;

    > p {
      padding-right: 30%;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.L}) {
    .news-wrapper {
      background-position-x: 80%;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
    .news-wrapper {
      background-position-x: 95%;

      > p {
        padding-right: 30%;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XXL}) {
    .news-wrapper {
      background-position-x: right;

      > p {
        padding-right: 35%;
      }
    }
  }

  .useful-links {
    li {
      position: relative;
      padding-left: 25px;
      padding-bottom: 5px;
    }

    i {
      position: absolute;
      left: 0;
      top: 3px;
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  .map-container {
    border: 1px solid ${(props) => props.theme.colours.grey10};
  }

  .map-btn-container {
    background-color: ${(props) => props.theme.colours.secondaryC5};
  }

  .map-btn {
    background-color: white;
    border-color: ${(props) => props.theme.colours.primaryC1};
    color: ${(props) => props.theme.colours.primaryC1};
    border-radius: 3px;
    line-height: 1.8;
    padding-left: 1em;
    padding-right: 1em;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    font-size: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
    .map-btn {
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  .large-btn {
    width: 300px;
    height: 50px;
  }

  .get-quote-by-container {
    background-color: ${(props) => props.theme.colours.secondaryC5};
  }

  .spaced-list {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
  }
`;

// export the connected class
export default StyledWelcome;
