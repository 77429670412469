import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IOpenReachAddress } from 'shared/types/postcodeResults';
import {
  concatenateOnNetAddress,
  concatenateOpenReachAddress,
  isManualAddress,
} from 'shared/utils/addresses/helperFunctions';
import CardRow from 'shared/components/molecules/CardRow';
import Column from 'shared/components/atoms/Column';
import SummaryTextHelper, {
  BooleanFieldSummary,
  EmailSummary,
  NameFieldSummary,
  NotesFieldSummary,
  PhoneFieldSummary,
  TextWithLabelSummary,
  VLANIdFieldSummary,
} from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { AsbestosRegister, IOrderLocation, ISiteConfig } from 'Order/types/location';
import { MISSING_ADDRESS_PROMPT, MISSING_END_COMPANY_NAME_PROMPT } from 'Order/OrderBuilder/Locations/shared/messages';
import { AddressType, ILocation, IPriceData, IQuote } from 'Quotes/types/store';
import { IOrderMetaForLocation } from 'Order/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { mediaTypeForDisplay } from 'Order/OrderBuilder/shared/utils/mediaTypeForDisplay';
import AddressTypeBadge, { AddressType as AddressTypeForBadge } from 'shared/components/atoms/AddressTypeBadge';
import { isSelectedPriceSSEOnNet } from 'Order/OrderBuilder/shared/utils/isSelectedPriceSSEOnNet';
import { isValidCoords, isValidVLANIdField } from 'Order/OrderBuilder/shared/utils/validation';
import { accessNoticeForDisplay } from 'Order/OrderBuilder/shared/utils/accessNoticeForDisplay';
import { selectIsOrderONAT } from 'Order/selectors';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

interface ISummaryProps {
  className?: string;
  identifierPrefix: string;
  isFTTXQuote: boolean;
  isNNAT: ReturnType<typeof selectIsOrderONAT>;
  onatAddress: ILocation['onatAddress'];
  location: IOrderLocation;
  orderMeta: IOrderMetaForLocation;
  productType: ProductType;
  showSiteTypeConfigForPoint: boolean;
  selectedPrice: IPriceData;
  quote: IQuote;
}

function isFullManualAddress(address: IOpenReachAddress): boolean {
  return (
    (address.building_name !== '' || address.building_number !== '') &&
    ['street', 'post_town', 'county', 'postcode'].every((requiredKey) => address[requiredKey] !== '')
  );
}

function SiteConfigSummary(props: {
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  fttxQuote: boolean;
  nnat: boolean;
  showVLANTagging: boolean;
}) {
  return (
    <div className="row no-gutters mb-4">
      <Column defaultWidth={6}>
        <ul>
          <li>
            <TextWithLabelSummary
              id={`${props.identifierPrefix}_floor`}
              label="Floor"
              text={props.siteConfig.floor}
              prompt="Add floor"
            />
          </li>
          <li>
            <TextWithLabelSummary
              id={`${props.identifierPrefix}_room`}
              label="Room"
              text={props.siteConfig.room || ''}
              prompt="Add room"
            />
          </li>
          <li>
            <TextWithLabelSummary id={`${props.identifierPrefix}_suite`} label="Suite" text={props.siteConfig.suite} />
          </li>
          <li>
            <TextWithLabelSummary
              id={`${props.identifierPrefix}_rack`}
              label="Rack"
              text={props.siteConfig.rackId || ''}
              prompt="Add rack"
            />
          </li>
          {!props.fttxQuote && (
            <li>
              <TextWithLabelSummary
                id={`${props.identifierPrefix}_connectorType`}
                label="Connector Type"
                text={props.siteConfig.connectorType || ''}
                prompt="Add connector type"
              />
            </li>
          )}
        </ul>
      </Column>
      <Column defaultWidth={6}>
        <ul>
          {!props.fttxQuote && (
            <li>
              <TextWithLabelSummary
                id={`${props.identifierPrefix}_mediaType`}
                label="Media Type"
                text={mediaTypeForDisplay(props.siteConfig.mediaType)}
                prompt="Add media type"
              />
            </li>
          )}
          {!props.fttxQuote && (
            <li>
              <TextWithLabelSummary
                id={`${props.identifierPrefix}_powerType`}
                label="Power"
                text={props.siteConfig.powerType || ''}
                prompt="Add power type"
              />
            </li>
          )}
          {!props.nnat && (
            <li>
              <BooleanFieldSummary
                id={`${props.identifierPrefix}_autoNegotiation`}
                label="Auto Negotiation"
                bool={props.siteConfig.autoNegotiation}
              />
            </li>
          )}
          {props.showVLANTagging && (
            <li>
              {props.siteConfig.vlanTagging && (
                <div className="vlan-tagging">
                  <div className="label">VLAN tagging</div>
                  <VLANIdFieldSummary text={props.siteConfig.vlanId || ''} prompt={isValidVLANIdField.errorMessage} />
                </div>
              )}
              {!props.siteConfig.vlanTagging && (
                <BooleanFieldSummary
                  id={`${props.identifierPrefix}_vlanTagging`}
                  label="VLAN tagging"
                  bool={props.siteConfig.vlanTagging}
                />
              )}
            </li>
          )}
        </ul>
      </Column>
    </div>
  );
}

const Summary: FunctionComponent<React.PropsWithChildren<ISummaryProps>> = ({
  className,
  identifierPrefix,
  isFTTXQuote,
  isNNAT,
  location,
  onatAddress,
  productType,
  orderMeta,
  showSiteTypeConfigForPoint,
  selectedPrice,
  quote,
}) => {
  const {
    siteConfig,
    siteContact,
    siteReadiness,
    endCompanyName,
    locationData: { fullAddress, addressType },
  } = location;

  const showVLANTagging = productType !== ProductType.P2P && productType !== ProductType.DIA;

  return (
    <>
      <CardRow className={className} title="Address &amp; contact">
        <div className="row no-gutters mb-4">
          <Column defaultWidth={6}>
            <ul>
              <li id={`${identifierPrefix}_endCompanyName`} className="companyName">
                {endCompanyName}
              </li>
              {!endCompanyName && (
                <li>
                  <SummaryTextHelper infoPromptText={MISSING_END_COMPANY_NAME_PROMPT} />
                </li>
              )}
              {addressType === AddressType.ON_NET ? (
                <li className="onNet-address">
                  <address className="mb-0">{concatenateOnNetAddress(orderMeta, fullAddress)}</address>
                  <div className="mt-2 mt-2">
                    <AddressTypeBadge
                      addressType={
                        isSelectedPriceSSEOnNet(selectedPrice, identifierPrefix)
                          ? AddressTypeForBadge.ON_NET_SSE
                          : AddressTypeForBadge.ON_NET_3RD_PARTY
                      }
                    />
                  </div>
                </li>
              ) : !isNNAT && productType !== ProductType.OpticalP2P ? (
                <li className="openReach-address">
                  <address className="mb-2">{concatenateOpenReachAddress(fullAddress)}</address>
                  {!fullAddress.alk && !fullAddress.qualifier && (
                    <AddressTypeBadge addressType={AddressTypeForBadge.MANUAL} />
                  )}
                  {(fullAddress.alk || fullAddress.qualifier) && (
                    <AddressTypeBadge
                      addressType={AddressTypeForBadge.OPENREACH}
                      qualifier={fullAddress.qualifier}
                      alk={fullAddress.alk}
                    />
                  )}
                </li>
              ) : isNNAT ? (
                <li className="onat-address">
                  <address className="mb-2">{onatAddress?.full_address || ''}</address>
                  <AddressTypeBadge addressType={AddressTypeForBadge.ONAT} />
                </li>
              ) : null}
              {productType === ProductType.OpticalP2P && (
                <li>
                  <address className="mb-2">
                    {quote.location[identifierPrefix === 'aEndLocation' ? 'aEnd' : 'bEnd'].dataCentreReference}
                  </address>
                </li>
              )}
              {productType !== ProductType.OpticalP2P &&
                !isNNAT &&
                isManualAddress(fullAddress, addressType) &&
                !isFullManualAddress(fullAddress) && (
                  <li>
                    <SummaryTextHelper infoPromptText={MISSING_ADDRESS_PROMPT} />
                  </li>
                )}
              {productType !== ProductType.OpticalP2P &&
                !isNNAT &&
                isManualAddress(fullAddress, addressType) &&
                !isValidCoords({
                  lon: location.siteCoords.longitude,
                  lat: location.siteCoords.latitude,
                  easting: location.siteCoords.easting,
                  northing: location.siteCoords.northing,
                }) && (
                  <li>
                    <SummaryTextHelper infoPromptText="Add site co-ordinates" />
                  </li>
                )}
              {(location.siteCoords.latitude || location.siteCoords.longitude) && (
                <>
                  <li>
                    <TextWithLabelSummary
                      label="Latitude"
                      text={`${location.siteCoords.latitude || ''}`}
                      prompt="Add latitude"
                    />
                  </li>
                  <li>
                    <TextWithLabelSummary
                      label="Longitude"
                      text={`${location.siteCoords.longitude || ''}`}
                      prompt="Add longitude"
                    />
                  </li>
                </>
              )}
              {(location.siteCoords.easting || location.siteCoords.northing) && (
                <>
                  <li>
                    <TextWithLabelSummary
                      label="Eastings (X)"
                      text={`${location.siteCoords.easting || ''}`}
                      prompt="Add eastings"
                    />
                  </li>
                  <li>
                    <TextWithLabelSummary
                      label="Northings (Y)"
                      text={`${location.siteCoords.northing || ''}`}
                      prompt="Add northings"
                    />
                  </li>
                </>
              )}
              <li>
                <NameFieldSummary firstName={siteContact.firstName} surname={siteContact.surname} />
              </li>
              <li>
                <PhoneFieldSummary phone={siteContact.phone} />
              </li>
              <li>
                <EmailSummary email={siteContact.email} />{' '}
              </li>
              {showSiteTypeConfigForPoint && (
                <li className="text-capitalize">
                  <TextWithLabelSummary label="Site type" text={location.siteType || ''} />
                </li>
              )}
            </ul>
          </Column>
          <Column defaultWidth={6}>
            <ul>
              <li>
                <NotesFieldSummary text={siteContact.notes} label="Site Notes" />
              </li>
            </ul>
          </Column>
        </div>
      </CardRow>

      {featureFlag.isEnabled(Feature.SecondaryCircuits) && location.secondarySiteConfig ? (
        <>
          <CardRow
            className={className}
            title="Site Config"
            subtitle="Primary Circuit"
            id={`${identifierPrefix}_siteConfig`}
          >
            <SiteConfigSummary
              identifierPrefix={identifierPrefix}
              siteConfig={siteConfig}
              fttxQuote={isFTTXQuote}
              nnat={isNNAT}
              showVLANTagging={showVLANTagging}
            />
          </CardRow>
          <CardRow
            className={className}
            title="Site Config"
            subtitle="Secondary Circuit"
            id={`${identifierPrefix}_siteConfig_secondary`}
          >
            <SiteConfigSummary
              identifierPrefix={identifierPrefix}
              siteConfig={location.secondarySiteConfig}
              fttxQuote={isFTTXQuote}
              nnat={isNNAT}
              showVLANTagging={showVLANTagging}
            />
          </CardRow>
        </>
      ) : (
        <CardRow className={className} title="Site Config" id={`${identifierPrefix}_siteConfig`}>
          <SiteConfigSummary
            identifierPrefix={identifierPrefix}
            siteConfig={siteConfig}
            fttxQuote={isFTTXQuote}
            nnat={isNNAT}
            showVLANTagging={showVLANTagging}
          />
        </CardRow>
      )}

      <CardRow className={className} title="Site Readiness" id={`${identifierPrefix}_siteReadiness`}>
        <div className="row no-gutters">
          <Column defaultWidth={6}>
            <ul className="site-readiness-summary">
              <li>
                <TextWithLabelSummary
                  id={`${identifierPrefix}_access_notice`}
                  label="What notice is required to access site?"
                  text={accessNoticeForDisplay(siteReadiness.accessNotice)}
                />
              </li>
              <li>
                <BooleanFieldSummary
                  id={`${identifierPrefix}_landOwnerPermissionRequired`}
                  label="Is land-owner permissions or wayleave required?"
                  bool={siteReadiness.landOwnerPermissionRequired}
                />
              </li>
              <li>
                <BooleanFieldSummary
                  id={`${identifierPrefix}_moreThanOneTenant`}
                  label="Is there more than one tenant in the building?"
                  bool={siteReadiness.moreThanOneTenant}
                />
              </li>
              <li>
                <BooleanFieldSummary
                  id={`${identifierPrefix}_hazardsOnSite`}
                  label="Are there any hazards on site which an engineer should be aware of?"
                  bool={siteReadiness.hazardsOnSite}
                />
              </li>
              <li>
                <BooleanFieldSummary
                  id={`${identifierPrefix}_asbestosRegister`}
                  label="If the building was built prior to 2000, is there an asbestos register available onsite?"
                  bool={
                    siteReadiness.asbestosRegister !== AsbestosRegister.NotBuiltPriorTo2000
                      ? siteReadiness.asbestosRegister === AsbestosRegister.Yes
                      : undefined
                  }
                />
              </li>
            </ul>
          </Column>
          <Column defaultWidth={6}>
            {siteReadiness.hazardsOnSite && (
              <ul>
                <li>
                  <p className="group-title">Hazards</p>
                  <NotesFieldSummary
                    text={siteReadiness.hazardsOnSiteDescription || ''}
                    required={true}
                    requiredPrompt="Add info about hazards on site"
                    errorPrompt="Please limit hazard description to 500 characters"
                  />
                </li>
              </ul>
            )}
          </Column>
        </div>
      </CardRow>
    </>
  );
};

const StyledSummary = styled(Summary)`
  ul {
    list-style: none;
  }

  .site-readiness-summary li .label,
  li address {
    word-break: normal;
  }

  li {
    word-break: break-all;
    margin-top: 0.5rem;
  }

  li:first-child {
    margin-top: 0rem;
  }

  .group-title {
    color: ${(props) => props.theme.colours.grey50};
    margin-bottom: 0.5rem;
  }

  .vlan-tagging .label {
    color: ${(props) => props.theme.colours.grey50};
  }
`;

export default StyledSummary;
