import { useEffect, useState } from 'react';
import { IPagingResponse, doRequest } from '../../Request';
import { IServerSidePaging } from '../types/ServerSidePaging';
import { objectToGetParams } from '../utils/objectToGetParams';
export const ERROR_MESSAGE = 'Error fetching results';
const DEFAULT_PAGE_SIZE = 10;
export enum SortDirection {
  ASCENDING = '',
  DESCENDING = '-',
}
export interface ISort {
  key: string | null;
  direction: SortDirection;
}

export interface IPageFilter {
  id: string;
  value: string;
}

export const urlSafeFilters = (filters: IPageFilter[]) => {
  const params: Record<string, string> = {};

  filters.forEach((filter) => {
    params[`filter${encodeURIComponent(`[${filter.id}]`)}`] = encodeURIComponent(filter.value);
  });

  return params;
};

export const pagedUrl = (
  baseUrl: string,
  pageSize: number,
  filter: IPageFilter[],
  sort?: ISort | null,
  extraParams?: Record<string, string>
) => {
  const params: Record<string, string> = {
    [encodeURIComponent('page[size]')]: `${pageSize}`,
    ...(extraParams || {}),
    ...urlSafeFilters(filter),
  };

  if (sort?.key) {
    params.sort = `${sort.direction}${sort.key}`;
  }

  const baseUrlHasSearch = new URL(`${process.env.REACT_APP_SSE_API_URL}${baseUrl}`).search;

  return `${baseUrl}${baseUrlHasSearch ? '&' : '?'}${objectToGetParams(params)}`;
};

export const withParams = (url: string, extraParams?: Record<string, string>) => {
  if (!extraParams) {
    return url;
  }
  return `${url}&${objectToGetParams(extraParams)}`;
};

export const withSortAndFilters = (url: string, filter: IPageFilter[], sort?: ISort | null) => {
  const params: Record<string, string> = {
    ...urlSafeFilters(filter),
  };

  if (sort?.key) {
    params.sort = `${sort.direction}${sort.key}`;
  }

  if (Object.keys(params).length > 0) {
    return `${url}&${objectToGetParams(params)}`;
  }

  return url;
};

export interface IUseTablePaging {
  doRequest?: (url: string) => Promise<IPagingResponse>;
  baseUrl: string;
  defaultPageSize?: number;
  dataTransform?: (res: any) => any;
  defaultSort?: ISort;
  filterTransform?: (filters: IPageFilter[]) => IPageFilter[];
  extraParams?: Record<string, string>;
}

export const useTablePaging = ({
  baseUrl,
  defaultPageSize,
  defaultSort,
  dataTransform,
  filterTransform,
  extraParams,
}: IUseTablePaging): IServerSidePaging => {
  const [urlStack, setUrlStack] = useState<string[]>([]);
  const [sort, setSort] = useState<ISort | null>(defaultSort || null);
  const [filter, setFilter] = useState<IPageFilter[]>([]);
  const [results, setResults] = useState<IPagingResponse | null>(null);
  const [fetchAttempts, setFetchAttempts] = useState(0);

  const [pageSize, setPageSize] = useState<number>(defaultPageSize || DEFAULT_PAGE_SIZE);

  const [nextPageUrl, setNextPageUrl] = useState<string>(pagedUrl(baseUrl, pageSize, filter, sort, extraParams));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const goNextPage = () => {
    const withExtraParams = withParams(nextPageUrl, extraParams);
    const withSortAndFiltering = withSortAndFilters(withExtraParams, filter, sort);

    setUrlStack(urlStack.concat([nextPageUrl]));
    pagedRequest(withSortAndFiltering, false, false);
  };

  const toggleSort = async (key: string) => {
    let newSort: ISort | null;
    const newKey = key || sort?.key || null;
    if (sort?.direction === SortDirection.ASCENDING) {
      newSort = {
        key: newKey,
        direction: SortDirection.DESCENDING,
      };
    } else {
      newSort = {
        key,
        direction: SortDirection.ASCENDING,
      };
    }
    setSort(newSort);
  };

  const goPreviousPage = async () => {
    await pagedRequest(urlStack[urlStack.length - 2], true, false);
  };

  const pagedRequest = async (url: string, isPrevious: boolean, isReplace: boolean) => {
    try {
      setIsLoading(true);
      const requestResults: IPagingResponse = await doRequest({ path: url });

      if (!requestResults.links) {
        requestResults.links = {
          next: pagedUrl(baseUrl, pageSize, filter, sort, {
            ...extraParams,
            'page[number]': `${urlStack.length + 1}`,
          }),
        };
      }
      if (dataTransform) {
        requestResults.data = dataTransform(requestResults);
      }
      setResults(requestResults);
      setNextPageUrl(requestResults.links.next || '');
    } catch (e) {
      setError(ERROR_MESSAGE);
    } finally {
      if (isPrevious) {
        setUrlStack(urlStack.slice(0, -1));
      } else if (!isReplace) {
        setUrlStack(urlStack.concat([url]));
      } else {
        setUrlStack([url]);
      }
      setFetchAttempts(fetchAttempts + 1);
      setIsLoading(false);
    }
  };

  const setTableFilter = (filters: IPageFilter[]): void => {
    if (filterTransform) {
      setFilter(filterTransform(filters));
    } else {
      setFilter(filters);
    }
  };

  useEffect(() => {
    pagedRequest(pagedUrl(baseUrl, pageSize, filter, sort, extraParams), false, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, baseUrl, filter, sort, extraParams?.customer_id]);

  const firstResult = results?.data.length ? (urlStack.length - 1) * pageSize + 1 : 0;
  return {
    canNextPage: !!results?.links.next,
    canPreviousPage: urlStack.length > 1,
    currentPage: urlStack.length,
    error,
    filter,
    firstResult,
    fetchAttempts,
    isLoading,
    lastResult:
      results?.meta && results
        ? Math.min(urlStack.length * pageSize, results!.meta!.total_count)
        : urlStack.length * pageSize,
    nextPage: goNextPage,
    pageSize,
    previousPage: goPreviousPage,
    refresh: () => pagedRequest(pagedUrl(baseUrl, pageSize, filter, sort, extraParams), false, true),
    results: results?.data || [],
    setFilter: setTableFilter,
    setPageSize,
    setSort: toggleSort,
    sort,
    totalPages: results?.meta && results ? Math.ceil(results!.meta!.total_count / pageSize) : 0,
    totalResults: results?.meta && results?.meta.total_count,
  };
};
