import { ISiteConfig } from 'Order/types/location';
import React from 'react';
import { concatenateShadowVlan } from 'shared/utils/addresses/helperFunctions';
import CardRow from 'shared/components/molecules/CardRow';
import Column from 'shared/components/atoms/Column';
import VLANConfig from 'Order/OrderBuilder/Locations/shared/components/Edit/VLANConfiguration';
import ShadowVLANConfig from 'Order/OrderBuilder/Locations/shared/components/Edit/ShadowVLANConfiguration';
import { NEW_NNI_DESCRIPTION, NEW_NNI_TITLE } from 'Order/OrderBuilder/Locations/shared/messages';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { IQuote } from 'Quotes/types/store';

interface NNISiteConfigSection {
  circuitType?: string;
  customerName: string | null;
  dataCentre?: string | null;
  defaultNNIRequestContactEmail: string;
  hasShadowNNI?: boolean;
  identifierPrefix: string;
  isNewNNI?: boolean;
  isNewShadowNNI?: boolean;
  nniLabel?: string | null;
  popId: string | null;
  quote: IQuote;
  quoteMeta: IMeta;
  showShadowNNI?: boolean;
  siteConfig: ISiteConfig;
  siteConfigProps: any;
  updateSiteConfig: (config: any) => void;
}

export const NNISiteConfig: React.FC<NNISiteConfigSection> = ({
  circuitType,
  customerName,
  dataCentre,
  defaultNNIRequestContactEmail,
  hasShadowNNI,
  identifierPrefix,
  isNewNNI,
  isNewShadowNNI,
  nniLabel,
  popId,
  quote,
  quoteMeta,
  showShadowNNI,
  siteConfig,
  siteConfigProps,
  updateSiteConfig,
}) => {
  const shadowReference = concatenateShadowVlan(quoteMeta, quote.location.aEnd.nni) || '';
  const nniType = circuitType === 'Secondary Circuit' ? 'edit_secondary_nni' : 'edit_nni';

  return (
    <CardRow id={`${identifierPrefix}_${nniType}`} title="Site Config" subtitle={circuitType} {...siteConfigProps}>
      <div className="row no-gutters mb-4">
        <Column classNames={['pl-4']} smWidth={12} mdWidth={6} lgWidth={6}>
          <VLANConfig
            customerName={customerName}
            dataCentre={dataCentre}
            defaultNNIRequestContactEmail={defaultNNIRequestContactEmail}
            identifierPrefix={identifierPrefix}
            isNewNNI={isNewNNI}
            nniLabel={nniLabel}
            popId={popId}
            required
            showLabel
            siteConfig={siteConfig}
            updateSiteConfig={updateSiteConfig}
          />
        </Column>
        <Column classNames={['pl-4']} smWidth={12} mdWidth={6} lgWidth={6}>
          {hasShadowNNI && showShadowNNI && (
            <ShadowVLANConfig
              customerName={quote.customerName}
              dataCentre={quoteMeta.a_end_nni_shadow_data_centre_reference}
              defaultNNIRequestContactEmail={quote.createdBy}
              identifierPrefix={identifierPrefix}
              isNewNNI={isNewShadowNNI}
              popId={quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId}
              reference={shadowReference}
              required
              showLabel
              siteConfig={siteConfig}
              updateSiteConfig={updateSiteConfig}
            />
          )}
        </Column>
        <Column smWidth={12} mdWidth={12} lgWidth={9}>
          {(isNewNNI || isNewShadowNNI) && (
            <div className="mt-5">
              <div className="text-danger font-weight-bold">{NEW_NNI_TITLE}</div>
              <p>{NEW_NNI_DESCRIPTION}</p>
            </div>
          )}
        </Column>
      </div>
    </CardRow>
  );
};
