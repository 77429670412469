import { TextWithLabelSummary } from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { ISiteConfig } from 'Order/types/location';
import React from 'react';

export const NNISummaryFields = ({ siteConfig }: { siteConfig: ISiteConfig }) => {
  return (
    <>
      <TextWithLabelSummary
        id="summary_nni_job_reference"
        label="NNI Job Reference"
        text={siteConfig.nni_job_reference || ''}
      />

      <TextWithLabelSummary id="summary_nni_idn_number" label="IDN" text={siteConfig.nni_idn_number || ''} />
    </>
  );
};
