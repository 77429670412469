import IQuoteRecord, {
  IComplexQuotePricingProgress,
  IQuoteData,
  IQuoteListItem,
  IQuotePricedOrOrdered,
} from './quoteRecord';
import IQuoteRecordAttributesBase, { LocationLookup } from './quoteRecordAttributesBase';
import {
  AddressesFound,
  ILocation,
  IPriceData,
  IQuoteListItemWithOrderId,
  IQuotesState,
  MDIAFields,
  ProductSubType,
} from './store';
import { NNISelectionType } from 'Location/NNI/types';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { IBulkQuoteSubmitErrorData } from './bulkQuoteSubmitResponse';
import { Supplier, SupplierEndType } from './supplier';
import { IOnNetSite } from 'shared/types/onNetSite';
import { ErrorResponse } from 'Request';
import { IOpticalDataCentre } from 'Location/OpticalDataCentres/types';
import IDownloadQuotesType from './downloadQuotes';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { ErrorResponse as JsonApiErrorResponse } from 'Request/json-api/types';
import { ALKLocation, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { ICloudProvider } from 'Location/CloudConnect/CloudProviders/types';
import IPricedQuote from './pricedQuote';
import { GenericAction } from 'shared/actions';
import { OverallConnectionType } from './connectionType';
import { IAccessAvailability } from './accessAvailability';
import { FlatONATAddressResponseItem } from 'shared/types/onatAddress';
import { AvailabilityCheckRequest, AvailabilityCheckResponse, Source } from './availabilityCheck';
import {
  DualCircuitRouterConfigurations,
  IPAddressAllocation,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { MessageResource } from 'shared/types/Messages';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { ICreateOrder } from 'Order/types/actions';

export enum QuoteActionTypes {
  BULK_QUOTE_SUBMIT_ENDED = 'BULK_QUOTE_SUBMIT_ENDED',
  BULK_QUOTE_SUBMIT_ERROR = 'BULK_QUOTE_SUBMIT_ERROR',
  BULK_QUOTE_SUBMIT_STARTED = 'BULK_QUOTE_SUBMIT_STARTED',
  CLONE_QUOTE = 'CLONE_QUOTE',
  CLONE_QUOTE_ENDED = 'CLONE_QUOTE_ENDED',
  CLONE_QUOTE_ERROR = 'CLONE_QUOTE_ERROR',
  CLONE_QUOTE_STARTED = 'CLONE_QUOTE_STARTED',
  CREATE_QUOTE = 'CREATE_QUOTE',
  CREATE_QUOTE_ENDED = 'CREATE_QUOTE_ENDED',
  CREATE_QUOTE_ERROR = 'CREATE_QUOTE_ERROR',
  CREATE_QUOTE_STARTED = 'CREATE_QUOTE_STARTED',
  DOWNLOAD_QUOTES = 'DOWNLOAD_QUOTES',
  DOWNLOAD_QUOTES_ENDED = 'DOWNLOAD_QUOTES_ENDED',
  DOWNLOAD_QUOTES_ERROR = 'DOWNLOAD_QUOTES_ERROR',
  DOWNLOAD_QUOTES_STARTED = 'DOWNLOAD_QUOTES_STARTED',
  GET_API_QUOTE = 'GET_API_QUOTE',
  GET_API_QUOTE_ENDED = 'GET_API_QUOTE_ENDED',
  GET_API_QUOTE_ERROR = 'GET_API_QUOTE_ERROR',
  GET_API_QUOTE_STARTED = 'GET_API_QUOTE_STARTED',
  GET_AVAILABLE_ACCESS_METHODS = 'GET_AVAILABLE_ACCESS_METHODS',
  GET_AVAILABLE_ACCESS_METHODS_ENDED = 'GET_AVAILABLE_ACCESS_METHODS_ENDED',
  GET_AVAILABLE_ACCESS_METHODS_ERROR = 'GET_AVAILABLE_ACCESS_METHODS_ERROR',
  GET_AVAILABLE_ACCESS_METHODS_STARTED = 'GET_AVAILABLE_ACCESS_METHODS_STARTED',
  GET_EXISTING_QUOTE = 'GET_EXISTING_QUOTE',
  GET_EXISTING_QUOTE_ENDED = 'GET_EXISTING_QUOTE_ENDED',
  GET_EXISTING_QUOTE_ERROR = 'GET_EXISTING_QUOTE_ERROR',
  GET_EXISTING_QUOTE_STARTED = 'GET_EXISTING_QUOTE_STARTED',
  PRICE_QUOTE = 'PRICE_QUOTE',
  PRICE_QUOTE_ENDED = 'PRICE_QUOTE_ENDED',
  PRICE_QUOTE_ERROR = 'PRICE_QUOTE_ERROR',
  PRICE_QUOTE_STARTED = 'PRICE_QUOTE_STARTED',
  PRICE_QUOTE_SUCCESS = 'PRICE_QUOTE_SUCCESS',
  PRICE_COMPLEX_QUOTE_UPDATE_PROGRESS = 'PRICE_COMPLEX_QUOTE_UPDATE_PROGRESS',
  PRICE_COMPLEX_QUOTE_COMPLETED = 'PRICE_COMPLEX_QUOTE_COMPLETED',
  PRICING_QUOTE_WILL_RETRY = 'PRICING_QUOTE_WILL_RETRY',
  REFRESH_FILTERED_PRICES = 'REFRESH_FILTERED_PRICES',
  REPLACE_QUOTE_STATE = 'REPLACE_QUOTE_STATE',
  SET_CERILLION_SENT_AT = 'SET_CERILLION_SENT_AT',
  RESET_BULK_QUOTE_SUBMIT_STATE = 'RESET_BULK_QUOTE_SUBMIT_STATE ',
  RESET_QUOTE_STATE = 'RESET_QUOTE_STATE',
  SET_ADDRESS_NOT_LISTED_A_END = 'SET_ADDRESS_NOT_LISTED_A_END',
  SET_ADDRESS_NOT_LISTED_B_END = 'SET_ADDRESS_NOT_LISTED_B_END',
  SET_ADDRESSES_FOUND_A_END = 'SET_ADDRESSES_FOUND_A_END',
  SET_ADDRESSES_FOUND_B_END = 'SET_ADDRESSES_FOUND_B_END',
  SET_AMORTISATION = 'SET_AMORTISATION',
  SET_FTTP_AGGREGATION = 'SET_FTTP_AGGREGATION',
  SET_ROUTER_CHOICE = 'SET_ROUTER_CHOICE',
  CLEAR_ROUTER_CHOICE = 'CLEAR_ROUTER_CHOICE',
  SET_SECONDARY_CIRCUIT_OPTIONS = 'SET_SECONDARY_CIRCUIT_OPTIONS',
  SET_BANDWIDTH = 'SET_BANDWIDTH',
  TOGGLE_CHOSEN_BANDWIDTH = 'TOGGLE_CHOSEN_BANDWIDTH',
  TOGGLE_CHOSEN_CONTRACT_TERM = 'TOGGLE_CHOSEN_CONTRACT_TERM',
  SET_BEARER = 'SET_BEARER',
  SET_CABLING_SUCCESS_STATE = 'SET_CABLING_SUCCESS_STATE',
  SET_CLOUD_PROVIDER = 'SET_CLOUD_PROVIDER',
  SET_CLOUD_PROVIDERS_LIST = 'SET_CLOUD_PROVIDERS_LIST',
  /** @deprecated multiple terms are now supported by `TOGGLE_CHOSEN_CONTRACT_TERM` */
  SET_CONTRACT_TERM_LENGTH = 'SET_CONTRACT_TERM_LENGTH',
  SET_CURRENT_QUOTE_DATA = 'SET_CURRENT_QUOTE_DATA',
  SET_DATA_CENTRE_A_END = 'SET_DATA_CENTRE_A_END',
  SET_DATA_CENTRE_B_END = 'SET_DATA_CENTRE_B_END',
  SET_DATA_CENTRE_B_END_NOT_LISTED = 'SET_DATA_CENTRE_B_END_NOT_LISTED',
  SET_DIVERSITY = 'SET_DIVERSITY',
  SET_DRAFT_POSTCODE_A_END = 'SET_DRAFT_POSTCODE_A_END',
  SET_DRAFT_POSTCODE_B_END = 'SET_DRAFT_POSTCODE_B_END',
  SET_FILTERED_PRICE_DATA = 'SET_FILTERED_PRICE_DATA',
  SET_FULL_ADDRESS_A_END = 'SET_FULL_ADDRESS_A_END',
  SET_FULL_ADDRESS_B_END = 'SET_FULL_ADDRESS_B_END',
  SET_OPENREACH_ADDRESS = 'SET_OPENREACH_ADDRESS',
  SET_IP_OPTION = 'SET_IP_OPTION',
  SET_SECOND_IP_OPTION = 'SET_SECOND_IP_OPTION',
  SET_DIA_IP_ALLOCATION = 'SET_DIA_IP_ALLOCATION',
  SET_NNI_ID_AND_REFERENCE = 'SET_NNI_AND_REFERENCE',
  SET_OPTICAL_DATA_CENTRE_LIST = 'SET_OPTICAL_DATA_CENTRE_LIST',
  SET_POSTCODE_A_END = 'SET_POSTCODE_A_END',
  SET_POSTCODE_B_END = 'SET_POSTCODE_B_END',
  SET_PRICE_DATA = 'SET_PRICE_DATA',
  SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE',
  SET_QUOTE_POA = 'SET_QUOTE_POA',
  SET_QUOTE_CONNECTION_TYPE = 'SET_QUOTE_CONNECTION_TYPE',
  SET_SELECTED_PRICE = 'SET_SELECTED_PRICE',
  SET_SECONDARY_CIRCUITS_ENABLED = 'SET_SECONDARY_CIRCUITS_ENABLED',
  SET_SECONDARY_CIRCUITS_ON_SELECTED_PRICE = 'SET_SECONDARY_CIRCUITS_ON_SELECTED_PRICE',
  ADD_PRICES_TO_CIRCUIT = 'ADD_PRICES_TO_CIRCUIT',
  SELECT_PRICE_ON_CIRCUIT = 'SELECT_PRICE_ON_CIRCUIT',
  SET_SUPPLIER_END_FILTER = 'SET_SUPPLIER_END_FILTER',
  TOGGLE_PRODUCT_SUB_TYPE_FILTER = 'TOGGLE_PRODUCT_SUB_TYPE_FILTER',
  TOGGLE_PRICE_BANDWIDTH_FILTER = 'TOGGLE_PRICE_BANDWIDTH_FILTER',
  TOGGLE_CONTRACT_TERM_FILTER = 'TOGGLE_CONTRACT_TERM_FILTER',
  SET_SUPPORTED_BANDWIDTHS = 'SET_SUPPORTED_BANDWIDTHS',
  SUBMIT_BULK_QUOTE = 'SUBMIT_BULK_QUOTE ',
  TOGGLE_NNI_SHADOW_VLAN = 'TOGGLE_NNI_SHADOW_VLAN',
  UPDATE_PRICED_QUOTE = 'UPDATE_PRICED_QUOTE',
  UPDATE_PRICED_QUOTE_AND_CREATE_ORDER = 'UPDATE_PRICED_QUOTE_AND_CREATE_ORDER',
  UPDATE_QUOTE = 'UPDATE_QUOTE',
  UPDATE_QUOTE_AND_GET_PRICE = 'UPDATE_QUOTE_AND_GET_PRICE',
  UPDATE_QUOTE_ENDED = 'UPDATE_QUOTE_ENDED',
  UPDATE_QUOTE_ERROR = 'UPDATE_QUOTE_ERROR',
  UPDATE_QUOTE_PRICE = 'UPDATE_QUOTE_PRICE',
  UPDATE_QUOTE_STARTED = 'UPDATE_QUOTE_STARTED',
  UPDATE_SINGLE_PRICE = 'UPDATE_SINGLE_PRICE',
  SET_SHORT_BULK_QUOTE_ID = 'SET_SHORT_BULK_QUOTE_ID',
  SET_BULK_QUOTE_ID = 'SET_BULK_QUOTE_ID',
  ADDRESS_RETRIEVAL = 'ADDRESS_RETRIEVAL',
  RESET_PRICING_PROGRESS_STATE = 'RESET_PRICING_PROGRESS_STATE',
  PUBLISH_QUOTE = 'PUBLISH_QUOTE',
  PUBLISH_QUOTE_SUCCESS = 'PUBLISH_QUOTE_SUCCESS',
  PUBLISH_QUOTE_FAILURE = 'PUBLISH_QUOTE_FAILURE',
  SWITCH_LOCATION_LOOKUP = 'SWITCH_LOCATION_LOOKUP',
  SET_IS_MANAGED_DIA = 'SET_IS_MANAGED_DIA',
  SET_IS_ENGINEER_INSTALLATION_REQUIRED = 'SET_IS_ENGINEER_INSTALLATION_REQUIRED',
  SET_IS_RACK_MOUNT_REQUIRED = 'SET_IS_RACK_MOUNT_REQUIRED',
  SAVE_ONAT_ADDRESS_ID = 'SAVE_ONAT_ADDRESS_ID',
  SAVE_ONAT_ADDRESS_ID_SUCCESS = 'SAVE_ONAT_ADDRESS_ID_SUCCESS',
  SAVE_ONAT_ADDRESS_ID_FAILURE = 'SAVE_ONAT_ADDRESS_ID_FAILURE',
  CLEAR_ONAT_ADDRESS_ID_FAILURE = 'CLEAR_ONAT_ADDRESS_ID_FAILURE',
  AVAILABILITY_CHECK_CHANGE = 'AVAILABILITY_CHECK_CHANGE',
  AVAILABILITY_CHECK_REQUEST_START = 'AVAILABILITY_CHECK_REQUEST_START',
  AVAILABILITY_CHECK_REQUEST_SUCCESS = 'AVAILABILITY_CHECK_REQUEST_SUCCESS',
  AVAILABILITY_CHECK_REQUEST_FAILURE = 'AVAILABILITY_CHECK_REQUEST_FAILURE',
  AVAILABILITY_CHECK_TAB_CHANGE = 'AVAILABILITY_CHECK_TAB_CHANGE',
  SET_IS_MULTIQUOTE = 'SET_IS_MULTIQUOTE',
  NEW_QUOTE_MESSAGE = 'NEW_QUOTE_MESSAGE',
  CREATE_MULTIPLE_ORDERS = 'CREATE_MULTIPLE_ORDERS',
  CREATE_MULTIPLE_ORDERS_STARTED = 'CREATE_MULTIPLE_ORDERS_STARTED',
  CREATE_MULTIPLE_ORDERS_SUCCESS = 'CREATE_MULTIPLE_ORDERS_SUCCESS',
  CREATE_MULTIPLE_ORDERS_POA_SUCCESS = 'CREATE_MULTIPLE_ORDERS_POA_SUCCESS',
  CREATE_MULTIPLE_ORDERS_ERROR = 'CREATE_MULTIPLE_ORDERS_ERROR',
  CREATE_MULTIPLE_ORDERS_ENDED = 'CREATE_MULTIPLE_ORDERS_ENDED',
  CREATE_MULTIPLE_ORDERS_CLEANUP = 'CREATE_MULTIPLE_ORDERS_CLEANUP',
}

export type QuoteAction = GenericAction & {
  key?: keyof IQuotesState;
  notListed?: boolean;
};

export interface IGetExistingQuote {
  payload: { quoteId: string };
  type: QuoteActionTypes.GET_EXISTING_QUOTE;
}

export interface IDownloadQuotes {
  payload: IDownloadQuotesType;
  type: QuoteActionTypes.DOWNLOAD_QUOTES;
}

export interface IGetExistingQuoteEnded {
  type: QuoteActionTypes.GET_EXISTING_QUOTE_ENDED;
}

export interface IGetExistingQuoteError {
  payload: { response: ErrorResponse | null };
  type: QuoteActionTypes.GET_EXISTING_QUOTE_ERROR;
}

export interface IGetExistingQuoteStarted {
  type: QuoteActionTypes.GET_EXISTING_QUOTE_STARTED;
}

export interface IPostcodeTypePayload {
  postcode: string;
}

export interface IAddressNotListedTypePayload {
  notListed: boolean;
}

export interface IAddressesFoundPayload {
  addresses: AddressesFound;
}

export interface IFullAddressTypePayload {
  fullAddress: IPAFAddressSite | IOnNetSite | null;
  onNetType?: ILocation['onNetType'];
}

export interface IProductTypePayload {
  productType: IQuoteRecordAttributesBase['product_type'];
}

export interface IBearerPayload {
  bearer?: BearerType;
}

export interface IDivercityPayload {
  diversity: boolean;
}

export interface IBandwidthPayload {
  bandwidth: string;
}

export interface ISupportedBandwidthsPayload {
  bandwidths: number[];
}

export interface IContractTermLengthPayload {
  contractTerm: string;
}

export interface ICurrentQuoteDataPayload {
  currentQuote: IQuoteData;
}

export interface IPriceQuotePayload {
  quoteId: string;
}

export interface IUpdateQuotePayload {
  quoteId: string;
  updateValues: IQuoteRecordAttributesBase;
}

export interface IUpdateQuoteAndGetPricePayload {
  quoteId: string;
  updateValues: IQuoteRecordAttributesBase;
  requiresAsyncPrices: boolean;
}

export interface IUpdatePriceQuotePayload {
  quoteId?: string;
  priceId: string;
  updateValues: IPricedQuote;
  updatingPrice?: boolean;
  updatingCabling?: boolean;
  updatingCablingOrigin?: 'A' | 'B';
}

export interface ISetPricePayload {
  prices: IPriceData[];
}

export interface IAmortisationPayload {
  amortised: boolean;
}

export interface IFTTPAggregationPayload {
  fttpAggregation: boolean | null;
}

export interface IRouterChoicePayload {
  routerChoice: DualCircuitRouterConfigurations | null | undefined;
}

export type Journey = 'by_product_type' | 'by_location';

export interface INewQuote {
  quote: IQuotesState['quote'];
  journey: Journey;
}

export interface ISetPostcodeAEnd {
  payload: IPostcodeTypePayload;
  type: QuoteActionTypes.SET_POSTCODE_A_END;
}

export interface ISetPostcodeBEnd {
  payload: IPostcodeTypePayload;
  type: QuoteActionTypes.SET_POSTCODE_B_END;
}

export interface ISetDraftPostcodeAEnd {
  payload: IPostcodeTypePayload;
  type: QuoteActionTypes.SET_DRAFT_POSTCODE_A_END;
}

export interface ISetDraftPostcodeBEnd {
  payload: IPostcodeTypePayload;
  type: QuoteActionTypes.SET_DRAFT_POSTCODE_B_END;
}

export interface ISetAddressesFoundAEnd {
  payload: IAddressesFoundPayload;
  type: QuoteActionTypes.SET_ADDRESSES_FOUND_A_END;
}

export interface ISetAddressesFoundBEnd {
  payload: IAddressesFoundPayload;
  type: QuoteActionTypes.SET_ADDRESSES_FOUND_B_END;
}

export interface ISetFullAddressAEnd {
  payload: IFullAddressTypePayload;
  type: QuoteActionTypes.SET_FULL_ADDRESS_A_END;
}

export interface ISetFullAddressBEnd {
  payload: IFullAddressTypePayload;
  type: QuoteActionTypes.SET_FULL_ADDRESS_B_END;
}

export interface ISetOpenreachAddress {
  payload: {
    address: IOpenReachAddressSite | ALKLocation | null;
    end: 'A' | 'B';
  };
  type: QuoteActionTypes.SET_OPENREACH_ADDRESS;
}

export interface ISetAddressNotListedAEnd {
  payload: IAddressNotListedTypePayload;
  type: QuoteActionTypes.SET_ADDRESS_NOT_LISTED_A_END;
}

export interface ISetAddressNotListedBEnd {
  payload: IAddressNotListedTypePayload;
  type: QuoteActionTypes.SET_ADDRESS_NOT_LISTED_B_END;
}

export interface ISetProductType {
  payload: IProductTypePayload;
  type: QuoteActionTypes.SET_PRODUCT_TYPE;
}

export interface ISetBearer {
  payload: IBearerPayload;
  type: QuoteActionTypes.SET_BEARER;
}

export interface ISetDivercity {
  payload: IDivercityPayload;
  type: QuoteActionTypes.SET_DIVERSITY;
}

export interface ISetBandwidth {
  payload: IBandwidthPayload;
  type: QuoteActionTypes.SET_BANDWIDTH;
}

export interface IToggleChosenBandwidth {
  payload: { bandwidth: string };
  type: QuoteActionTypes.TOGGLE_CHOSEN_BANDWIDTH;
}

export interface IToggleChosenContractTerm {
  payload: { term: string };
  type: QuoteActionTypes.TOGGLE_CHOSEN_CONTRACT_TERM;
}

export interface ISetSupportedBandwidths {
  payload: ISupportedBandwidthsPayload;
  type: QuoteActionTypes.SET_SUPPORTED_BANDWIDTHS;
}

export interface ISetContractTermLength {
  payload: IContractTermLengthPayload;
  type: QuoteActionTypes.SET_CONTRACT_TERM_LENGTH;
}

export interface ISetCurrentQuoteData {
  payload: ICurrentQuoteDataPayload;
  type: QuoteActionTypes.SET_CURRENT_QUOTE_DATA;
}

export interface ICreateQuote {
  payload: INewQuote;
  type: QuoteActionTypes.CREATE_QUOTE;
}

export interface ICloneQuote {
  payload: INewQuote;
  type: QuoteActionTypes.CLONE_QUOTE;
}

export interface ISetPriceData {
  payload: ISetPricePayload;
  type: QuoteActionTypes.SET_PRICE_DATA;
}

export interface ISetFilteredPriceData {
  type: QuoteActionTypes.SET_FILTERED_PRICE_DATA;
}

export interface ICreateQuoteStarted {
  type: QuoteActionTypes.CREATE_QUOTE_STARTED;
}

export interface ICreateQuoteEnded {
  type: QuoteActionTypes.CREATE_QUOTE_ENDED;
}

export interface ICreateQuoteError {
  type: QuoteActionTypes.CREATE_QUOTE_ERROR;
}

export interface ICloneQuoteStarted {
  type: QuoteActionTypes.CLONE_QUOTE_STARTED;
}

export interface ICloneQuoteEnded {
  type: QuoteActionTypes.CLONE_QUOTE_ENDED;
}

export interface ICloneQuoteError {
  type: QuoteActionTypes.CLONE_QUOTE_ERROR;
}

export interface IUpdateQuote {
  payload: IUpdateQuotePayload;
  type: QuoteActionTypes.UPDATE_QUOTE;
}

export type UpdatePricedQuotePayloadPrice = {
  id: string;
  amortised: boolean;
};

export type UpdatePricedQuotePayloadQuote = {
  id: string;
  selectedPriceId: string;
  fttpAggregation: boolean | null;
  routerChoice?: MDIAFields['routerChoice'];
  secondRouterOptions?: MDIAFields['secondRouterOptions'];
  secondIPChoice?: IPType | null;
};

export interface IUpdatePricedQuote {
  payload: {
    price: UpdatePricedQuotePayloadPrice;
    quote: UpdatePricedQuotePayloadQuote;
  };
  type: QuoteActionTypes.UPDATE_PRICED_QUOTE;
}

export interface IUpdatePricedQuoteAndCreateOrder {
  payload: {
    updatePricedQuoteAction: IUpdatePricedQuote;
    createOrder: ICreateOrder;
  };
  type: QuoteActionTypes.UPDATE_PRICED_QUOTE_AND_CREATE_ORDER;
}

export interface IUpdateQuoteAndGetPrice {
  payload: IUpdateQuoteAndGetPricePayload;
  type: QuoteActionTypes.UPDATE_QUOTE_AND_GET_PRICE;
}

export interface IPriceQuote {
  payload: { quoteId: string; requiresAsyncPrices: boolean };
  type: QuoteActionTypes.PRICE_QUOTE;
}

export interface IPriceComplexQuoteUpdateProgress {
  payload: { progress: IComplexQuotePricingProgress };
  type: QuoteActionTypes.PRICE_COMPLEX_QUOTE_UPDATE_PROGRESS;
}

export interface IPriceComplexQuoteCompleted {
  type: QuoteActionTypes.PRICE_COMPLEX_QUOTE_COMPLETED;
}

export interface IUpdatePriceQuote {
  payload: IUpdatePriceQuotePayload;
  type: QuoteActionTypes.UPDATE_QUOTE_PRICE;
}

export interface IPriceQuoteEnded {
  type: QuoteActionTypes.PRICE_QUOTE_ENDED;
}

export interface IPriceQuoteErrorPayload {
  error?: JsonApiErrorResponse;
}

export interface IPriceQuoteError {
  payload: IPriceQuoteErrorPayload;
  type: QuoteActionTypes.PRICE_QUOTE_ERROR;
}

export interface IPriceQuoteSuccess {
  type: QuoteActionTypes.PRICE_QUOTE_SUCCESS;
}

export interface ISetQuotePOA {
  type: QuoteActionTypes.SET_QUOTE_POA;
}

export interface IPricingQuoteWillRetry {
  type: QuoteActionTypes.PRICING_QUOTE_WILL_RETRY;
}

export interface IPriceQuoteStarted {
  payload: {
    updatingPrice: boolean;
    updatingCabling: boolean;
    requiresAsyncPrices: boolean;
  };
  type: QuoteActionTypes.PRICE_QUOTE_STARTED;
}

export interface IReplaceQuoteState {
  payload: { quote: IQuoteRecord };
  type: QuoteActionTypes.REPLACE_QUOTE_STATE;
}

export interface ISetCerillionSentAt {
  payload: { cerillionSentAt: string };
  type: QuoteActionTypes.SET_CERILLION_SENT_AT;
}

export interface IResetQuoteState {
  type: QuoteActionTypes.RESET_QUOTE_STATE;
}

export interface IUpdateQuoteStarted {
  type: QuoteActionTypes.UPDATE_QUOTE_STARTED;
}

export interface IUpdateQuoteEnded {
  type: QuoteActionTypes.UPDATE_QUOTE_ENDED;
}

export interface IUpdateQuoteError {
  type: QuoteActionTypes.UPDATE_QUOTE_ERROR;
}

export interface ISetOpticalDataCentreList {
  payload: { opticalDataCentreList: IOpticalDataCentre[] };
  type: QuoteActionTypes.SET_OPTICAL_DATA_CENTRE_LIST;
}

export interface ISetCloudProvidersList {
  payload: { cloudProvidersList: ICloudProvider[] };
  type: QuoteActionTypes.SET_CLOUD_PROVIDERS_LIST;
}

export interface ISetCloudProvider {
  payload: { cloudProvider: ICloudProvider };
  type: QuoteActionTypes.SET_CLOUD_PROVIDER;
}

export interface ISetNNIIdAndReference {
  payload: {
    id: string;
    nniReference: string;
    dataCentreReference: string;
    postcode: string;
    shadowVLAN: boolean;
    type: NNISelectionType;
    label: string;
  };
  type: QuoteActionTypes.SET_NNI_ID_AND_REFERENCE;
}

export interface IToggleNNIShadowVLAN {
  type: QuoteActionTypes.TOGGLE_NNI_SHADOW_VLAN;
}

export interface ISetDataCentreAEnd {
  payload: { id: string; reference: string };
  type: QuoteActionTypes.SET_DATA_CENTRE_A_END;
}

export interface ISetDataCentreBEnd {
  payload: { id: string; reference: string };
  type: QuoteActionTypes.SET_DATA_CENTRE_B_END;
}
export interface ISetDataCentreBEndNotListed {
  type: QuoteActionTypes.SET_DATA_CENTRE_B_END_NOT_LISTED;
  notListed: boolean;
}

export interface ISetSelectedPrice {
  payload: {
    preferredPriceId?: string;
    amortised?: boolean;
    missingPrices?: IDummyPrice[];
  };
  type: QuoteActionTypes.SET_SELECTED_PRICE;
}

export interface ISetSecondaryExchanges {
  payload: {
    priceId: string;
    secondaryCircuits: IPriceData['secondary_circuits'];
  };
  type: QuoteActionTypes.SET_SECONDARY_CIRCUITS_ON_SELECTED_PRICE;
}

export interface ISetSecondaryCircuitsEnabled {
  payload: {
    priceId: string;
    enabled: boolean;
  };
  type: QuoteActionTypes.SET_SECONDARY_CIRCUITS_ENABLED;
}

export interface ISetPricesOnCircuit {
  payload: {
    primaryPriceId: string;
    circuitId: string;
    prices: IPriceData[];
  };
  type: QuoteActionTypes.ADD_PRICES_TO_CIRCUIT;
}

export interface ISetSelectedPriceOnCircuit {
  payload: {
    primaryPriceId: string;
    circuitId: string;
    priceId: string;
  };
  type: QuoteActionTypes.SELECT_PRICE_ON_CIRCUIT;
}

export interface ISetSupplierEndFilter {
  payload: { supplier: Supplier; endType: SupplierEndType };
  type: QuoteActionTypes.SET_SUPPLIER_END_FILTER;
}

export interface IToggleProductSubTypeFilter {
  payload: { productSubType: ProductSubType };
  type: QuoteActionTypes.TOGGLE_PRODUCT_SUB_TYPE_FILTER;
}

export interface ITogglePriceBandwidthFilter {
  payload: IBandwidthPayload;
  type: QuoteActionTypes.TOGGLE_PRICE_BANDWIDTH_FILTER;
}

export interface IToggleContractTermFilter {
  payload: { contractTerm: ContractTerm };
  type: QuoteActionTypes.TOGGLE_CONTRACT_TERM_FILTER;
}

export interface ISetFTTPAggregation {
  payload: IFTTPAggregationPayload;
  type: QuoteActionTypes.SET_FTTP_AGGREGATION;
}

export interface ISetRouterChoice {
  payload: IRouterChoicePayload;
  type: QuoteActionTypes.SET_ROUTER_CHOICE;
}

export interface IClearRouterChoice {
  payload: IRouterChoicePayload;
  type: QuoteActionTypes.CLEAR_ROUTER_CHOICE;
}

export interface ISetSecondaryCircuitOptions {
  payload: MDIAFields['secondRouterOptions'];
  type: QuoteActionTypes.SET_SECONDARY_CIRCUIT_OPTIONS;
}

export interface ISetAmortisation {
  payload: IAmortisationPayload;
  type: QuoteActionTypes.SET_AMORTISATION;
}

export interface ISubmitBulkQuote {
  payload: { bulkQuoteFile: File };
  type: QuoteActionTypes.SUBMIT_BULK_QUOTE;
}

export interface IBulkQuoteSubmitStarted {
  type: QuoteActionTypes.BULK_QUOTE_SUBMIT_STARTED;
}

export interface IBulkQuoteSubmitEnded {
  type: QuoteActionTypes.BULK_QUOTE_SUBMIT_ENDED;
}

export interface IDownloadQuotesStarted {
  type: QuoteActionTypes.DOWNLOAD_QUOTES_STARTED;
}

export interface IDownloadQuotesEnded {
  type: QuoteActionTypes.DOWNLOAD_QUOTES_ENDED;
}

export interface IDownloadQuotesError {
  type: QuoteActionTypes.DOWNLOAD_QUOTES_ERROR;
}

export interface IBulkQuoteSubmitError {
  payload: IBulkQuoteSubmitErrorData;
  type: QuoteActionTypes.BULK_QUOTE_SUBMIT_ERROR;
}

export interface IResetBulkQuoteSubmitState {
  type: QuoteActionTypes.RESET_BULK_QUOTE_SUBMIT_STATE;
}

export interface IGetAvailableAccessMethods {
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS;
}

export interface IGetAvailableAccessMethodsStarted {
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_STARTED;
}

export interface IGetAvailableAccessMethodsEnded {
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ENDED;
  payload: {
    availability: IAccessAvailability;
  };
}

export interface IGetAvailableAccessMethodsError {
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ERROR;
  payload: { response: ErrorResponse | null };
}

export interface ISetIPOption {
  payload: {
    option: IPType;
  };
  type: QuoteActionTypes.SET_IP_OPTION;
}

export interface ISetSecondIPOption {
  payload: {
    option: IPType;
  };
  type: QuoteActionTypes.SET_SECOND_IP_OPTION;
}
export interface ISetDIAIPAllocation {
  payload: {
    dia_ip_allocation: IPAddressAllocation;
  };
  type: QuoteActionTypes.SET_DIA_IP_ALLOCATION;
}

export interface ISetCablingSuccessState {
  payload: {
    state: boolean;
  };
  type: QuoteActionTypes.SET_CABLING_SUCCESS_STATE;
}

export interface IUpdateSinglePrice {
  payload: {
    price: IQuotePricedOrOrdered;
  };
  type: QuoteActionTypes.UPDATE_SINGLE_PRICE;
}

export interface IRefreshFilteredPrices {
  type: QuoteActionTypes.REFRESH_FILTERED_PRICES;
}

export interface ISetQuoteConnectionType {
  type: QuoteActionTypes.SET_QUOTE_CONNECTION_TYPE;
  payload: {
    connectionType: OverallConnectionType;
  };
}

export interface ISetShortBulkQuoteId {
  type: QuoteActionTypes.SET_SHORT_BULK_QUOTE_ID;
  payload: {
    shortBulkQuoteId: string;
  };
}

export interface ISetBulkQuoteId {
  type: QuoteActionTypes.SET_BULK_QUOTE_ID;
  payload: {
    bulkQuoteId?: string;
  };
}

export interface ISetAddressRetrieval {
  type: QuoteActionTypes.ADDRESS_RETRIEVAL;
  payload: {
    aEnd: boolean;
    onNetAndPaf: boolean;
    retrieve: boolean;
  };
}

export interface IResetPricingProgressStateAction {
  type: QuoteActionTypes.RESET_PRICING_PROGRESS_STATE;
  payload: Record<string, never>;
}

export interface IPublishQuote {
  type: QuoteActionTypes.PUBLISH_QUOTE;
}

export interface IPublishQuoteSuccess {
  type: QuoteActionTypes.PUBLISH_QUOTE_SUCCESS;
}

export interface IPublishQuoteFailure {
  type: QuoteActionTypes.PUBLISH_QUOTE_FAILURE;
}

export interface ISwitchLocationLookup {
  type: QuoteActionTypes.SWITCH_LOCATION_LOOKUP;
  payload: {
    end: 'A' | 'B';
    lookupType: LocationLookup;
  };
}

export interface ISetIsManagedDIA {
  type: QuoteActionTypes.SET_IS_MANAGED_DIA;
  payload: {
    is_managed_dia: boolean;
  };
}

export interface ISetIsEngineerInstallationRequired {
  type: QuoteActionTypes.SET_IS_ENGINEER_INSTALLATION_REQUIRED;
  payload: {
    is_engineer_installation_required: boolean;
  };
}

export interface ISetIsRackMountRequired {
  type: QuoteActionTypes.SET_IS_RACK_MOUNT_REQUIRED;
  payload: {
    is_rack_mount_required: boolean;
  };
}

export interface ISaveONATAddressId {
  type: QuoteActionTypes.SAVE_ONAT_ADDRESS_ID;
  payload: {
    id: IQuotesState['quote']['b_end_onat_address_id'];
    end: 'A' | 'B';
  };
}

export interface ISaveONATAddressIdSuccess {
  type: QuoteActionTypes.SAVE_ONAT_ADDRESS_ID_SUCCESS;
  payload: {
    address: FlatONATAddressResponseItem;
  };
}

export interface ISaveONATAddressIdFailure {
  type: QuoteActionTypes.SAVE_ONAT_ADDRESS_ID_FAILURE;
}

export interface IClearONATAddressIdFailure {
  type: QuoteActionTypes.CLEAR_ONAT_ADDRESS_ID_FAILURE;
  payload: {
    end: 'A' | 'B';
  };
}

export interface AvailabilityCheckChange {
  type: QuoteActionTypes.AVAILABILITY_CHECK_CHANGE;
  payload: {
    changes: AvailabilityCheckRequest;
    source: Source;
  };
}

export interface AvailabilityCheckRequestStart {
  type: QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_START;
}

export interface AvailabilityCheckRequestSuccess {
  payload: { response: AvailabilityCheckResponse };
  type: QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_SUCCESS;
}

export interface AvailabilityCheckRequestFailure {
  type: QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_FAILURE;
}

export interface AvailabilityCheckTabChange {
  payload: { source: Source };
  type: QuoteActionTypes.AVAILABILITY_CHECK_TAB_CHANGE;
}

export interface ISetMultiQuote {
  payload: {
    isMultiQuote: boolean;
    bulkRequestId: string;
  };
  type: QuoteActionTypes.SET_IS_MULTIQUOTE;
}

export interface NewQuoteMessage {
  type: QuoteActionTypes.NEW_QUOTE_MESSAGE;
  payload: {
    message: MessageResource;
  };
}

export interface ICreateMultipleOrders {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS;
  payload: { quotes: IQuoteListItem[]; companyName: string };
}

export interface ICreateMultipleOrdersStarted {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_STARTED;
}

export interface ICreateMultipleOrdersEnded {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_ENDED;
}

export interface ICreateMultipleOrdersError {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_ERROR;
  payload: { relatedQuote: IQuoteListItem };
}

export interface ICreateMultipleOrdersSuccess {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_SUCCESS;
  payload: { relatedQuote: IQuoteListItemWithOrderId };
}

export interface ICreateMultipleOrdersPOASuccess {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_POA_SUCCESS;
  payload: { relatedQuote: IQuoteListItem };
}

export interface ICreateMultipleOrdersCleanup {
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_CLEANUP;
}
