import { TextWithLabelSummary } from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { ISiteConfig } from 'Order/types/location';
import Column from 'shared/components/atoms/Column';
import React from 'react';

export const ShadowNNISummaryFields = ({ siteConfig }: { siteConfig: ISiteConfig }) => (
  <>
    <Column classNames={['pr-5 text-break']} lgWidth={6}>
      <TextWithLabelSummary
        id="summary_shadow_nni_job_reference"
        label="Shadow NNI Job Reference"
        text={siteConfig.shadow_nni_job_reference || ''}
      />
    </Column>
    <Column classNames={['pr-5 text-break']} lgWidth={6}>
      <TextWithLabelSummary
        id="summary_shadow_nni_idn_number"
        label="Shadow IDN"
        text={siteConfig.shadow_nni_idn_number || ''}
      />
    </Column>
  </>
);
