import React, { FC, FunctionComponent, useEffect } from 'react';
import { ConnectorType, ISecondarySiteConfig, ISiteConfig, MediaType, PowerType } from 'Order/types/location';
import RadioButton, { RadioButtonDisabledOverride } from 'shared/components/molecules/RadioButton';
import TextInput from 'shared/components/atoms/TextInput';
import FormHeader from '../FormHeader';
import ConnectorTypeOptions from './ConnectorTypeOptions';
import MediaTypeOptions, { useMediaTypes } from './MediaTypeOptions';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import VLANConfig from '../VLANConfiguration';
import { getDIAOrderOptions } from './DIAOrderOptions';
import { Feature } from 'FeatureFlags/types';
import { StackedOptions } from '../utils/StackedOptions';
import Column from 'shared/components/atoms/Column';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

const title = `Provide specific location details. 
Please note, we are unable to accept demarcation details for Floor, Room and Rack ID as 'N/A' or 'TBC'.
Please ensure these details are completed before submitting your order, to avoid rejection.`;

interface ISiteConfigProps {
  quote: IQuote;
  identifierPrefix: string;
  isFTTXQuote: boolean;
  isSupplierNNAT: boolean;
  siteConfig: ISiteConfig | ISecondarySiteConfig;
  endPort: BearerType;
  selectedPrice: IPriceData;
  updateSiteConfig(updated: ISiteConfig | ISecondarySiteConfig): void;
}

const MediaTypeInfoMessage: FC<{
  identifierPrefix: string;
  endPort: BearerType;
  isSupplierNNAT: boolean;
  siteConfig: ISiteConfig;
}> = ({ identifierPrefix, endPort, isSupplierNNAT, siteConfig }) => {
  const { showLR, showLX, showSR, showSX } = useMediaTypes(identifierPrefix, endPort, isSupplierNNAT, siteConfig);
  const displayMessage = (showLR || showLX) && (showSR || showSX);

  if (!displayMessage) return null;
  return (
    <p style={{ fontStyle: 'italic' }}>
      If ordering to an Equinix Data Centre, effective 31st March 2024, Equinix will only supply Single Mode Fibre cross
      connects. Please ensure you select the Single Mode option to avoid delays with placing your order.
    </p>
  );
};

const SiteConfigInformation: FunctionComponent<React.PropsWithChildren<ISiteConfigProps>> = ({
  quote,
  identifierPrefix,
  isFTTXQuote,
  isSupplierNNAT,
  updateSiteConfig,
  siteConfig,
  endPort,
  selectedPrice,
}) => {
  const onChange = (field: keyof ISiteConfig, value: any): void => {
    const payload: ISiteConfig = {
      ...siteConfig,
      [field]: value,
    };

    if (field === 'connectorType' && value === ConnectorType.RJ45) {
      payload.mediaType = MediaType.TX;
    } else if (
      field === 'connectorType' &&
      value !== ConnectorType.RJ45 &&
      siteConfig.connectorType === ConnectorType.RJ45
    ) {
      payload.mediaType = null;
    }

    updateSiteConfig(payload);
  };

  useEffect(() => {
    if (isFTTXQuote && !siteConfig.autoNegotiation) {
      onChange('autoNegotiation', true);
    } else if (siteConfig.autoNegotiation === undefined) {
      onChange('autoNegotiation', endPort !== BearerType.LARGE);
    }
  }, []);

  const isManagedDIA = quote.location.aEnd.is_managed_dia;

  let forceAutoNegotiationYes: boolean;
  let forceAutoNegotiationNo: boolean;
  let removeAutoNegotiationSection = false;
  let allowVLAN: boolean;

  const isVLANAllowed = quote.productType !== ProductType.P2P;

  // MDIA 1.2 logic
  if (featureFlag.isEnabled(Feature.newMDIAUI) && quote.productType === ProductType.DIA) {
    const {
      autoNegotiation: { isYesMandatory, isNoMandatory, removeSection },
      vlanTagging: { isAvailable },
    } = getDIAOrderOptions(isManagedDIA, endPort, isSupplierNNAT);

    forceAutoNegotiationYes = isYesMandatory;
    forceAutoNegotiationNo = isNoMandatory;
    removeAutoNegotiationSection = removeSection;
    allowVLAN = isAvailable && isVLANAllowed;
  }
  // classic logic
  else {
    forceAutoNegotiationNo = endPort === BearerType.LARGE;
    forceAutoNegotiationYes = isFTTXQuote || isManagedDIA;
    allowVLAN = isVLANAllowed;
  }

  return (
    <>
      <FormHeader title={title} />

      <div className="row">
        <Column defaultWidth={6}>
          <TextInput
            inputIdentifierPrefix={identifierPrefix}
            fieldName="_floor"
            maxLength={50}
            onChange={(e) => onChange('floor', e.target.value)}
            labelText="Floor *"
            value={siteConfig.floor}
          />
        </Column>
        <Column defaultWidth={6}>
          <TextInput
            inputIdentifierPrefix={identifierPrefix}
            fieldName="_suite"
            onChange={(e) => onChange('suite', e.target.value)}
            maxLength={50}
            labelText="Suite"
            value={siteConfig.suite}
            optional={true}
          />
        </Column>
      </div>

      <div className="row mb-1">
        <Column defaultWidth={6}>
          <TextInput
            inputIdentifierPrefix={identifierPrefix}
            fieldName="_room"
            onChange={(e) => onChange('room', e.target.value)}
            labelText="Room *"
            maxLength={50}
            value={siteConfig.room || ''}
          />
        </Column>
        <Column defaultWidth={6}>
          <TextInput
            inputIdentifierPrefix={identifierPrefix}
            fieldName="_rackId"
            onChange={(e) => onChange('rackId', e.target.value)}
            labelText="Rack ID *"
            maxLength={50}
            value={siteConfig.rackId || ''}
          />
        </Column>
      </div>

      {!isFTTXQuote && (
        <>
          <div className="row mb-2">
            <Column defaultWidth={6}>
              <FormHeader title="Connector type *" />

              <ConnectorTypeOptions
                identifierPrefix={identifierPrefix}
                siteConfig={siteConfig}
                onChange={onChange}
                endPort={endPort}
                isSupplierNNAT={isSupplierNNAT}
              />
            </Column>

            <Column defaultWidth={6}>
              <FormHeader title="Media type *" />

              <MediaTypeOptions
                identifierPrefix={identifierPrefix}
                endPort={endPort}
                siteConfig={siteConfig}
                onChange={onChange}
                isSupplierNNAT={isSupplierNNAT}
              />
            </Column>
          </div>
          <div className="row mb-4">
            <Column defaultWidth={6}>
              <FormHeader title="Power supply? *" />

              <StackedOptions>
                <RadioButtonDisabledOverride>
                  <RadioButton
                    id={`${identifierPrefix}_powerType_AC`}
                    description="AC"
                    onClick={() => onChange('powerType', PowerType.AC)}
                    selected={siteConfig.powerType === PowerType.AC}
                    disabled={isManagedDIA}
                  />
                </RadioButtonDisabledOverride>

                {!isManagedDIA && !isSupplierNNAT && (
                  <RadioButton
                    id={`${identifierPrefix}_powerType_DC`}
                    description="DC"
                    onClick={() => onChange('powerType', PowerType.DC)}
                    selected={siteConfig.powerType === PowerType.DC}
                  />
                )}
              </StackedOptions>
            </Column>
            <Column defaultWidth={6}>
              <MediaTypeInfoMessage
                siteConfig={siteConfig}
                endPort={endPort}
                isSupplierNNAT={isSupplierNNAT}
                identifierPrefix={identifierPrefix}
              />
            </Column>
          </div>
        </>
      )}

      <div className="row mb-2 mb-4">
        {(!isSupplierNNAT || quote.productType === ProductType.DIA) && (
          <Column defaultWidth={forceAutoNegotiationYes || forceAutoNegotiationNo ? 6 : 12}>
            {!removeAutoNegotiationSection && (
              <>
                <FormHeader title="Auto Negotiation *" />

                <div className="row">
                  {forceAutoNegotiationNo ? (
                    <Column defaultWidth={12}>
                      <RadioButton id={`${identifierPrefix}_autoNegotiation_no`} description="No" selected={true} />
                    </Column>
                  ) : forceAutoNegotiationYes ? (
                    <Column defaultWidth={12}>
                      <RadioButtonDisabledOverride>
                        <RadioButton
                          id={`${identifierPrefix}_autoNegotiation_yes`}
                          description="Yes"
                          selected={true}
                          disabled={quote.productType !== ProductType.DIA && isManagedDIA}
                        />
                      </RadioButtonDisabledOverride>
                    </Column>
                  ) : (
                    <>
                      <Column defaultWidth={6}>
                        <RadioButton
                          id={`${identifierPrefix}_autoNegotiation_yes`}
                          description="Yes"
                          onClick={() => onChange('autoNegotiation', true)}
                          selected={siteConfig.autoNegotiation}
                        />
                      </Column>
                      <Column defaultWidth={6}>
                        <RadioButton
                          id={`${identifierPrefix}_autoNegotiation_no`}
                          description="No"
                          onClick={() => onChange('autoNegotiation', false)}
                          selected={!siteConfig.autoNegotiation}
                        />
                      </Column>
                    </>
                  )}
                </div>
              </>
            )}
          </Column>
        )}

        {allowVLAN && (
          <Column defaultWidth={6}>
            <VLANConfig
              identifierPrefix={identifierPrefix}
              siteConfig={siteConfig}
              updateSiteConfig={updateSiteConfig}
              popId={selectedPrice.a_end_p_o_p_id}
              defaultNNIRequestContactEmail={quote.createdBy}
            />
          </Column>
        )}
      </div>
    </>
  );
};

export default SiteConfigInformation;
