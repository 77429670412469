import React, { FunctionComponent } from 'react';
import { setBearerAction } from 'Quotes/actions';
import { bearerForDisplay } from 'shared/utils/connectionCapacity';
import {
  BearerType,
  bearerTypes,
  bearerTypesOptical,
} from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { useDispatch, useSelector } from 'react-redux';
import { selectBearer, selectCommonCircuitOptions, selectP2CCTBearers, selectProductType } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import { OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';

export const Bearer: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const productType = useSelector(selectProductType);
  if (productType === ProductType.P2CCT) return <P2CCTBearer />;
  else return <DefaultBearer />;
};

const DefaultBearer: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const commonBearers = useSelector(selectCommonCircuitOptions)?.ports ?? [];
  const allBearers = [...new Set([...bearerTypes, ...bearerTypesOptical])];

  return <GenericBearer allBearers={allBearers} availableBearers={commonBearers} />;
};

const P2CCTBearer: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const bearers = useSelector(selectP2CCTBearers);
  return <GenericBearer allBearers={bearerTypes} availableBearers={bearers} />;
};

function GenericBearer({ allBearers, availableBearers }: { allBearers: BearerType[]; availableBearers: BearerType[] }) {
  const dispatch = useDispatch();
  const selectedBearer = useSelector(selectBearer);
  return (
    <>
      <BearerHeader />

      <OptionGroup<BearerType>
        options={getBearerOptions(allBearers, availableBearers)}
        value={selectedBearer}
        onClick={(value) => {
          dispatch(setBearerAction(value));
        }}
      />
    </>
  );
}

function getBearerOptions(allBearers: BearerType[], availableBearers: BearerType[]) {
  return allBearers.map((bearerValue) => ({
    label: bearerForDisplay(bearerValue),
    value: bearerValue,
    disabled: !availableBearers.includes(bearerValue),
    customId: `bearer--${bearerValue}`,
    tooltip: !availableBearers.includes(bearerValue)
      ? 'This option is unavailable based on your current selections'
      : undefined,
  }));
}

export function BearerHeader() {
  return <h5 className="h5 pb-2">Bearer size</h5>;
}
