import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { isCheckboxCell } from 'shared/utils/tableHelper';
import MultiSelectTable, { ISingleFilter, RowCell } from '../../../../shared/components/organisms/MultiSelectTable';
import headers from './headers';
import { quoteById } from 'Quotes/Routes';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { selectSingleQuotesTableFilters, selectSingleQuotesTablePageSize } from 'Tables/selectors';
import TableNames from '../../../../Tables/types/tableNames';
import { IFilterPair } from 'shared/types/filterPairType';
import { UpdateHeaderCellWidths } from 'shared/utils/updateHeaderCellWidth';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IQuotesTable {
  paging: IServerSidePaging;
}

const QuotesTable: FC<React.PropsWithChildren<IQuotesTable>> = (props) => {
  const navigate = useNavigate();

  return <QuotesTableInternal {...props} navigate={navigate} />;
};

export const QuotesTableInternal: FC<React.PropsWithChildren<IQuotesTable & Navigation>> = ({ paging, navigate }) => {
  const defaultPageSize = useSelector(selectSingleQuotesTablePageSize);

  const rowClickHandler = (cell: RowCell) => {
    if (!isCheckboxCell(cell)) {
      const quoteId = cell.row.original.id;
      navigate(quoteById(quoteId));
    }
  };

  const initialFilters: ISingleFilter[] = [];

  const filterPairs: IFilterPair<'singleQuotesTable'>[] = [
    { tableHeaderId: 'locations', storeField: 'location' },
    { tableHeaderId: 'created_by', storeField: 'quotedBy' },
    { tableHeaderId: 'id', storeField: 'quoteId' },
    { tableHeaderId: 'product_type', storeField: 'product' },
    { tableHeaderId: 'access_type', storeField: 'accessProvider' },
    { tableHeaderId: 'access_method', storeField: 'accessType' },
    { tableHeaderId: 'created_at', storeField: 'createdAt' },
    { tableHeaderId: 'updated_at', storeField: 'updatedAt' },
    { tableHeaderId: 'quote_state', storeField: 'status' },
  ];

  const singleQuoteTableFilters = useSelector(selectSingleQuotesTableFilters);

  filterPairs.forEach((pair) => {
    const storeValue = singleQuoteTableFilters[pair.storeField];
    if (storeValue) {
      initialFilters.push({ id: pair.tableHeaderId, value: storeValue });
    }
  });

  return (
    <MultiSelectTable
      headers={UpdateHeaderCellWidths(headers, TableNames.singleQuotesTable)}
      paging={paging}
      isLoading={paging.isLoading}
      rows={paging.results}
      rowClickHandler={rowClickHandler}
      className="quotes-table"
      initialFilters={initialFilters}
      defaultSort={{
        id: 'updated_at',
        desc: true,
      }}
      defaultPageSize={defaultPageSize}
      storeTableName={TableNames.singleQuotesTable}
    />
  );
};

export default QuotesTable;
