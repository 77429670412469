import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IEditableFieldProps {
  isEditing: boolean;
  label?: string;
  className?: string;
  value?: string;
  customDisplayValue?: ReactNode;
}
const UnstyledEditableField: FC<React.PropsWithChildren<IEditableFieldProps>> = ({
  className,
  label,
  isEditing,
  children,
  value,
  customDisplayValue,
}) => {
  return (
    <div className={className}>
      <h3>{label}</h3>
      {isEditing && <div className="editing-field">{children}</div>}
      {!isEditing && (customDisplayValue ?? value)}
      {!isEditing && value?.length === 0 && <i>Empty</i>}
    </div>
  );
};

export const EditableField = styled(UnstyledEditableField)`
  .editing-field {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      height: 2.5em;
      padding-left: 1em;
    }

    textarea {
      min-height: 120px;
    }

    // TODO: move this style to remainingCharacters element
    span {
      text-align: right;
    }
  }
  i {
    color: ${({ theme }) => theme.colours.grey50};
  }
`;
