import { ApiRecord } from 'shared/types/ApiRecord';
import IQuoteRecordAttributesBase from './quoteRecordAttributesBase';
import { IBulkQuoteRecord } from './bulkQuoteRecord';
import { QuoteOrigin } from './store';
import IPricedQuote from './pricedQuote';
import { INNIRecord, PopResource, SupplierNNIResource } from 'Location/NNI/types';
import { Resource, ResourceTypes } from 'Request';
import { ONATAddressResponseItem } from 'shared/types/onatAddress';

import { MessageResponseData } from 'shared/types/Messages';
import { IPAFAddressSiteQuoteIncludesResponse } from 'shared/types/postcodeResults';

export type IQuoteRecordAttributes = IQuoteRecordAttributesBase & {
  created_at: string;
  updated_at: string;
  created_by?: string;
  a_end_udprn?: string;
  a_end_port?: string;
  a_end_uprn?: string;
  b_end_udprn?: string;
  b_end_port?: string;
  b_end_uprn?: string;
  path_speed?: string;
  is_poa?: boolean;
  is_orderable?: boolean;
  term_length_in_years?: string;
  lq_id?: string;
  origin?: QuoteOrigin;
  cerillion_stage?: CerillionQuoteStage;
  order_id?: string;
};

export type IQuoteData = {
  id: string;
  attributes: IQuoteRecordAttributes;
  relationships?: Record<string, Record<string, any>>;
};

export type IQuoteListItem = IQuoteRecordAttributes & {
  id: string;
};

type IQuoteRecord = ApiRecord & {
  data: IQuoteData;
  included?: IQuoteIncludePriceOrderBulk[];
};

export type IComplexQuotePricingProgress = {
  completed_at: string;
  created_at: string;
  last_updated_at: string;
  progress: string;
  status: 'Incomplete' | 'Completed';
};

type IQuoteIncludePriceOrderBulk =
  | IQuotePricedOrOrdered
  | IQuoteIncludeBulk
  | INNIRecord
  | SupplierNNIResource
  | PopResource
  | ONATAddressResponseItem
  | IPAFAddressSiteQuoteIncludesResponse
  | MessageResponseData;

export interface IQuotePricedOrOrdered {
  id: string;
  attributes: IPricedQuote;
  type: ResourceTypes.price | ResourceTypes.order;
}

interface IQuoteIncludeBulk {
  id: string;
  attributes: IBulkQuoteRecord['attributes'];
  type: ResourceTypes.bulk_quote;
}

export const isBulkQuoteInclude = (resource: Resource): resource is IQuoteIncludeBulk => {
  return resource.type === ResourceTypes.bulk_quote;
};

export type IQuotesResponse = {
  data: IQuoteData[];
};

export type IBulkQuotesResponse = {
  data: IBulkQuoteRecord[];
};

export enum CerillionQuoteStage {
  QUALIFICATION = 'qualification',
  BUDGETARY_DESIGN = 'budgetary_design',
  QUOTE_CUSTOMER = 'quote_customer',
  DETAILED_DESIGN = 'detailed_design',
  TECHNICAL_REVIEW = 'technical_review',
  NEGOTIATION_AND_APPROVAL = 'negotiation_and_approval',
  ORDER_CONVERSION = 'order_conversion',
}

export default IQuoteRecord;
