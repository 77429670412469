import FeatureFlag, { Feature, FeatureFlagEnvironment } from './types';
import { featureFlag, getEnvironment } from 'FeatureFlags/utils/hasFeatureEnabled';
import { oldFeatureFlags } from 'FeatureFlags/oldFeatureFlags';
import { ProductType } from 'Quotes/types/productTypes';

export const featureFlags: Record<Feature, FeatureFlag> = {
  ...oldFeatureFlags,
  requestErrorToasts: {
    description: 'Enable error message toasts when requests fail',
    environments: {
      testing: { enabled: false, attributes: { verbose: true } },
      integration: { enabled: false, attributes: { verbose: true } },
      dev: { enabled: false, attributes: { verbose: true } },
      staging: { enabled: false, attributes: { verbose: false } },
      preproduction: { enabled: false, attributes: { verbose: false } },
      production: { enabled: false, attributes: { verbose: false } },
    },
  },
  orderDownload: {
    description: 'Order PDF download',
    environments: {
      dev: { enabled: true },
      integration: { enabled: true },
      testing: { enabled: true },
      staging: { enabled: true },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  opticalP2PCerillion: {
    description: 'Ability to send an Optical P2P quote to Cerillion',
    environments: {
      dev: { enabled: true },
      integration: { enabled: true },
      testing: { enabled: true },
      staging: { enabled: true },
      preproduction: { enabled: true },
      production: { enabled: true },
    },
  },
  quoteCerillionStageUI: {
    description: 'Show the UI with the Cerillion stages for quotes - 2E',
    environments: {
      integration: { enabled: true, attributes: { alertsEnabled: true } },
      testing: { enabled: true, attributes: { alertsEnabled: true } },
      dev: { enabled: true, attributes: { alertsEnabled: true } },
      staging: { enabled: true, attributes: { alertsEnabled: false } },
      preproduction: { enabled: true, attributes: { alertsEnabled: false } },
      production: { enabled: true, attributes: { alertsEnabled: false } },
    },
  },
  orderStatusStepperUI: {
    description: 'Order status stepper UI - 2F',
    environments: {
      integration: { enabled: true, attributes: { alertsEnabled: true } },
      testing: { enabled: true, attributes: { alertsEnabled: true } },
      dev: { enabled: true, attributes: { alertsEnabled: true } },
      staging: { enabled: false, attributes: { alertsEnabled: false } },
      preproduction: { enabled: true, attributes: { alertsEnabled: false } },
      production: { enabled: false, attributes: { alertsEnabled: false } },
    },
  },
  orderCerillionDeliveryInfo: {
    description: 'Order status delivery information',
    environments: {
      dev: { enabled: true },
      integration: { enabled: true },
      testing: { enabled: true },
      staging: { enabled: true },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  nrtRedirecting: {
    description: 'Load NRT theme depending on predefined hostnames',
    environments: {
      dev: { enabled: false },
      integration: { enabled: false },
      testing: { enabled: false },
      staging: { enabled: false },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  cerillionQuote: {
    description: 'The display of quotes originating from Cerillion CRM',
    environments: {
      dev: { enabled: false },
      integration: { enabled: false },
      testing: { enabled: true },
      staging: { enabled: false },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  nnat: {
    description: 'Enable NNAT only when journey is complete',
    environments: {
      dev: { enabled: false, attributes: { excludeP2P: false, excludeDIA: false } },
      integration: { enabled: false, attributes: { excludeP2P: false, excludeDIA: false } },
      testing: { enabled: false, attributes: { excludeP2P: false, excludeDIA: false } },
      staging: { enabled: false, attributes: { excludeP2P: false, excludeDIA: false } },
      preproduction: { enabled: false, attributes: { excludeP2P: false, excludeDIA: false } },
      production: { enabled: false, attributes: { excludeP2P: false, excludeDIA: false } },
    },
  },
  Optical100G400G: {
    description: 'Optical 100G & 400G Pricing - R006',
    environments: {
      dev: { enabled: true, attributes: { dummyPricesEnabled: false } },
      integration: { enabled: true, attributes: { dummyPricesEnabled: false } },
      testing: { enabled: true, attributes: { dummyPricesEnabled: false } },
      staging: { enabled: true, attributes: { dummyPricesEnabled: false } },
      preproduction: { enabled: true, attributes: { dummyPricesEnabled: false } },
      production: { enabled: true, attributes: { dummyPricesEnabled: false } },
    },
  },
  Optical100G400GInProductJourney: {
    description: 'Optical 100G & 400G Pricing is available in the Product Journey',
    environments: {
      dev: { enabled: false },
      integration: { enabled: false },
      testing: { enabled: false },
      staging: { enabled: false },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  newMDIAUI: {
    description: 'Show the new MDIA 1.2 UI or the MDIA 1.2 Interim Fix UI',
    environments: {
      testing: { enabled: true, attributes: { interimFix: true } },
      integration: { enabled: true, attributes: { interimFix: true } },
      dev: { enabled: true, attributes: { interimFix: true } },
      staging: { enabled: true, attributes: { interimFix: true } },
      preproduction: { enabled: true, attributes: { interimFix: true } },
      production: { enabled: true, attributes: { interimFix: true } },
    },
  },
  mdiaPriceBreakdown: {
    description: 'Display MDIA Router prices, rack kit prices, and service charges',
    environments: {
      dev: { enabled: false },
      integration: { enabled: false },
      testing: { enabled: false },
      staging: { enabled: false },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  nnatOrderable: {
    description:
      'Enable the orderability of NNAT prices for all product types. If disabled show Data Capture journey for all product types.',
    environments: {
      dev: { enabled: true },
      integration: { enabled: true },
      testing: { enabled: true },
      staging: { enabled: true },
      preproduction: { enabled: true },
      production: { enabled: true },
    },
  },
  orderTemplates: {
    description: 'Enable the ability to create and use order templates to pre-populate order sections with data.',
    environments: {
      dev: { enabled: false },
      integration: { enabled: false },
      testing: { enabled: false },
      staging: { enabled: false },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  newNNIRequests: {
    description: 'Add fields to the Order form to allow users to request new NNIs whilst ordering a circuit',
    environments: {
      dev: { enabled: false },
      integration: { enabled: false },
      testing: { enabled: false },
      staging: { enabled: false },
      preproduction: { enabled: false },
      production: { enabled: false },
    },
  },
  SecondaryCircuits: {
    description: 'Enable the ability to Quote and Order a Diverse Circuit using RO2',
    environments: {
      dev: {
        enabled: true,
        attributes: {
          [ProductType.P2NNI]: true,
          [ProductType.P2P]: true,
          [ProductType.DIA]: true,
          isMDIAEnabled: true,
        },
      },
      integration: {
        enabled: false,
        attributes: {
          [ProductType.P2NNI]: false,
          [ProductType.P2P]: false,
          [ProductType.DIA]: false,
          isMDIAEnabled: false,
        },
      },
      testing: {
        enabled: true,
        attributes: {
          [ProductType.P2NNI]: true,
          [ProductType.P2P]: true,
          [ProductType.DIA]: true,
          isMDIAEnabled: true,
        },
      },
      staging: {
        enabled: true,
        attributes: {
          [ProductType.P2NNI]: true,
          [ProductType.P2P]: true,
          [ProductType.DIA]: true,
          isMDIAEnabled: true,
        },
      },
      preproduction: {
        enabled: true,
        attributes: {
          [ProductType.P2NNI]: true,
          [ProductType.P2P]: true,
          [ProductType.DIA]: false,
          isMDIAEnabled: false,
        },
      },
      production: {
        enabled: true,
        attributes: {
          [ProductType.P2NNI]: true,
          [ProductType.P2P]: true,
          [ProductType.DIA]: false,
          isMDIAEnabled: false,
        },
      },
    },
  },
};

const getAllFeatureFlags = () => {
  const environments: {
    [key in FeatureFlagEnvironment]?: { enabled: string[]; disabled: string[] };
  } = {};
  Object.entries(featureFlags).map((value) => {
    const name = value[0] as Feature;
    const flag = value[1];

    if (name === Feature.sampleFeature) return;

    const currentEnvironment = getEnvironment();

    Object.entries(flag.environments).forEach((environment) => {
      const flagEnvironment = environment[0] as FeatureFlagEnvironment;

      const featuresInEnvironment = environments[flagEnvironment] ?? {
        enabled: [],
        disabled: [],
      };
      // defer to current environment for localStorage override
      if (currentEnvironment === flagEnvironment) {
        const state = featureFlag.isEnabled(name) ? featuresInEnvironment.enabled : featuresInEnvironment.disabled;
        state.push(name);
      } else {
        const state = environment[1].enabled ? featuresInEnvironment.enabled : featuresInEnvironment.disabled;
        state.push(name);
      }

      environments[flagEnvironment] = featuresInEnvironment;
    });
  });

  // eslint-disable-next-line no-console
  return environments;
};

const resetFeatureFlags = () => {
  Object.keys(featureFlags).forEach((flag) => localStorage.removeItem(flag));
};

// eslint-disable-next-line no-console
const printAllFeatureFlags = () => console.table(getAllFeatureFlags());

const environment = process.env.REACT_APP_FEATURE_FLAGS as FeatureFlagEnvironment;

const getFeatureFlags = () => getAllFeatureFlags()[environment];

const printFeatureFlags = () =>
  // eslint-disable-next-line no-console
  console.log(getFeatureFlags());

const writeFlagsToLocalStorageForInspection = () => {
  const { disabled, enabled } = getFeatureFlags() ?? {
    enabled: undefined,
    disabled: undefined,
  };
  localStorage.setItem('feature-flags-enabled', JSON.stringify(enabled));
  localStorage.setItem('feature-flags-disabled', JSON.stringify(disabled));
};

interface FeatureFlagOperations {
  print: () => void;
  getAll: () => {
    dev?: { enabled: string[]; disabled: string[] };
    integration?: { enabled: string[]; disabled: string[] };
    testing?: { enabled: string[]; disabled: string[] };
    staging?: { enabled: string[]; disabled: string[] };
    preproduction?: { enabled: string[]; disabled: string[] };
    production?: { enabled: string[]; disabled: string[] };
  };
  get: () => { enabled: string[]; disabled: string[] } | undefined;
  reset: () => void;
  writeForInspection: () => void;
  printAll: () => void;
}

const featureFlagOperations: FeatureFlagOperations = {
  reset: resetFeatureFlags,
  get: getFeatureFlags,
  getAll: getAllFeatureFlags,
  print: printFeatureFlags,
  printAll: printAllFeatureFlags,
  writeForInspection: writeFlagsToLocalStorageForInspection,
};

declare const window: {
  featureFlags: FeatureFlagOperations;
} & Window;

if (typeof window !== 'undefined') {
  window.featureFlags = featureFlagOperations;
  setTimeout(() => writeFlagsToLocalStorageForInspection(), 1);
}
