import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ISiteContact } from '../../../../../../types/location';
import TextInput from '../../../../../../../shared/components/atoms/TextInput';
import TextAreaInput from '../../../../../../../shared/components/atoms/TextAreaInput';
import FormHeader from '../FormHeader';
import Column from '../../../../../../../shared/components/atoms/Column';
import { siteContactIsComplete } from '../../../../../shared/utils/contactComplete';
import StyledExistingContactButton from '../../../../../../../shared/components/atoms/ExistingContactButton';
import { MAX_LENGTH_LONG_INPUT } from '../../../../../shared/utils/validation';

interface ISiteContactProps {
  identifierPrefix: string;
  otherSiteContact?: ISiteContact;
  siteContact: ISiteContact;
  copyLocationASiteContactToLocationB?: () => void;
  updateSiteContact(updated: ISiteContact): void;
}

const SiteContactInformation: FunctionComponent<React.PropsWithChildren<ISiteContactProps>> = ({
  identifierPrefix,
  otherSiteContact,
  updateSiteContact,
  siteContact,
  copyLocationASiteContactToLocationB,
}) => {
  const onChangeWithUpdate = (field: keyof ISiteContact, value: string): void => {
    updateSiteContact({
      ...siteContact,
      [field]: value,
    });
  };

  const copyContactDetails = () => {
    if (copyLocationASiteContactToLocationB) {
      copyLocationASiteContactToLocationB();
    }
  };

  const otherSiteContactComplete = () => {
    return otherSiteContact !== undefined && copyLocationASiteContactToLocationB !== undefined
      ? siteContactIsComplete(otherSiteContact)
      : false;
  };

  return (
    <>
      <FormHeader title="Site Contact Information" />
      {otherSiteContactComplete() && (
        <Column defaultWidth={14}>
          <StyledExistingContactButton
            className="copy-site-contact-information-btn"
            onClick={() => copyContactDetails()}
          >
            Use A-End Site Contact Information
          </StyledExistingContactButton>
        </Column>
      )}

      <div className={`${identifierPrefix} site-contact-form`}>
        <p>The site contact will be the key contact for managing and accessing the site.</p>

        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-5">
                <TextInput
                  fieldName="firstName"
                  inputIdentifierPrefix={identifierPrefix}
                  labelText="First name *"
                  maxLength={50}
                  value={siteContact.firstName}
                  onChange={(e) => onChangeWithUpdate('firstName', e.target.value)}
                />
              </div>
              <div className="col-7">
                <TextInput
                  fieldName="surname"
                  inputIdentifierPrefix={identifierPrefix}
                  labelText="Surname *"
                  maxLength={50}
                  value={siteContact.surname}
                  onChange={(e) => onChangeWithUpdate('surname', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <TextInput
              fieldName="phone"
              inputIdentifierPrefix={identifierPrefix}
              labelText="Mobile or landline phone number *"
              maxLength={50}
              value={siteContact.phone}
              onChange={(e) => onChangeWithUpdate('phone', e.target.value)}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <TextInput
              fieldName="email"
              inputIdentifierPrefix={identifierPrefix}
              labelText="Email address *"
              maxLength={70}
              value={siteContact.email}
              onChange={(e) => onChangeWithUpdate('email', e.target.value)}
            />
          </div>
          <div className={'col'}>
            <TextAreaInput
              fieldName="notes"
              inputIdentifierPrefix={identifierPrefix}
              labelText="Enter notes for this site"
              value={siteContact.notes}
              maxLength={MAX_LENGTH_LONG_INPUT}
              onChange={(e) => onChangeWithUpdate('notes', e.target.value)}
              optional={true}
            />
            <div className="float-right text-muted site-notes-length">
              {siteContact.notes.length} / {MAX_LENGTH_LONG_INPUT}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledSiteContactInformation = styled(SiteContactInformation)`
  .site-notes-length {
    margin-top: -0.5rem;
  }
`;

export default StyledSiteContactInformation;
